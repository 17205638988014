import React, { useContext, useEffect, useState } from "react";
import {
    Box,
    Button,
    Divider,
    Grid,
    MenuItem,
    Select,
    TextField,
    Typography,
    containerClasses,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { LocalizationProvider, MobileDateRangePicker } from '@mui/x-date-pickers-pro/';
import { DateRangePicker } from '@mui/x-date-pickers-pro/';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/';
import CustomTooltip from "../CustomTooltip";
import dayjs from "dayjs";
import { LicenseInfo } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { empty, generatePhrase, getDateToString } from "../../utils/common";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { UserAuth } from "../../store/context/userContext";
import { StaticDateRangePicker } from '@mui/x-date-pickers-pro/StaticDateRangePicker';
import { DesktopDateRangePicker } from '@mui/x-date-pickers-pro/DesktopDateRangePicker';
import { CommonContext } from "../../store/context/commonContext";
import theme from "../../theme";
import { set } from "react-hook-form";



LicenseInfo.setLicenseKey(
    "370e579ab4fef48a1739afecb9c68d3bTz04OTIyMyxFPTE3NDU3NTAwNTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const BookingCard = ({
    packageSeq,
    // guests = 0,
    maxGuests,
    minGuests,
    minDays,
    maxDays,
    price = 1,
    notAvailableDates = [],
    // handleGuestChange = () => { },
    handleDateChange = () => { },
    isPopup = false
}) => {
    const { user, authPopup, setAuthPopup } = UserAuth();
    const openAuthPopup = (type) => {
        setAuthPopup({
            ...authPopup,
            type: type,
            open: true,
            callback: () => {
                handleCheckOut();
            },
        });
    };

    const navigate = useNavigate();

    const tomorrow = dayjs().add(1, "day");
    const today = dayjs()

    let defaultBookingSelection = [null, null]
    let defaultGuestsSelection = parseInt(minGuests)


    if (localStorage.getItem(`${packageSeq}-bookingSelection`) != null) {
        const localStorageBookingSelection = JSON.parse(localStorage.getItem(`${packageSeq}-bookingSelection`))
        defaultBookingSelection = [dayjs(localStorageBookingSelection.startDate), dayjs(localStorageBookingSelection.endDate)]
        defaultGuestsSelection = parseInt(localStorageBookingSelection.guest)
    }

    const [guests, setGuests] = useState(defaultGuestsSelection)
    const [dateRange, setDateRange] = useState(defaultBookingSelection);

    const [openDate, setOpenDate] = useState(false)

    // console.log(dateRange)

    // console.log(notAvailableDates)

    const [selectedDays, setSelectedDays] = useState(minDays);
    const { isNineHundred } = CommonContext()

    const [errorText, setErrorText] = useState('')
    const [dateError, setDateError] = useState(false)

    const handleSetSelectedDaysCount = (days) => {
        setSelectedDays(isNaN(days) ? 0 : days);
    }


    function dateRangeContainsUnavailable(dateRange, unavailableDates) {

        const start = dayjs(dateRange[0])
        const end = dayjs(dateRange[1])

        for (let date of unavailableDates) {
            if (date.isBetween(start - 1, end + 1)) {
                return true
            }
        }

        return false
    }

    const handleGuestChange = (event) => {
        const selectedGuests = parseInt(event.target.value, 10);

        // Ensure selected guests are within the allowed range
        if (selectedGuests >= minGuests && selectedGuests <= maxGuests) {
            setGuests(selectedGuests.toString());
        }

        let storedSelection = JSON.parse(localStorage.getItem(`${packageSeq}-bookingSelection`));
        if (storedSelection != null) {
            storedSelection.guest = selectedGuests;
            localStorage.setItem(`${packageSeq}-bookingSelection`, JSON.stringify(storedSelection));
        }
    };

    const setDates = (date) => {


        if (minDays == maxDays && date[1] != null) {

            if ((date[0] ? date[0].format('YYYY-MM-DD') : null) !== (dateRange[0] ? dateRange[0].format('YYYY-MM-DD') : null)) {
                if (date[0]) {
                    date[1] = dayjs(date[0]).add(minDays - 1, 'day');
                    setDateRange(date)
                    setOpenDate(false)
                    return
                }
            }
            date[0] = date[1]
            date[1] = dayjs(date[0]).add(minDays - 1, 'day');
            setDateRange(date);
        } else {
            if (minDays == maxDays) {
                // console.log(date, 'before update');
                date[1] = dayjs(date[0]).add(minDays - 1, 'day');
                // console.log(date, 'after update');
                setDateRange(date);
            } else {
                // Check if both formatted end dates are equal 
                if (dayjs(date[1]).format('YYYY-MM-DD') === dayjs(dateRange[1]).format('YYYY-MM-DD')) {
                    date[1] = null;
                    setOpenDate(true);
                    setDateRange(date)
                } else {
                    setDateRange(date)
                }
            }
        }
        // console.log(dateRange)

        // if user press back button user should have it's previous selection 
        localStorage.setItem(`${packageSeq}-bookingSelection`, JSON.stringify({
            guest: guests,
            startDate: getDateToString(dayjs(date[0])),
            endDate: getDateToString(dayjs(date[1]))
        }));

    }

    const [dateSelectText, setDateSelectText] = useState('')


    const disableCustomDates = (date) => {
        return date.isSame(today, 'day')
            || (notAvailableDates.length > 0 && notAvailableDates.some(d => date.isSame(d, 'day')));
    };


    const handleCheckOut = () => {
        if (empty(user)) {
            openAuthPopup("signup");
            return;
        }
        if (empty(guests) || empty(dateRange[0]) || empty(dateRange[1])) {
            toast.error("Please select booking dates and number of guests.", {
                theme: "colored",
            });
            setErrorText('Please select booking dates and number of guests.')
            setDateError(true)
            return;
        }

        const startDateParam = "&sd=" + getDateToString(dateRange[0]);
        const endDateParam = "&ed=" + getDateToString(dateRange[1]);
        const packageSeqParam = "&p=" + packageSeq;

        navigate(
            "/checkout?g=" +
            guests +
            startDateParam +
            endDateParam +
            packageSeqParam
        );
    };

    let dateHelperText = ''

    if (minDays == maxDays) {
        dateHelperText = `This package can be booked for ${generatePhrase(minDays, 'day')}`
    } else if (minDays != maxDays) {
        dateHelperText = `This package can be booked for ${minDays}-${maxDays} days`
    }

    useEffect(() => {

        let days = dayjs(dateRange[1]).diff(dateRange[0], 'days') + 1;

        if (dateRange[0] == null || dateRange[1] == null) {
            setDateSelectText('Select Dates')
        } else if (dateRange[0].format('YYYY-MM-DD') == dateRange[1].format('YYYY-MM-DD')) {
            const invalidCheck = dateRange[0].format('MMM DD, YYYY')
            if (invalidCheck.includes('Invalid')) {
                setDateRange([null, null])
                return
            }
            setDateSelectText(`${dateRange[0].format('MMM DD, YYYY')}`)
        } else {
            const invalidCheck = `${dateRange[0].format('MMM DD, YYYY')}${dateRange[1].format('MMM DD, YYYY')}`
            if (invalidCheck.includes('Invalid')) {
                setDateRange([null, null])
                return
            }
            setDateSelectText(`${dateRange[0].format('MMM DD, YYYY')} - ${dateRange[1].format('MMM DD, YYYY')}`)
        }

        if (dateRange[0] != null && dateRange[1] != null) {
            setOpenDate(false)
        }

        if (isNaN(days) || (dateRange[0] === null) || (dateRange[1] === null)) {
            setErrorText("Select valid date range for this package")
            setDateError(true)
        }

        if (dateRangeContainsUnavailable(dateRange, notAvailableDates)) {
            setErrorText(`The selected date range includes some unavailable dates`)
            setDateError(true)
            return
        }


        if (days < minDays) {
            setErrorText(`You can not book this package for less than ${minDays} days.`)
            setDateError(true)
        } else if (days > maxDays) {
            setErrorText(`You can not book this package for more than ${maxDays} days`)
            setDateError(true)
        } else {
            if (empty(dateRange[0]) || empty(dateRange[1])) {
                setErrorText("")
            } else {
                setErrorText('These dates are available')
            }
            setDateError(false)
        }


        handleSetSelectedDaysCount(days)

        handleDateChange(dateRange);


    }, [notAvailableDates, dateRange, guests]);

    if (isNaN(selectedDays)) {
        selectedDays = 0;
    }


    let total_package_price =
        price * (guests ? parseInt(guests) : minGuests) * (selectedDays > minDays ? selectedDays : minDays);
    let booking_fee = parseFloat((total_package_price * 0.05).toFixed(2));
    let conservation_donation = 0;

    return (
        <Grid item xs={12} sm={12} md={5}>
            <Box
                sx={{
                    p: { xs: 2, sm: 2, md: 3, lg: 3 },
                    border: "1px solid var(--grey500)",
                    borderRadius: (theme) => `${theme.card.borderRadius} `,
                    position: "sticky",
                    top: "100px",
                    "& .MuiTypography-root": {
                        color: 'var(--grey900)'
                    },
                }}
                variant="outlined"
            >
                <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                    <Box sx={{ display: 'flex', gap: 1, }}>
                        <Box sx={{
                            "& .MuiTypography-root": {
                                color: 'var(--black)'
                            }
                        }}>
                            <Typography sx={{ mr: 1, }} style={{ fontWeight: "600" }}> ${parseFloat(price).toFixed(2)}</Typography>
                        </Box>
                    </Box>
                    <Typography> per Guest</Typography>
                </Box>
                <Box sx={{
                    display: 'flex', mb: 2, justifyContent: 'space-between', color: 'var(--grey900)',

                }}>
                    <Typography>Package Includes</Typography>
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{
                            display: 'flex', alignItems: 'center', gap: 1, "& .MuiTypography-root": {
                                fontSize: '14px'
                            },
                        }}>
                            <Icon.PersonOutlineOutlined sx={{ fontSize: '17.5px;' }} />
                            <Typography >
                                {generatePhrase(guests, 'Guest')}
                            </Typography>
                            <Icon.CalendarTodayOutlined sx={{ fontSize: '14px' }} />
                            <Typography >
                                {generatePhrase(selectedDays > minDays ? selectedDays : minDays, 'Day')}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box
                    sx={{
                        borderRadius: (theme) => `${theme.card.borderRadiusMd} `,
                        mb: 3,
                        backgroundColor: 'var(--grey300)'
                    }}
                >
                    <Box sx={{ display: "flex", p: "0.75rem 1rem", flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                            <Typography>
                                Select the Number of Guests
                            </Typography>
                        </Box>

                        <Box>
                            <Select
                                displayEmpty
                                value={guests} // Set the value to defaultGuestsSelection
                                fullWidth
                                IconComponent={Icon.KeyboardArrowDownOutlined}
                                onChange={handleGuestChange}
                                sx={{
                                    height: "42px",
                                    backgroundColor: 'white'
                                }}
                            >
                                {Array.from({ length: maxGuests - minGuests + 1 }, (_, index) => (
                                    <MenuItem
                                        key={index + parseInt(minGuests)}
                                        value={index + parseInt(minGuests)}
                                    >
                                        {index + parseInt(minGuests)}
                                    </MenuItem>
                                ))}
                            </Select>

                        </Box>
                    </Box>
                    <Box sx={{ display: "flex", p: "1rem", flexDirection: 'column', gap: 1 }}>
                        <Box sx={{ display: 'flex', gap: 1 }}>
                            <Typography>Choose Trip Dates</Typography>
                        </Box>

                        <Box sx={{
                            height: 0,
                            overflow: 'hidden',

                            '& :hover': {
                                cursor: 'pointer',
                            },
                            "& .MuiFormHelperText-root": {
                                ml: 0,
                                color: 'green'
                            },
                            "&. MuiTextField-root": {
                                display: 'none'
                            },
                            mt: 2,
                        }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                {!isNineHundred ?
                                    <Box sx={{
                                        height: 0
                                    }}>
                                        <DesktopDateRangePicker
                                            open={openDate}
                                            // autoFocus
                                            calendars={1}
                                            clearable={true}
                                            slots={{ field: SingleInputDateRangeField }}
                                            // label="Select Dates"
                                            slotProps={{
                                                textField: {
                                                    size: "small",
                                                },
                                                actionBar: { actions: ["clear"] },

                                            }}
                                            sx={{
                                                width: '100%',
                                                '& .MuiOutlinedInput-root': {
                                                    backgroundColor: 'white',
                                                },
                                                "&. MuiInputBase-root": {
                                                    display: 'none'
                                                },
                                                "& .MuiFormControl-root": {
                                                    display: 'none'
                                                }
                                            }}
                                            format="DD MMM YYYY"
                                            onOpen={() => setOpenDate(true)}
                                            // use this if Ok button is needed
                                            // onAccept={()=>{
                                            //     setOpenDate(false)
                                            // }}
                                            value={dateRange}
                                            onChange={(date) => setDates(date)}
                                            disablePast
                                            disableHighlightToday
                                            shouldDisableDate={disableCustomDates}
                                            onClose={() => setOpenDate(false)}
                                        />
                                    </Box>
                                    :
                                    <MobileDateRangePicker
                                        open={openDate}
                                        calendars={1}
                                        clearable={true}
                                        slots={{ field: SingleInputDateRangeField }}
                                        // label="Select Dates"
                                        slotProps={{
                                            textField: {
                                                size: "small",
                                                InputProps: { endAdornment: <Icon.CalendarMonth />, placeholder: 'Select Dates' },
                                                error: !!dateError,
                                                helperText: errorText
                                            },
                                            actionBar: { actions: ["clear"] },

                                        }}
                                        sx={{
                                            width: '100%',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: 'white',

                                            }
                                        }}
                                        format="DD MMM YYYY"
                                        value={dateRange}
                                        onChange={(date) => setDates(date)}
                                        disablePast
                                        disableHighlightToday
                                        closeOnSelect
                                        shouldDisableDate={disableCustomDates}
                                        onClose={() => setOpenDate(false)}
                                    />}
                            </LocalizationProvider>
                        </Box>
                        <TextField
                            sx={{
                                zIndex: '99',
                                marginTop: '-28px',
                                borderRadius: '6px',
                                // transition: 'border-color 0.3s', // Add transition for smooth effect
                                color: 'var(--black)',
                                '& :hover': {
                                    cursor: 'pointer'
                                },
                                '& .MuiOutlinedInput-root': {
                                    backgroundColor: 'white',
                                },
                                "&. MuiInputBase-root": {
                                    display: 'none',
                                },
                                "& .MuiFormControl-root": {
                                    display: 'none'
                                }, "& .MuiFormHelperText-root": {
                                    ml: 0,
                                    color: 'var(--primary)'
                                }, "& .MuiInputBase-input": {
                                }, "& .Mui-error": {
                                    color: '#d32f2f'
                                }
                            }}
                            InputProps={{
                                endAdornment: <Icon.CalendarToday />,
                                style: { color: 'black' },
                                inputProps: { style: { color: 'black' } }
                            }}
                            error={!!dateError}
                            helperText={errorText}
                            autoComplete="off"
                            size="small"
                            value={dateSelectText}
                            onClick={(e) => {
                                e.preventDefault();
                                setOpenDate(!openDate)
                            }}
                            onKeyDown={(e) => e.preventDefault()} // Prevent typing inside TextField
                        />

                        <Typography variant="caption">
                            {dateHelperText}
                        </Typography>
                    </Box>
                </Box>
                {window.location.pathname !== '/checkout' && <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    sx={isPopup ? { display: 'none' } : { mb: 3 }}
                    onClick={handleCheckOut}
                    disabled={dateError}
                >
                    Continue to Booking
                </Button>}
                <Box sx={{}}>
                    <Box sx={{
                        display: "flex", alignItems: "center", gap: 1, "& .MuiTypography-root": {
                            color: 'var(--black)'
                        }
                    }}>
                        <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                            Pricing Details
                        </Typography>
                        <CustomTooltip
                            content={
                                <>The amount paid is inclusive of the FarWide fee. </>
                            }
                        />
                    </Box>
                    <Typography variant="caption">
                        Prices are for exclusive access to this property.
                    </Typography>
                    <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                        <Box>{`$${parseFloat(price).toFixed(2)} x ${generatePhrase(guests, 'Guest')} x ${generatePhrase(selectedDays > minDays ? selectedDays : minDays, 'Day')}`}</Box>
                        <Box>${isNaN(total_package_price) ? 0 : parseFloat(total_package_price).toFixed(2)}</Box>
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Box>Platform Fee (5%)</Box>
                        <Box>+${isNaN(booking_fee) ? 0 : parseFloat(booking_fee).toFixed(2)}</Box>
                    </Box>
                    {/* <Box
                sx={{ display: "flex", justifyContent: "space-between" }}
            >
                <Box>Conservation Donation</Box>
                <Box sx={{ textAlign: "right" }}>+${conservation_donation}</Box>
            </Box> */}
                    <Divider sx={{ my: 1 }} />
                    <Box
                        sx={{
                            display: "flex",
                            fontWeight: "600",
                            justifyContent: "space-between",
                            color: 'var(--grey900)'
                        }}
                    >
                        <Box sx={{ color: 'var(--black)' }}>Total</Box>
                        <Box sx={{ color: 'var(--black)' }}>
                            ${isNaN(total_package_price + conservation_donation + booking_fee) ? 0 : (parseFloat(total_package_price + conservation_donation + booking_fee).toFixed(2))}
                        </Box>
                    </Box>
                </Box>
            </Box>
            {isPopup &&
                <Box
                    sx={{
                        width: '100%',
                        ml: -2,
                        height: '82px',
                        position: 'fixed',
                        bottom: 0,
                        backgroundColor: 'white',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        display: 'flex',
                        flexDirection: 'column',
                        boxShadow: '0 -2px 4px 0 rgba(0,0,0,0.15)',
                        zIndex: '9999',
                        pl: 2,
                        pr: 2
                    }}
                >

                    <Button
                        fullWidth
                        size="large"
                        variant="contained"
                        disabled={dateError}
                        onClick={handleCheckOut}
                        sx={{ mt: 'auto', mb: 'auto' }} // Vertically center the button

                    >
                        Continue to Booking
                    </Button>

                </Box>
            }
        </Grid >
    );
};

export default BookingCard;