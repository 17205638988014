import React from 'react';
import { Box, CardMedia, Typography } from '@mui/material';
import * as Icon from '@mui/icons-material';
import { CommonContext } from '../../store/context/commonContext';
import { empty, getActivityType, getImageUrl } from '../../utils/common';
import { useLocation } from 'react-router';
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import { Link } from 'react-router-dom';

const PackageCardMobile = ({ image, title, area, address, nearest_town, showBorder = true, slug, seq, activity_type, type, thumbnail, propertyName, listingSlug, listing_seq }) => {
    const { isMobile } = CommonContext()
    const location = useLocation();
    const isValidAddress = address === '-' ? false : true;

    // console.log({ image, title, area, address, nearest_town, showBorder, slug, seq, activity_type, type, thumbnail })

    return (
        <Box
            sx={{
                p: showBorder && 2,
                border: showBorder && '1px solid var(--grey500)',
                display: 'flex',
                flexDirection: 'row',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
            }}
            onClick={
                (event) => {
                    const isPropertyNameClicked = event.target.classList.contains('propertyName');
                    if(isPropertyNameClicked){
                      let locationToOpen = isMobile ? '_self':'_blank'
                      window.open(`/property/${listingSlug}-${listing_seq}`, locationToOpen)
                      return
                    }else if (!empty(slug)) {
                        let locationToOpen = isMobile ? "_self" : "_blank"
                        window.open("/package/" + slug + "-" + seq, locationToOpen)
                    } else {

                    }
                }}
        >
            {
                image.type === 'IMAGE' ?
                    <Box
                        sx={{
                            width: '100px',
                            height: '80px',
                            backgroundImage: "url('" + getImageUrl(image.url, "m") + "')",
                            backgroundSize: 'cover',
                            backgroundPosition: 'center',
                            borderRadius: '8px',
                            minWidth: '100px',
                        }}
                    />
                    :
                    <>
                        {
                            thumbnail === '' ?
                                <div className='' style={{ position: 'relative', display: 'flex' }}>
                                    <div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '80px', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
                                        <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
                                    </div>
                                    <CardMedia
                                        component='video'
                                        sx={{
                                            width: '100px',
                                            height: '80px',
                                            backgroundSize: 'cover',
                                            borderRadius: '8px',
                                            position: 'relative',
                                            objectFit: 'cover'
                                        }}
                                        image={image.url}
                                    />
                                </div>
                                :
                                <div className='' style={{ position: 'relative', display: 'flex' }}>
                                    <div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '80px', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
                                        <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
                                    </div>
                                    <Box
                                        sx={{
                                            width: '100px',
                                            height: '80px',
                                            backgroundImage: "url('" + thumbnail + "')",
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            borderRadius: '8px',
                                            minWidth: '100px',
                                        }}
                                    />
                                </div>
                        }
                    </>
            }
            <Box
                sx={{
                    alignItems: 'flex-start',
                    flexWrap: 'wrap',
                    ml: 1,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                }}
            >
                <Typography noWrap sx={{ display: 'block', fontSize: '16px', fontWeight: 'bold', mb: 0.5 }}>
                    {title}
                </Typography>
                <Box sx={isMobile ? { display: 'flex', width: '100%', gap: 1, fontSize: '14px', mb: 0.5 } : { display: 'flex', gap: 1, fontSize: '14px', mb: 0.5 }}>
                    <Icon.MapOutlined />
                    <Typography sx={{ mr: 1, color: 'var(--grey900)', fontSize: '14px' }}>
                        {area} acre
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'flex-start', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                    <Icon.LocationOnOutlined sx={{ mr: 1 }} />
                    <Typography sx={{}}>
                        <Typography variant='body2' component={'span'} sx={{ color: 'var(--grey900)' }}>
                            {getActivityType(activity_type)+ ' at '}
                        </Typography>
                        <Link 
                        // target={isMobile ? '_self' : '_blank'} 
                        // to={`/property/${listingSlug}-${listing_seq}`}
                         className="propertyName" style={{ color: 'var(--black)', textDecoration: 'underline', fontSize: '14px' }} component={'span'} >
                            {" " + propertyName + " "}
                        </Link>
                        <Typography component={'span'} sx={{ color: 'var(--grey900)' }} variant='body2' >
                            near {nearest_town}
                        </Typography>

                    </Typography>
                </Box>
            </Box>
        </Box >
    );
};

export default PackageCardMobile;
