import { createContext, useContext, useEffect, useRef, useState } from "react";

const defaultProvider = {
    isMobile: null,
    isTablet: null,
    isNineHundred : null,
    screenWidth: 0,
    scroll: 0,
    searchFilters : {
        activity : 'A',
        kw : '',
        start_date : '',
        end_date : '',
        species : '',
        amenties : [],
        sort : 'R'
    },
    setSearchFilters : ()=>{},
    isMessagesDashboard: true,
    isShowMessages: false,
    isProfile: false,
}


const commonContext = createContext(defaultProvider);


const CommonContextProvider = ({ children }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [scroll, setScroll] = useState(0);
    const [searchFilters, setSearchFilters] = useState(defaultProvider.searchFilters);
    const [isMessagesDashboard, setIsMessageDashboard] = useState(true);
    const [isShowMessages, setIsShowMessages] = useState(false);
    const [isProfile, setIsProfile] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [detail, setDetail] = useState({});
    const [start, setStart] = useState(0);
    const listContainerRef = useRef(null);
    const [seqValue, setSeqValue] = useState("");
    // const [refDetected, setRefDetected] = useState("")
    const scrollableDivRef = useRef(null);
    const [toBottom, setToBottom] = useState(true)
    const [newMessageReceivedStatus, setNewMessageReceivedStatus] = useState("")
    const [lastMessageseq, setLastMessageseq] = useState()
    const [updateConnectionListMessage, setUpdateConnectionListMessage] = useState({
        message: "",
        receiver_seq: ""
    })
    // console.log("listContainerRef", listContainerRef)
    const [userData, setUserData] = useState({
        u_seq: "",
        u_type: ""
    })

    const [senderSeqToSendChat, setSenderSeqToSendChat] = useState("")
    const [senderTypeToSendChat, setSenderTypeToSendChat] = useState("")
    const [newUserMessageReceivedStatus, setNewUserMessageReceivedStatus] = useState(false)
    const [isNewMessage, setIsNewMessage] = useState(false)
    const [profilePhoto, setProfilePhoto] = useState("")
    const [userDetail, setuserDetail] = useState({});
    const [reviewPresent, setReviewPresent] = useState()
    const [errorMessage, setErrorMessage] = useState('')
    const [isListingPresent, setIsListingPresent] = useState(false)
    const [isStripeAdded , setIsStripeAdded] = useState(false)


    //   console.log("limit", limit, start)

    // console.log("SenderSequence : ", senderSeqToSendChat)
    function setFilters (filter){
        setSearchFilters({...searchFilters, ...filter})
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    function handleScroll() {
        setScroll(window.scrollY);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    const isMobile = width <= 480;
    const isTablet = width <= 767;
    const isNineHundred = width <= 899;
    const isLoginHamburger = width <= 1010;

    const values = {
        isMobile,
        searchFilters,
        isTablet,
        isNineHundred,
        isLoginHamburger,
        scroll,
        setSearchFilters : setFilters,
        screenWidth: width,
        isMessagesDashboard,
        setIsMessageDashboard,
        isShowMessages,
        setIsShowMessages,
        isProfile,
        setIsProfile,
        isLoading,
        setIsLoading,
        userData, setUserData,
        listContainerRef,
        start, setStart, senderSeqToSendChat, setSenderSeqToSendChat, setDetail, detail,
        newMessageReceivedStatus,
        setNewMessageReceivedStatus, scrollableDivRef ,
        // refDetected,setRefDetected
        toBottom, setToBottom,
        updateConnectionListMessage, setUpdateConnectionListMessage,
        senderTypeToSendChat, setSenderTypeToSendChat,
        seqValue, setSeqValue,
        lastMessageseq, setLastMessageseq,
        newUserMessageReceivedStatus, setNewUserMessageReceivedStatus,
        isNewMessage, setIsNewMessage,
        profilePhoto, setProfilePhoto,
        userDetail, setuserDetail,
        reviewPresent, setReviewPresent,
        errorMessage, setErrorMessage,
        isListingPresent, setIsListingPresent, isStripeAdded , setIsStripeAdded
    }

    //    console.log("userData", userData)

    return (<commonContext.Provider value={values}>{children}</commonContext.Provider>)
}

const CommonContext = () => {
    return useContext(commonContext)
}
// export { UserProvider };
export { CommonContext, CommonContextProvider }
