import * as React from "react";
import { useEffect, useState } from "react";
import { Avatar, Badge, Box, Button, Chip, Dialog, DialogTitle, Divider, Grid, IconButton, MenuItem, Pagination, Select, TextField, Typography } from "@mui/material";
import PackageCard from "../../commonComponents/PackageCard";
import * as Icon from '@mui/icons-material';
import SearchMap from "../../projectComponents/Map";
import { CommonContext } from "../../store/context/commonContext";
import FilterPopup from "./FilterPopup";
import SearchService from "../../services/search";
import { empty, extractDataFromArray } from "../../utils/common";
import { Link, useSearchParams } from "react-router-dom";
import SkeletonPackageCard from "../../commonComponents/placeholders/SkeletonPackageCard";
import CustomDateRangePicker from "../../projectComponents/CustomDateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, MobileDateRangePicker, SingleInputDateRangeField, StaticDateRangePicker } from "@mui/x-date-pickers-pro";
import { date } from "yup";
import dayjs from "dayjs";
import { ROUTE_PROPERTY } from "../../constants/page";



const SearchListing = () => {
    const [loading, setLoading] = useState(true)
    const { searchFilters, setSearchFilters } = CommonContext()
    const [results, setResults] = useState([])
    const [speciesData, setSpeciesData] = useState({})
    const [pageCount, setPageCount] = useState(0)
    const [count, setCount] = useState(0)
    const [popupConfig, setPopupConfig] = useState({ open: false, type: '' })
    const [searchParams, setSearchParams] = useSearchParams();
    const [selectedActivity, setSelectedActivity] = useState('');
    const { isNineHundred } = CommonContext();
    const [isActive, setIsActive] = useState(false);
    const [isActivesp, setIsActivesp] = useState(false);
    const [mapView, setMapView] = useState(false)
    const [listingSeq, setListingSeq] = useState('')
    const handleFilterOpen = (type) => {
        setPopupConfig({ open: true, type });
    };

    const [open, setOpen] = useState(false)
    const [dateRange, setDateRange] = useState([null, null])
    // console.log(dateRange, 'daterange')
    const { isMobile, isTablet } = CommonContext()

    const handleClose = (value) => {
        setPopupConfig({ open: false, type: '' });
    };

    const [popupOpenActivity, setPopupOpenActivity] = useState(false)

    const [selectedSpecies, setSelectedSpecies] = useState([]);

    const [triggerDate, setTriggerDate] = useState(false)

    const active = {
        width: '7px',
        height: '7px',
        backgroundColor: 'var(--black)',
        borderRadius: '50%',
        position: 'absolute',
        top: '-3px',
        right: '-3px',
    }


    const handleDateChange = (dates) => {
        // console.log(dates, 'dates')
        // check if both formatted end dates are equal
        if (dayjs(dates[1]).format('YYYY-MM-DD') === dayjs(dateRange[1]).format('YYYY-MM-DD')) {
            dates[1] = null
            setDateRange(dates)
            return
        }
        if (dates[0] != null && dates[1] != null) {
            setDateRange(dates)
            setTriggerDate(!triggerDate)
            // setOpen(false)
        } else if (dates[0] == null && dates[1] == null) {
            setDateRange(false)
            setTriggerDate(!triggerDate)
            // setOpen(false)
        }



    }

    //disable current date
    const today = dayjs()
    const disableCustomDates = (date) => {
        return date.isSame(today, 'day')
    }


    // const DateBtn = <Button color="secondary" size="small" variant="outlined" onClick={() => { handleFilterOpen('date') }} >Dates</Button>
    const DateBtn =
        <Box>
            <div
                className="searchDates"
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    fontWeight: 500,
                    width: '95px',
                    height: '100%',
                    borderRadius: '8px',
                    transition: 'border-color 0.3s',
                    color: 'var(--black)',
                    border: '1px solid #c5c5c7',
                    padding: '0 8px',
                    cursor: 'pointer',
                    borderColor: isActive ? 'var(--black)' : '#c5c5c7',
                    position: 'relative',
                }}
                onClick={() => {
                    setOpen(!open);
                    // setIsActive(true);
                }}
            >
                Dates <span style={isActive ? active : {}}></span>
            </div>

        </Box>

    const SpeciesBtn = <Button sx={{ '&:hover': { backgroundColor: 'white', }, borderColor: !isActivesp ? '#c5c5c7' : '#1b1b1b' }} color="secondary" size="small" variant="outlined" disabled={empty(speciesData)} onClick={() => { handleFilterOpen('species');  }}>Species
        <span style={isActivesp ? active : {}}></span>
    </Button>

    // Pagination 
    const [page, setPage] = React.useState(1);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    // Set Search filters
    const handleSetSearch = (value) => {
        setSearchFilters(value);
    };

    const speciesFilterChange = (selectedSpeciesString) => {
        // Reset page number to 1 when reselect species filter
        setPage(1)
        // Update the searchFilters with the selected species array
        setSearchFilters({ ...searchFilters, species: selectedSpeciesString });
    };

    useEffect(() => {
        let temp = {}
        if (searchParams.has("kw")) {
            temp['kw'] = searchParams.get('kw')
        }
        if (searchParams.has("sp")) {
            temp['sp'] = searchParams.get('sp').split(",")
        }
        if (searchParams.has("at")) {
            if (empty(searchParams.get('at'))) {
                temp['activity'] = 'A'
            } else {
                temp['activity'] = searchParams.get('at')
            }
        }
        setSearchFilters(temp)


    }, [])


    useEffect(() => {
        setLoading(true)

        if (dateRange[0] != null || dateRange[1] != null) {
            setIsActive(true)
        } else if (dateRange[0] === null || dateRange[1] === null) {
            setIsActive(false)
        }

        if (searchFilters.species === '') {
            setIsActivesp(false)
        } else if (searchFilters.species !== '') {
            setIsActivesp(true)
        }


        SearchService.search(
            searchFilters.kw,
            searchFilters.activity,
            searchFilters.species,
            searchFilters.sort,
            (page - 1) * 100,
            100,
            "",
            "",
            "",
            !(dateRange[0] === null || dateRange[1] === null) ? dateRange[0].format('YYYY-MM-DD') : '',
            !(dateRange[0] === null || dateRange[1] === null) ? dateRange[1].format('YYYY-MM-DD') : '',
            (data) => {
                setResults(extractDataFromArray(data, ["records"], []));
                setCount(extractDataFromArray(data, ["count"], []))
                setSpeciesData(extractDataFromArray(data, ["filters", "species"], []))
                extractDataFromArray(data, ["count"], []) % 100 == 0 ?
                    setPageCount(parseInt(extractDataFromArray(data, ["count"], [])))
                    :
                    setPageCount((parseInt(extractDataFromArray(data, ["count"], []) / 100)) + 1)
                setLoading(false);
            }
        );
    }, [searchFilters, page, triggerDate])



    const getActivityType = (results) => {
        let type = extractDataFromArray(results, ['package_json', 'activity_type'], '')
        switch (type) {
            case 'H':
                return "Hunting"
            case 'F':
                return "Fishing"
            case 'FR':
                return "Farm"
            case 'BW':
                return "Bird Watching"
            default:
                return ""
        }
    }

    // console.log("result", results)
    const hoverHandler = (value) => {
        setListingSeq(value)
      }
    const hoverEnd = () =>{
        setListingSeq('')
    }  

    return (<>
        <Grid container sx={{ mt: 0 }}>

            <FilterPopup filterData={speciesData} type={popupConfig.type} open={popupConfig.open} handleClose={handleClose} selectedFilters={selectedSpecies} setSelectedFilters={setSelectedSpecies} handleFilterChange={speciesFilterChange} />

            <Grid item xs={12} sm={12} md={6} className="exp_listing_left" >
                {
                    isNineHundred &&
                    <Box className="search_filter_wrapper" sx={{ gap: 1, display: 'flex', overflow: 'auto', px: 2, py: 2, }}>
                        <Chip
                            className={"search_filter " + searchFilters}
                            style={{ backgroundColor: selectedActivity === '' ? '#1b1b1b' : '', color: selectedActivity === '' ? 'white' : '' }}
                            avatar={<Avatar alt="all" src="../assets/images/icon_images/all_icon.svg" sx={{ padding: 0.6, filter: selectedActivity === '' ? 'invert(1)' : 'invert(0)' }} />}
                            label="All"
                            sx={{
                                backgroundColor: '#E5E7EB',
                                border: 'none',
                                fontWeight : '600'
                            }}
                            variant="outlined"
                            onClick={() => {
                                setSearchFilters({ ...searchFilters, activity: '' });
                                setSelectedActivity('');
                            }}
                        />
                        <Chip
                            className="search_filter"
                            style={{ backgroundColor: selectedActivity === 'H' ? '#1b1b1b' : '', color: selectedActivity === 'H' ? 'white' : '' }}
                            avatar={<Avatar alt="all" src="../assets/images/icon_images/hunting.svg" sx={{ padding: 0.6, filter: selectedActivity === 'H' ? 'invert(1)' : 'invert(0)' }} />}
                            sx={{
                                backgroundColor: '#E5E7EB',
                                border: 'none',
                                fontWeight : '600'
                            }}
                            label="Hunting"
                            variant="outlined"
                            onClick={() => {
                                setSearchFilters({ ...searchFilters, activity: 'H' });
                                setSelectedActivity('H');
                            }}
                        />
                        <Chip
                            className="search_filter"
                            style={{ backgroundColor: selectedActivity === 'F' ? '#1b1b1b' : '', color: selectedActivity === 'F' ? 'white' : '' }}
                            avatar={<Avatar alt="all" src="../assets/images/icon_images/fishing.svg" sx={{ padding: 0.6, filter: selectedActivity === 'F' ? 'invert(1)' : 'invert(0)' }} />}
                            sx={{
                                backgroundColor: '#E5E7EB',
                                border: 'none',
                                fontWeight : '600'
                            }}
                            label="Fishing"
                            variant="outlined"
                            onClick={() => {
                                setSearchFilters({ ...searchFilters, activity: 'F' });
                                setSelectedActivity('F');
                            }}
                        />
                        <Chip
                            className="search_filter"
                            avatar={<Avatar alt="all" src="../assets/images/icon_images/birdwatch.svg" sx={{ padding: 0.6, filter: selectedActivity === 'BW' ? 'invert(1)' : 'invert(0)' }} />}
                            sx={{
                                backgroundColor: '#E5E7EB',
                                border: 'none',
                                fontWeight : '600'
                            }}
                            style={{ backgroundColor: selectedActivity === 'BW' ? '#1b1b1b' : '', color: selectedActivity === 'BW' ? 'white' : '' }}
                            label="Bird Watching"
                            variant="outlined"
                            onClick={() => {
                                setSearchFilters({ ...searchFilters, activity: 'BW' })
                                setSelectedActivity('BW');
                            }}
                        />
                        <Chip
                            className="search_filter"
                            avatar={<Avatar alt="all" src="../assets/images/icon_images/rv_camping.svg" sx={{ padding: 0.6, filter: selectedActivity === 'RV' ? 'invert(1)' : 'invert(0)' }} />}
                            sx={{
                                backgroundColor: '#E5E7EB',
                                border: 'none',
                                fontWeight : '600'
                            }}
                            style={{ backgroundColor: selectedActivity === 'RV' ? '#1b1b1b' : '', color: selectedActivity === 'RV' ? 'white' : '' }}
                            label="Farm and Ranch"
                            variant="outlined"
                            onClick={() => {
                                setSearchFilters({ ...searchFilters, activity: '' })
                                setSelectedActivity('RV');
                            }}
                        />
                        {/* <Chip
                            className="search_filter"
                            avatar={<Avatar alt="all" src="../assets/images/icon_images/horse_riding.svg" sx={{ padding: 0.6, filter: selectedActivity === 'HR' ? 'invert(1)' : 'invert(0)' }} />}
                            sx={{
                                backgroundColor: '#E5E7EB',
                                border: 'none',
                                fontWeight : '600'
                            }}
                            style={{
                                backgroundColor: selectedActivity === 'HR' ? '#1b1b1b' : '', color: selectedActivity === 'HR' ? 'white' : '',
                                '& . MuiAvatarCircular': { filter: selectedActivity === 'HR' ? 'invert(1)' : 'invert(0)' }
                            }}
                            label="Horseback Riding"
                            variant="outlined"
                            onClick={() => {
                                setSearchFilters({ ...searchFilters, activity: '' })
                                setSelectedActivity('HR');
                            }}
                        /> */}
                    </Box>
                }

                <Box sx={{ display: 'flex', p: 2, gap: 1 }}>

                    {/* {
                        empty(searchFilters.start_date) && DateBtn
                    }
                    {
                        !empty(searchFilters.start_date) === 1 &&
                        <Badge
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            badgeContent={"."}
                            color="secondary"
                            sx={{ mr: 2 }}
                        >
                        </Badge>
                    } */}
                    {
                        DateBtn
                    }
                    {
                        SpeciesBtn
                    }
                    {/* {
                        searchFilters.species.length === 1 &&
                        <Badge

                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                            badgeContent={searchFilters.species.length}
                            color="secondary"
                            sx={{ mr: 2 }}
                        >
                            {
                                SpeciesBtn
                            }
                        </Badge>
                    } */}
                    <Select
                        value={searchFilters.sort}
                        onChange={(e) => { setSearchFilters({ sort: e.target.value }) }}
                        IconComponent={Icon.KeyboardArrowDownOutlined}
                        size="small"
                        sx={{
                            transition: 'border-color 0.3s', // Add transition for smooth effect
                        }}
                    >
                        <MenuItem selected value='R'>Most Recent</MenuItem>
                        <MenuItem value='LA'>Low to High Acre</MenuItem>
                        <MenuItem value='HA'>High to Low Acre</MenuItem>
                        <MenuItem value='L'>Low to High Price</MenuItem>
                        <MenuItem value='H'>High to Low Price</MenuItem>
                    </Select>

                </Box>
                <Divider />
                {
                    !mapView && <>
                        <Box className="test" sx={{ p: 2, height: 'calc(100vh - 145px)', overflow: 'auto' }}>
                            {
                                loading && <Box>
                                    <SkeletonPackageCard />
                                    <SkeletonPackageCard />
                                    <SkeletonPackageCard />
                                </Box>
                            }

                            {
                                !loading && results.length === 0 &&
                                <Box sx={{ py: 2, textAlign: 'center' }}>
                                    <Box sx={{ py: 5 }}>
                                        <img src="../assets/images/placeholders/result-not-found.svg" alt="no_result" />
                                    </Box>
                                    <Typography variant="h5">No Perfect matches for your search</Typography>
                                    <Typography variant="body1">Please use a different combination of filters and try again for better results.</Typography>
                                </Box>
                            }
                            {
                                !loading && results.length !== 0 &&
                                <Box sx={{ mb: 2, display: 'flex' }}><Typography variant="caption" sx={{ fontWeight: 600, lineHeight: 1 }}>{count} Results</Typography></Box>
                            }
                            {
                                !loading && results.length !== 0 && results.map((result, i) => {
                                    let address = extractDataFromArray(result, ['listing', 'address'], '')
                                    let listingSlug = extractDataFromArray(result,['listing', 'slug'],'')
                                    let listing_seq = extractDataFromArray(result,['listing', 'seq'],'')
                                    let propertyName = extractDataFromArray(result, ['listing', 'title'], '')
                                    let renderTitle = getActivityType(results[i])
                                    address = extractDataFromArray(result, ['listing', 'area'], 0) + " Acres, " + renderTitle + " at "
                                   let address2 =  " near " + extractDataFromArray(result, ['listing', 'nearest_town'], '')
                                    return (
                                        <>
                                            <Box sx={{ mb: 2 }} key={"result_" + i}
                                                onMouseOver={() => { hoverHandler(extractDataFromArray(result, ['listing', 'seq'], '')) }}
                                                onMouseOut={hoverEnd}
                                            >
                                                <PackageCard
                                                    cardType='package'
                                                    image={extractDataFromArray(result, ['package_json', 'media_library'], '')}
                                                    title={extractDataFromArray(result, ['package_title'], '')}
                                                    guest={extractDataFromArray(result, ['package_json', 'min_guests'], 1)}
                                                    days={extractDataFromArray(result, ['package_json', 'min_days'], 1)}
                                                    address={address}
                                                    address2={address2}
                                                    propertyName={propertyName}
                                                    listingSlug = {extractDataFromArray(result,['listing', 'slug'],'')}
                                                    listing_seq = {extractDataFromArray(result,['listing', 'seq'],'')}
                                                    package_seq={extractDataFromArray(result, ['package_seq'], 0)}
                                                    slug={extractDataFromArray(result, ['package_slug'], 0)}
                                                    size={extractDataFromArray(result, ['listing', 'area'], 0)}
                                                    price={Math.round(extractDataFromArray(result, ['price'], ''))}
                                                    viewTarget="_blank"
                                                    hideOptions={true}
                                                    search={true}
                                                    type={extractDataFromArray(result, ["type"], "")}
                                                    nearestTown={extractDataFromArray(result, ['listing', 'nearest_town'], '')}
                                                    averageRating={extractDataFromArray(result, ['average_rating'], '')}
                                                    reviewCount={extractDataFromArray(result, ['review_count'], '')}
                                                    lodging={extractDataFromArray(result, ['listing', 'lodging_available'], '')}
                                                    rv={extractDataFromArray(result, ['listing', 'rv_available'], '')}
                                                />
                                            </Box>
                                        </>
                                    )

                                })

                            }
                            {
                                <Box sx={{
                                    height: '64px',
                                    width: '100%',
                                    display: pageCount === 0 ? 'none' : 'flex',
                                    alignItems: 'center',
                                    zIndex: '9999',
                                    mb: isNineHundred && 7,
                                }}>
                                    <Pagination
                                        page={page}
                                        onChange={handleChangePage}
                                        count={pageCount}
                                        sx={{
                                            mx: 'auto',
                                            widows: '100%',
                                            justifyContent: 'center',
                                            alignSelf: 'center'
                                        }}
                                    />
                                </Box>
                            }
                        </Box>
                        {/* {!isNineHundred &&
                            <Box sx={{
                                height: '64px',
                                width: { sm: '100%', md: '50%' },
                                display: 'flex',
                                alignItems: 'center',
                                position: 'fixed',
                                mb: 8,
                            }}>
                                <Pagination
                                    page={page}
                                    onChange={handleChangePage}
                                    count={pageCount}
                                    sx={{
                                        mx: 'auto',
                                        widows: '100%',
                                        justifyContent: 'center',
                                        alignSelf: 'center'
                                    }}
                                />
                            </Box>
                        } */}
                    </>
                }



                {/* No result found code */}

            </Grid>
            <Grid item xs={12} sm={12} md={6} sx={{ display: 'block' }} >
                {
                    (!isNineHundred || mapView) &&
                    <Box>
                        <SearchMap results={results} listingSequence={listingSeq}/>
                    </Box>
                }
            </Grid>
            {
                isNineHundred && <>
                    <Button
                        variant="contained"
                        size="small"
                        color="secondary"
                        sx={{
                            borderRadius: 10, width: '100px', border: 'solid 1px #fff', position: 'absolute', bottom: '16px', left: 0, right: 0, margin: 'auto', boxShadow: 'box-shadow: 0 2px 4px 0 rgba(0,0,0,0.5);',
                            "&:hover": {
                                bgcolor: '#1b1b1b'
                            }, zIndex:1
                        }}
                        onClick={() => { setMapView(!mapView) }}>
                        {!mapView ? <><Icon.MapOutlined sx={{ mr: 1 }} /> Map</> : <><Icon.FormatListBulletedOutlined sx={{ mr: 1 }} /> List</>}
                    </Button>
                </>
            }
        </Grid>
        <Dialog open={open} onClose={() => { setOpen(false); }} sx={{ '& .MuiPaper-root': { maxWidth: '768px' } }}>
            <Box sx={{
                display: 'flex', alignItems: 'center',
                justifyContent: 'center', width: '100%', justifyContent: 'space-between'
            }}>
                <DialogTitle sx={{ fontWeight: 'bold' }}>Filters</DialogTitle>
                <IconButton sx={{ height: '42px' }} onClick={() => { setOpen(false); }}>
                    <Icon.Close />
                </IconButton>
            </Box>
            <Divider />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDateRangePicker
                    slots={{ field: SingleInputDateRangeField }}
                    disablePast
                    value={dateRange}
                    disableHighlightToday
                    orientation={!isNineHundred && "landscape"}
                    shouldDisableDate={disableCustomDates}
                    slotProps={{
                        textField: {
                            placeholder: 'Dates'
                        },
                        actionBar: { actions: [] },
                    }}
                    sx={{
                        '& .MuiOutlinedInput-root': {
                            backgroundColor: 'white',
                        },
                        "& .MuiPickersLayout-landscape": { minWidth: '100%' },
                        "&. MuiInputBase-root": {
                            display: 'none'
                        },
                        "& .MuiFormControl-root": {
                            display: 'none'
                        }
                    }}
                    onChange={(dates) => handleDateChange(dates)}
                />
            </LocalizationProvider>
            <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', px: 3, pb: 3 }}>
                <Button variant="text" sx={{ color: 'var(--black)' }}
                    onClick={() => {
                        setDateRange([null, null])
                        setTriggerDate(!triggerDate)
                    }} >
                    Clear All
                </Button>
                <Button variant="contained" onClick={() => { setOpen(false); }}>
                    Show {count} Properties
                </Button>
            </Box>
        </Dialog>
    </>)
}

export default SearchListing;