import { Box, CardMedia, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material';
import { empty, extractDataFromArray, getActivityType } from "../../utils/common";
import { Link, useNavigate } from "react-router-dom";
import { CommonContext } from "../../store/context/commonContext";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';


const PackageCardSmall = ({ image, title, address, rating, review, size = '', nearest_town, borderRadius = 0, width = "60%", slug, area, activity_type, propertyName, listingSlug, listing_seq }) => {
    const navigate = useNavigate()
    const isMobile = CommonContext()
    rating = Number(rating)
    review = Number(review)


    // console.log(address,area,nearest_town)
    const isValidAddress = address === '-' ? false : true;
    // console.log(isValidAddress)



    return (
        <Box sx={{ p: { xs: 1, sm: 1, md: 2, lg: 3 }, mb: isMobile ? 2 : 3, display: 'flex', gap: 2, border: '1px solid var(--grey500)', borderRadius: '8px' }} onClick={
            () => {
                if (!empty(slug)) {
                    navigate("/package/" + slug)
                }
            }}>
            {image.type === 'IMAGE' ? <Box sx={{
                minWidth: '100px',
                height: '90px',
                borderRadius: '8px',
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center center",
                backgroundSize: "cover",
                backgroundImage: "url('" + image.url + "')"
            }} />
                :
                <>
                    {
                        image.thumbnail_url === '' ?
                            <div className='' style={{ position: 'relative', display: 'flex' }}>
                                <div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '90px', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
                                </div>
                                <CardMedia
                                    component='video'
                                    sx={{
                                        width: '100px',
                                        height: '80px',
                                        backgroundSize: 'cover',
                                        borderRadius: '8px',
                                        position: 'relative',
                                        objectFit: 'cover'
                                    }}
                                    image={image.url}
                                />
                            </div>
                            :
                            <div className='' style={{ position: 'relative', display: 'flex' }}>
                                <div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '90px', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
                                </div>
                                <Box sx={{
                                    minWidth: '100px',
                                    height: '90px',
                                    borderRadius: '8px',
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center center",
                                    backgroundSize: "cover",
                                    backgroundImage: "url('" + image.thumbnail_url + "')"
                                }} />
                            </div>

                    }
                </>
            }
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: empty(size) ? '100%' : width, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                <Box>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {title}
                    </Typography>
                    <Typography sx={{  }}>
                        <Typography noWrap sx={{ color: 'var(--grey900)' }} component={'span'}>
                            {area} Acres, {getActivityType(activity_type)} at 
                        </Typography>
                        <Link target={isMobile ? '_self' : '_blank'} to={`/property/${listingSlug}-${listing_seq}`} className="propertyName" style={{ color: 'var(--grey900)', textDecoration: 'underline', fontSize:'14px' }} component={'span'} >
                            {" "+ propertyName + " "} 
                        </Link>
                        <Typography component={'span'} sx={{ color: 'var(--grey900)' }} variant='body2' >
                        near {nearest_town}
                        </Typography>

                    </Typography>
                </Box>
                {review !== 0 && (
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Icon.Star sx={{ color: "#F7C32E" }} />
                            <Typography sx={{ fontWeight: 700 }}>
                                {parseFloat(rating).toFixed(1)}
                            </Typography>
                        </Box>
                        <Typography>
                            {review} reviews
                        </Typography>
                    </Box>
                )}
            </Box>

        </Box>
    )
}

export default PackageCardSmall;