import { Dialog } from "@mui/material";
import ApprovalContent from "./ApprovePopup";
import DeclineContent from "./DeclinePopup";
import SpamContent from "./SpamPopup";
import MoveToPendingContent from "./MoveToPendingPopup";

const AdminListingPopup = ({ open, handleClose, type, listing_seq }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg">

            {
                type === 'approve' && <ApprovalContent handleClose={handleClose} listing_seq={listing_seq} />
            }
            {
                type === 'decline' && <DeclineContent handleClose={handleClose} listing_seq={listing_seq} />
            }
            {
                type === 'spam' && <SpamContent handleClose={handleClose} listing_seq={listing_seq} />
            }
            {
                type === 'pending' && <MoveToPendingContent handleClose={handleClose} listing_seq={listing_seq} />
            }

        </Dialog>
    )
}


export default AdminListingPopup;