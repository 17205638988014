import React, { useState, useEffect } from "react";
import { Box, Divider, Skeleton, TablePagination, Typography } from "@mui/material";
import TableNoData from "../../../commonComponents/placeholders/TableNoData";

import { empty, extractDataFromArray } from "../../../utils/common";
import BookingCard from "../sections/BookingCard";
import BookingService from "../../../services/bookings";
import Pagination from "../../../commonComponents/tableListingComponents/Pagination";
import { UserAuth } from "../../../store/context/userContext";
import { CommonContext } from "../../../store/context/commonContext";



const BookingBody = ({ filter, bookingStatus, setIsRecExistss }) => {
	const { user } = UserAuth()
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [totalRecords, setTotalRecords] = useState(0);
	const { isTablet } = CommonContext()
	const [loading, setLoading] = useState(true)
	const [bookings, setBookings] = useState([])
	const getBookings = () => {
		setLoading(true);
		const startIndex = ((page - 1) * rowsPerPage);
		BookingService.getBookings(bookingStatus, startIndex, rowsPerPage, (data) => {
			setBookings(extractDataFromArray(data, ['records'], []));
			setTotalRecords(extractDataFromArray(data, ['count'], []));
			setIsRecExistss(extractDataFromArray(data, ['is_record_exist'], 1) === 1);
			setLoading(false);
		});
	};

	const [noBookingData, setnoBookingData] = useState({
		header: "",
		title: "",
		buttonLink: "",
		buttonText: "",
	})



	useEffect(() => {
		getBookings();
		// console.log(bookingStatus)
		switch (bookingStatus) {
			case 'A':
				setnoBookingData({
					header: "You have not booked any trips with FarWide yet.",
					title: "Choose from our vast collection of amazing properties. Book your first trip now.",
					buttonLink: "/search",
					buttonText: "Show Me the Properties",
				});
				break;
			case 'R':
				setnoBookingData({
					header: "There are no upcoming bookings right now.",
					title: "Explore some of our recently added properties for your next adventure.",
					buttonLink: "/search",
					buttonText: "Show Me the Properties",
				});
				break;
			case 'CI':
				setnoBookingData({
					header: "You are not currently checked-in to any of our properties.",
					title: "Browse our properties and book one now.",
					buttonLink: "/search",
					buttonText: "Explore Properties Now",
				});
				break;
			case 'N':
				setnoBookingData({
					header: "Any bookings made with FarWide that you might cancel for some reason, will show up here.",
					title: "You have no cancelled bookings currently.",
					buttonLink: "/search",
					buttonText: "Show Me the Properties",
				});
				break;
			case 'D':
				setnoBookingData({
					header: "Any bookings that are declined by hosts due to an unavoidable reason, show up here.",
					title: "You have no declined bookings.",
					buttonLink: "/search",
					buttonText: "Show Me the Properties",
				});
				break;
			case 'M':
				setnoBookingData({
					header: "Completed - Trips you complete with FarWide will show up here.",
					title: "Book and experience one of our amazing properties now.",
					buttonLink: "/search",
					buttonText: "Explore Properties Now",
				});
				break;

			default:
				setnoBookingData({
					header: "You have not booked any trips with FarWide yet.",
					title: "Choose from our vast collection of amazing properties. Book your first trip now.",
					buttonLink: "/search",
					buttonText: "Show Me the Properties",
				});

		}
	}, [bookingStatus, rowsPerPage, page]);




	return (
		<>
			{loading ?
				<Box sx={{ p: { xs: 0, sm: 2, md: 3, lg: 3 }, display: 'flex', gap: 3, flexDirection: 'column', mt: { xs: 2, sm: 0, md: 0, lg: 0 }, mb: 4 }}>
					<Box sx={{ border: '1px solid var(--grey500)', p: 3, display: 'flex', borderRadius: theme => `${theme.card.borderRadius} `, gap: 3, }}>
						<Skeleton variant="rounded" sx={{ height: "185px", width: "30%", borderRadius: theme => `${theme.card.borderRadius} `, }} />
						<Box sx={{ width: '100%' }}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
								<Skeleton variant="rounded" sx={{ height: "40px", width: "50%" }} />
								<Skeleton variant="rounded" sx={{ height: "40px", width: "30%" }} />
							</Box>
							<Skeleton variant="rounded" sx={{ height: "40px", width: "100%", mb: 2 }} />
							<Divider sx={{ mb: 2 }} />
							<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
								<Skeleton variant="rounded" sx={{ height: "40px", width: "25%" }} />
								<Skeleton variant="rounded" sx={{ height: "40px", width: "25%" }} />
								<Skeleton variant="rounded" sx={{ height: "40px", width: "25%" }} />
							</Box>
						</Box>
					</Box>
					<Box sx={{ border: '1px solid var(--grey500)', p: 3, display: 'flex', borderRadius: theme => `${theme.card.borderRadius} `, gap: 3 }}>
						<Skeleton variant="rounded" sx={{ height: "185px", width: "30%", borderRadius: theme => `${theme.card.borderRadius} `, }} />
						<Box sx={{ width: '100%' }}>
							<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
								<Skeleton variant="rounded" sx={{ height: "40px", width: "50%" }} />
								<Skeleton variant="rounded" sx={{ height: "40px", width: "30%" }} />
							</Box>
							<Skeleton variant="rounded" sx={{ height: "40px", width: "100%", mb: 2 }} />
							<Divider sx={{ mb: 2 }} />
							<Box sx={{ display: 'flex', justifyContent: 'space-between', mb: 2 }}>
								<Skeleton variant="rounded" sx={{ height: "40px", width: "25%" }} />
								<Skeleton variant="rounded" sx={{ height: "40px", width: "25%" }} />
								<Skeleton variant="rounded" sx={{ height: "40px", width: "25%" }} />
							</Box>
						</Box>
					</Box>
				</Box>
				:
				<>
					{empty(bookings) ?
						<Box sx={{ p: isTablet ? 0 : 3, pt: isTablet ? 2 : 3 }}>
							<TableNoData
								header={noBookingData.header}
								title={noBookingData.title}
								buttonLink={noBookingData.buttonLink}
								buttonText={noBookingData.buttonText}
								image={!isTablet ? "/assets/images/placeholders/booking_blank_image.svg" : ''}
							/>
						</Box>
						:
						<Box sx={{ p: { xs: 0, sm: 3, md: 3, lg: 3 }, display: 'flex', flexDirection: 'column', gap: { xs: 2, sm: 3, md: 3, lg: 3 }, mt: { xs: 2, sm: 0, md: 0, lg: 0 }, }}>
							{bookings.map((booking, index) => {
								return(
								<BookingCard
									index={index}
									key={"booking" + index}
									title={extractDataFromArray(booking, ["package_title"], "")}
									address={extractDataFromArray(booking, ["address"], "")}
										propertyName = {extractDataFromArray(booking,['listing_title'],'')}
										listingSeq = {extractDataFromArray(booking,['listing_seq'],'')}
										listingSlug= {extractDataFromArray(booking,['listing_slug'],'')}
									nearest_town={extractDataFromArray(booking, ["nearest_town"], "")}
									guest={extractDataFromArray(booking, ["guest_count"], "")}
									days={extractDataFromArray(booking, ["booking_days"], "")}
									checkin={extractDataFromArray(booking, ["check_in_date"], "")}
									checkout={extractDataFromArray(booking, ["check_out_date"], "")}
									checkintime={extractDataFromArray(booking, ['check_in_time'], '')}
									activity_type={extractDataFromArray(booking, ['activity_type'], '')}
									checkouttime={extractDataFromArray(booking, ['check_out_time'], '')}
									booked_by={extractDataFromArray(user, ['fname'], '') + " " + extractDataFromArray(user, ['lname'], '')}
									status={extractDataFromArray(booking, ["booking_status"], "")}
									image={extractDataFromArray(booking, ["package_media"], "")}
									bookingId={extractDataFromArray(booking, ["booking_id"], "")}
									lastUpdateTime={extractDataFromArray(booking, ["booking_update_date_time"], "")}
									reviewId={extractDataFromArray(booking, ["review_id"], "")}
									propertyArea={extractDataFromArray(booking, ["area"], "")}
									packageMedia={extractDataFromArray(booking, ["package_media"], "")}
								/>
							)
							}
							)}
						</Box>}
				</>
			}

			<Pagination totalRecords={totalRecords} setRowsPerPage={setRowsPerPage} setCurrentPage={setPage} />
		</>
	);
};
export default BookingBody;
