import axios from "axios";
import config from "../config";
import { empty, extractDataFromArray, parseResponseAxios } from "../utils/common";
import { toast } from "react-toastify";

// let apiUrl = "https://lgexp.farwide.com"
let apiUrl = config.apiUrl
const PaymentService = {
    isUserVerified: async (lister_seq, callback = () => { }) => {
        await axios
            .get(apiUrl + "/services/payment", {
                params: {
                    cmd: "isUserVerified",
                    lister_seq
                },
            })
            .then((response) => {
                response = parseResponseAxios(response);
                if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
                    callback(extractDataFromArray(response, ["data"], []));
                }
            });
    },
    updateUserStatus: async (lister_seq, callback = () => { }) => {
        let data = new FormData();
        data.append("cmd", "updateVerificationStatus");
        data.append('status', "P");
        await axios({
            url: apiUrl + "/services/api",
            method: "post",
            data: data
        }).then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
                callback(extractDataFromArray(response, ["data"], []));
            }
        });
    },
    getListerInfo: async (lister_seq, callback = () => { }) => {
        await axios
            .get(apiUrl + "/services/payment", {
                params: {
                    cmd: "getListerInfo",
                    lister_seq,
                },
            })
            .then((response) => {
                response = parseResponseAxios(response);
                if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
                    callback(extractDataFromArray(response, ["data"], []));
                }
            });
    },
    createOrder: async (lister_seq, listing_seq, guests, check_in_date, check_out_date, amount, booking_fee, conservation_donation, card_id, package_seq, setLoaderButton, listing_version, package_version, callback = () => { }) => {
        let data = new FormData();
        data.append("cmd", "sendBookingRequest");
        data.append('lister_seq', lister_seq);
        data.append("amount", amount);//
        data.append("booking_fee", booking_fee);//
        data.append("conservation_donation", conservation_donation);//
        data.append("card_id", card_id);
        data.append("package_seq", package_seq);
        data.append("package_version", package_version);
        data.append("listing_version", listing_version);
        data.append("guest_count", guests);
        data.append("check_in_date", check_in_date);
        data.append("check_out_date", check_out_date);

        await axios({
            url: apiUrl + "/services/api",
            method: "post",
            data: data
        }).then((response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                callback(extractDataFromArray(response, ['data'], []))
            } else {
                toast.error(extractDataFromArray(response, ['errorMsg'], 0), {
                    theme: 'colored'
                })
                setLoaderButton(false)
            }
        });
    },
    verifyPayment: async (paymentIntent, paymentIntentClientSecret, redirectStatus, callback = () => { }) => {
        let data = new FormData();
        data.append("cmd", "verifyPayment");
        data.append('paymentIntent', paymentIntent);
        data.append("paymentIntentClientSecret", paymentIntentClientSecret);//
        data.append("redirectStatus", redirectStatus);

        await axios({
            url: apiUrl + "/services/payment",
            method: "post",
            data: data
        }).then((response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                callback(extractDataFromArray(response, ['data'], []))
            } else {
                toast.error(extractDataFromArray(response, ['errorMsg'], 0), {
                    theme: 'colored'
                })
            }
        })
    },
}

export default PaymentService