import axios from "axios";
import config from "../config";
import { extractDataFromArray, parseResponseAxios } from "../utils/common";



const SearchService = {
    search: async (kw, at, sp, sb, start, limit, lt, ln, r, d1, d2, callback = () => { }) => {
        await axios.get(config.apiUrl + "/services/openapi", {
            params: {
                cmd: "search",
                kw,
                at: at === 'A' ? '' : at,
                sp,
                sb,
                start,
                limit,
                lt,
                ln,
                r,
                d1,
                d2
            }
        }).then((response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                callback(extractDataFromArray(response, ['data'], []))
            }
        });
    },
    getActivityType: async(callback = () => { }) => {
        await axios.get(config.apiUrl + "/services/openapi", {
            params: {
                cmd: "getActivityDisplayStrings",
            }
        }).then((response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                callback(extractDataFromArray(response, ['data'], []))
            }
        });
    }
}

export default SearchService;