import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, List, ListItem, ListItemText, Typography, } from "@mui/material";
import "./style.css";
import PersonalInformation from "./sections/PersonalInformation";
import MyInterest from "./sections/MyInterest";
import ChangePassword from "./sections/ChangePassword";
import { Settings } from "@mui/icons-material";
import { CommonContext } from "../../store/context/commonContext";
import { showZendeskLauncher } from "../../utils/common";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";

const LeftCard = ({ menuItems, handleMenuItemClick, selectedMenuItem, isMobile }) => (
	<Box>
		<Card
			sx={{
				borderRadius: "1rem",
				border: "1px solid #E5E7EB",
				boxShadow: "none",
				'& .MuiCardContent-root': {
					p: isMobile ? 0 : 2,
					mx: 1
				},
				'& .MuiCardContent-root:last-child': {
					p: isMobile ? 0 : 2,
				}
			}}
		>
			<CardContent sx={!isMobile && { p: '1rem !important' }}>
				<List component="nav">
					{menuItems.map((item, index) => (
						<ListItem
							key={index}
							sx={{
								backgroundColor: selectedMenuItem === index ? "#E0F7EC" : "inherit",
								borderRadius: "0.5rem",
								mb: !isMobile && 0.5,
								cursor: 'pointer',
								padding: '4px 16px',
								"&:hover": {
									backgroundColor: "#E0F7EC",
									// color: "#26C77A",
								},
							}}
							onClick={() => handleMenuItemClick(index)}
						>
							<ListItemText
								disableTypography
								primary={<Typography sx={{ fontWeight: "600", color: selectedMenuItem === index ? "#26C77A" : "inherit", }} >{item}</Typography>} />
						</ListItem>
					))}
				</List>
			</CardContent>
		</Card>
	</Box>
);
const Profile = () => {
	const navigate = useNavigate();

	const menuItems = [
		"Personal Information",
		"Change Password",
		"My Interests",
		// "Settings",
	];
	const { isMobile } = CommonContext();
	const [searchParams, setSearchParams] = useSearchParams();
	let selectedMenu = searchParams.get("s")
    let selectedMenuIndex = 0;
	if (selectedMenu === 'changepassword') {
		selectedMenuIndex = 1
	} else if(selectedMenu === 'myinterest') {
		selectedMenuIndex = 2
	} else if(selectedMenu === 'personal') {
		selectedMenuIndex= 0
	}
	
	const [selectedMenuItem, setSelectedMenuItem] = useState(selectedMenuIndex);
	const handleMenuItemClick = (index) => {
		setSelectedMenuItem(index);

		const menuParam = ["personal", "changepassword",'myinterest'][index]; // Added this to add param to url for respective tab
		navigate(`?s=${menuParam}`, { replace: true });
	};
	const renderCard = () => {
		switch (selectedMenuItem) {
			case 0:
				return <PersonalInformation />;
			case 1:
				return <ChangePassword />;
			case 2:
				return <MyInterest />;
			case 3:
				return <Settings />;

			default:
				return <PersonalInformation />;
		}
	};

	useEffect(() => {
        showZendeskLauncher(true)
    	const cleanup = () => { // Added clean up to remove params from url when gpoing to other pages
			const currentPath = window.location.pathname;
			if (currentPath !== "/profile") {
			  setSearchParams({});
			  navigate("/profile", { replace: true });
			}
		  };
	  
		  window.addEventListener("beforeunload", cleanup);
	  
		  return () => {
			window.removeEventListener("beforeunload", cleanup);
		  };
	}, [navigate, setSearchParams])

	return (
		<>
			<Grid
				container
				maxWidth="lg"
				sx={{
					display: "flex",
					border: "1px solid #fff",
					justifyContent: "space-between",
					alignItems: "center",
				}}
			>
				<Typography variant="h4" sx={{ mb: 2 }}>
					Account Settings
				</Typography>
			</Grid>
			<Grid
				container
				maxWidth="lg"
				sx={{ m: "auto" }}
				spacing={{ xs: 0, md: 3 }}
			>
				<Grid item xs={12} sm={12} md={4} sx={{ mb: { xs: 2 } }}>
					<LeftCard
						menuItems={menuItems}
						handleMenuItemClick={handleMenuItemClick}
						selectedMenuItem={selectedMenuItem}
						isMobile={isMobile}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={8}>
					{renderCard()}
				</Grid>
			</Grid>
		</>
	);
};

export default Profile;
