import axios from "axios";
import config from "../config";
import { extractDataFromArray, parseResponseAxios } from "../utils/common";
import { toast } from "react-toastify";




const Review = {

	saveReviews: async (finalJsonData, successCB = () => { }, errorCB = () => { }) => {
		// console.log("data to api", finalJsonData)
		const formData = new FormData();
		formData.append('cmd', 'saveReviews');
		formData.append('booking_id', extractDataFromArray(finalJsonData,['booking_id'], ''));
		formData.append('listing_seq', extractDataFromArray(finalJsonData,['listing_seq'], ''));
		formData.append('listing_rating', extractDataFromArray(finalJsonData,['listing','rating'], ''));
		formData.append('listing_review', extractDataFromArray(finalJsonData,['listing','review'], ''));
		formData.append('listing_images', JSON.stringify(extractDataFromArray(finalJsonData,['listing', 'images'])).length === 0 ? "[]" : JSON.stringify(extractDataFromArray(finalJsonData,['listing', 'images'])) );
		formData.append('package_seq', extractDataFromArray(finalJsonData,['package_seq'], ''));
		formData.append('package_rating', extractDataFromArray(finalJsonData,['package','rating'], ''));
		formData.append('package_review', extractDataFromArray(finalJsonData,['package','review'], ''));
		formData.append('package_images', JSON.stringify(extractDataFromArray(finalJsonData,['package', 'images'])).length === 0 ? "[]" : JSON.stringify(extractDataFromArray(finalJsonData,['package', 'images'])));
		formData.append('host_seq', extractDataFromArray(finalJsonData,['host_seq'], ''));
		formData.append('host_rating', extractDataFromArray(finalJsonData,['host','rating'], ''));
		formData.append('host_review', extractDataFromArray(finalJsonData,['host','review'], ''));
		formData.append('host_images', JSON.stringify(extractDataFromArray(finalJsonData,['host', 'images'])));

		return axios
		.post(config.apiUrl + "/services/api", formData)
        .then((response) => {
				response = parseResponseAxios(response);

				if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
					const data = extractDataFromArray(response, ['data'], []);

					successCB(data);
				} else {
					toast.error(extractDataFromArray(response, ['errorMsg'], 0), {
						theme: 'colored'
					})
				}
			});
	},

	getReviewsPublic: async(type, listingSeq, start, limit, successCB = () => { }, errorCB = () => { }) => {
	
		await axios.get(config.apiUrl + "/services/openapi", {
            params: {
                cmd: "getReviewsPublic",
                type: type,
				type_seq:listingSeq, 
				start:start, 
				limit:limit
            }
        })
          .then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
              const data = extractDataFromArray(response, ['data','records'], []);
			  
              successCB(data);
            }
            else {
                toast.error(extractDataFromArray(response, ['errorMsg'], 0), {
                    theme: 'colored'
                })
            }
          });
	}	,
	getReviewDetails: async(bookingId, successCB = () => { }, errorCB = () => { }) => {
	
		await axios.get(config.apiUrl + "/services/api", {
            params: {
                cmd: "getReviewDetails",
                booking_id: bookingId
            }
        })
          .then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
              const data = extractDataFromArray(response, ['data','reviews'], []);
			  
              successCB(data);
            }
            else {
                toast.error(extractDataFromArray(response, ['errorMsg'], 0), {
                    theme: 'colored'
                })
            }
          });
	}
};

export default Review;