import { useLocation } from "react-router";
import { Box, Button, Divider, Grid, Paper, Skeleton, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material';
import PackageCardSmall from "../../commonComponents/PackageCardSmall";
import { useEffect, useState } from "react";
import PaymentService from "../../services/payments";
import { extractDataFromArray } from "../../utils/common";
import { UserAuth } from "../../store/context/userContext";
import dayjs from "dayjs";
import { Link } from "react-router-dom";
import { ROUTE_BOOKINGS } from "../../constants/page";
import { CommonContext } from "../../store/context/commonContext";
import PackageCardMobile from "../../commonComponents/PackageCardMobile";

const Payment = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const paymentIntent = queryParams.get("payment_intent");
    const paymentIntentClientSecret = queryParams.get("payment_intent_client_secret");
    const redirectStatus = queryParams.get("redirect_status");

    const { user } = UserAuth();
    const [packageData, setPackageData] = useState({})
    const [listingData, setListingData] = useState({})
    const [orderData, setOrderData] = useState({})
    const { isMobile } = CommonContext();
    const [bookingData, setBookingData] = useState({})
    const [transactionData, setTransactionData] = useState({})
    const [loading, setLoading] = useState(true);
    if (redirectStatus === 'succeeded') {

    }

    // console.log(extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], 1))

    useEffect(() => {
        PaymentService.verifyPayment(paymentIntent, paymentIntentClientSecret, redirectStatus, (data) => {
            setLoading(false)
            setPackageData(extractDataFromArray(data, ['package_rec'], {}))
            setListingData(extractDataFromArray(data, ['listing_rec'], {}))
            setOrderData(extractDataFromArray(data, ['order_rec'], {}))
            setBookingData(extractDataFromArray(data, ['booking_rec'], {}))
            setTransactionData(extractDataFromArray(data, ['transaction_rec'], {}))
        })
    }, [])

    // console.log(packageData)

    return (
        <>
            {!isMobile ?
                <Box maxWidth={685} sx={{ mx: 'auto', mt: 4, border: '1px solid var(--grey700)', borderRadius: (theme) => `${theme.card.borderRadius} `, }}>
                    {
                        loading && <>
                            <Box sx={{ p: 2.5, }}>
                                <Skeleton variant="rectangular" sx={{ pb: '144px', borderRadius: 2, mb: 2 }} />
                                <Skeleton variant="rectangular" sx={{ height: '320px', borderRadius: 2, }} />
                            </Box>
                        </>
                    }
                    {
                        !loading &&
                        <>
                            <Box sx={{ p: 2.5, paddingBottom: redirectStatus !== 'succeeded' ? 0 : 2.5 }}>
                                <PackageCardSmall
                                    address={extractDataFromArray(listingData, ['address'], '')}
                                    image={extractDataFromArray(packageData, ['package_json', 'media_library', '0'], '')}
                                    rating={extractDataFromArray(packageData, ['average_rating'], 0)}
                                    review={extractDataFromArray(packageData, ['review_count'], 0)}
                                    title={extractDataFromArray(packageData, ['title'], '')}
                                    area={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], 1)}
                                    nearest_town={extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '')}
                                    activity_type={extractDataFromArray(packageData, ['package_json', 'activity_type'], '')}
                                    propertyName={extractDataFromArray(listingData, ['listing_json', 'property_details', 'name'], '')}
                                    listingSlug={extractDataFromArray(listingData, ['listing_json', 'property_details', 'slug'], '')}
                                    listing_seq={extractDataFromArray(listingData, ['listing_seq'], '')}
                                    borderRadius={2}
                                    width={"75%"}
                                />
                                {
                                    redirectStatus === 'succeeded' &&
                                    <Box sx={{ textAlign: 'center', mt: 4 }}>
                                        <Typography variant="h4" sx={{ mb: 1 }}>🎉 Booking request sent</Typography>
                                        <Typography sx={{ color: 'var(--grey900)' }}>The owner of this property will respond to your request soon.
                                            Please have a look at your booking details below.
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                            {
                                redirectStatus === 'succeeded' &&
                                <>
                                    <Divider />
                                    <Box sx={{ display: 'flex', p: 2.5, justifyContent: 'space-between', flexWrap: 'wrap' }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "100%", py: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
                                                <Icon.ConfirmationNumberOutlined />
                                                <Typography>Booking ID:</Typography>
                                            </Box>
                                            <Typography>BS-{extractDataFromArray(bookingData, ['booking_id',], '')}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "45%", py: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Icon.CalendarMonthOutlined />
                                                <Typography>Booking Date:</Typography>
                                            </Box>
                                            <Typography>{dayjs(extractDataFromArray(orderData, ['booking_date'], '')).format("DD MMM YYYY")}</Typography>
                                        </Box>
                                        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "45%", py: 1 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Icon.Person2Outlined />
                                            <Typography>Booking By:</Typography>
                                        </Box>
                                        <Typography>{user.name}</Typography>
                                    </Box> */}
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "45%", py: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Icon.CalendarMonthOutlined />
                                                <Typography>Tour Date:</Typography>
                                            </Box>
                                            <Typography>{dayjs(extractDataFromArray(orderData, ['check_in_date'], '')).format("DD MMM YYYY")}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "45%", py: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Icon.AccountBalanceOutlined />
                                                <Typography>Payment Method:</Typography>
                                            </Box>
                                            <Typography>{"Card"}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "45%", py: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Icon.PeopleOutlineOutlined />
                                                <Typography>Guest:</Typography>
                                            </Box>
                                            <Typography>{extractDataFromArray(orderData, ['guest_count'], 0)}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "45%", py: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Icon.AttachMoneyOutlined />
                                                <Typography>Total Price:</Typography>
                                            </Box>
                                            <Typography>${parseFloat(extractDataFromArray(transactionData, ['amount'], 0)).toFixed(2)}</Typography>
                                        </Box>
                                    </Box>

                                    <Box sx={{ display: 'flex', p: 2.5, justifyContent: 'center', flexWrap: 'wrap' }}>
                                        <Box>
                                            {/* <Button variant="contained" sx={{ mr: 1 }}><Icon.SimCardDownloadOutlined sx={{ mr: 1 }} /> Download PDF</Button>
                                        <Button variant="light"><Icon.ShareOutlined sx={{ mr: 1 }} /> Share</Button> */}
                                        </Box>
                                        <Link to={ROUTE_BOOKINGS}>
                                            <Button variant="contained"><Icon.EventOutlined sx={{ mr: 1 }} /> View Details</Button>
                                        </Link>
                                    </Box>
                                </>
                            }
                            {
                                redirectStatus !== 'succeeded' &&
                                <Box sx={{ p: 2.5, textAlign: 'center', paddingTop: 0 }}>
                                    <Icon.CancelRounded sx={{ color: "#F35150", fontSize: '54px', mb: 2 }} />
                                    <Typography variant="h5" sx={{ fontWeight: 700, mb: 2 }}>Sorry, <br />Your payment failed</Typography>
                                    <Typography sx={{ mb: 4, color: 'var(--grey900);' }}>Please try again.</Typography>
                                    <Button variant="contained" sx={{ mr: 1 }} onClick={() => { window.history.back() }}> Try Again</Button>
                                </Box>
                            }
                        </>
                    }
                </Box>
                :
                <Box maxWidth={685} sx={{ mx: 'auto' }}>
                    {
                        loading && <>
                            <Box>
                                <Skeleton variant="rectangular" sx={{ pb: '144px', borderRadius: 2, mb: 2 }} />
                                <Skeleton variant="rectangular" sx={{ height: '320px', borderRadius: 2, }} />
                            </Box>
                        </>
                    }
                    {
                        !loading &&
                        <>
                            <Box sx={{ paddingBottom: redirectStatus !== 'succeeded' ? 0 : 2.5 }}>
                                <Box sx={{ display: 'flex' }}>
                                    <PackageCardMobile
                                        image={extractDataFromArray(packageData, ['package_json', 'media_library', '0'], '')}
                                        title={extractDataFromArray(packageData, ['title'], '')}
                                        area={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], '')}
                                        address={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_address'], '')}
                                        nearest_town={extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '')}
                                        thumbnail={extractDataFromArray(packageData, ['package_json', 'media_library', '0','thumbnail_url'], '')}
                                        activity_type={extractDataFromArray(packageData, ['package_json', 'activity_type'], '')}
                                        propertyName={extractDataFromArray(listingData, ['listing_json', 'property_details', 'name'], '')}
                                        listingSlug={extractDataFromArray(listingData, ['listing_json', 'property_details', 'slug'], '')}
                                        listing_seq={extractDataFromArray(listingData, ['listing_seq'], '')}
                                        type={extractDataFromArray(packageData, ['package_json', 'media_library', '0', 'type'], '')}
                                        showBorder={false}
                                    />
                                </Box>
                                {
                                    redirectStatus === 'succeeded' &&
                                    <Box sx={{ textAlign: 'center', mt: 2, boxSizing: 'border-box', border: '1px solid #FFFFFF', borderRadius: 4, bgcolor: '#f5f5f5', p: 2 }}>
                                        <Typography variant="h4" sx={{ mb: 1 }}>🎉 <br /> Booking request sent</Typography>
                                        <Typography sx={{ color: 'var(--grey900)' }}>The owner of this property will respond to your request soon.
                                            Please have a look at your booking details below.
                                        </Typography>
                                    </Box>
                                }
                            </Box>
                            {
                                redirectStatus === 'succeeded' &&
                                <>
                                    {!isMobile && <Divider />}
                                    <Box sx={{ display: 'flex', flexDirection: 'column', mb: 9 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "100%", py: 1 }}>
                                            <Box sx={{ display: 'flex', gap: 1 }}>
                                                <Icon.ConfirmationNumberOutlined />
                                                <Typography sx={{ minWidth: '170px', color: 'var(--grey900)' }}>Booking ID:</Typography>
                                            </Box>
                                            <Typography
                                                sx={{
                                                    maxWidth: '200px',
                                                    fontSize: '16px',
                                                    flexWrap: 'wrap',
                                                    wordBreak: 'break-all',

                                                }}
                                            >
                                                BS-{extractDataFromArray(bookingData, ['booking_id',], '')}
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                                <Icon.CalendarMonthOutlined />
                                                <Typography sx={{ color: 'var(--grey900)' }}>Booking Date:</Typography>
                                            </Box>
                                            <Typography>{dayjs(extractDataFromArray(orderData, ['booking_date'], '')).format("DD MMM YYYY")}</Typography>
                                        </Box>
                                        {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', width: "45%", py: 1 }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                            <Icon.Person2Outlined />
                                            <Typography>Booking By:</Typography>
                                        </Box>
                                        <Typography>{user.name}</Typography>
                                    </Box> */}
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '' }}>
                                                <Icon.CalendarMonthOutlined />
                                                <Typography sx={{ color: 'var(--grey900)' }}>Tour Date:</Typography>
                                            </Box>
                                            <Typography>{dayjs(extractDataFromArray(orderData, ['check_in_date'], '')).format("DD MMM YYYY")}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '' }}>
                                                <Icon.AccountBalanceOutlined />
                                                <Typography sx={{ color: 'var(--grey900)' }}>Payment Method:</Typography>
                                            </Box>
                                            <Typography>{"Card"}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '' }}>
                                                <Icon.PeopleOutlineOutlined />
                                                <Typography sx={{ color: 'var(--grey900)' }}>Guest:</Typography>
                                            </Box>
                                            <Typography>{extractDataFromArray(orderData, ['guest_count'], 0)}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', py: 1 }}>
                                            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, color: '' }}>
                                                <Icon.AttachMoneyOutlined />
                                                <Typography sx={{ color: 'var(--grey900)' }}>Total Price:</Typography>
                                            </Box>
                                            <Typography>${parseFloat(extractDataFromArray(transactionData, ['amount'], 0)).toFixed(2)} </Typography>
                                        </Box>
                                    </Box>
                                    <Box
                                        sx={{
                                            width: '100%',
                                            ml: -2,
                                            height: '82px',
                                            position: 'fixed',
                                            bottom: 0,
                                            backgroundColor: 'white',
                                            display: 'flex',
                                            alignItems: 'center', // Vertically align the items
                                            justifyContent: 'space-between',
                                            flexDirection: 'column',
                                            boxShadow: '0 -2px 4px 0 rgba(0,0,0,0.15)',
                                            zIndex: '9999',
                                            pl: 2,
                                            pr: 2,
                                            p: 2
                                        }}
                                    >
                                        <Link to={ROUTE_BOOKINGS}>
                                            <Button
                                                variant="light"
                                                fullWidth
                                                sx={{ my: 'auto' }}><Icon.EventOutlined sx={{ mr: 1 }} /> View Details</Button>
                                        </Link>
                                    </Box>
                                </>
                            }
                            {
                                redirectStatus !== 'succeeded' &&
                                <Box sx={{ p: 2.5, textAlign: 'center', paddingTop: 0 }}>
                                    <Icon.CancelRounded sx={{ color: "#F35150", fontSize: '54px', mb: 2 }} />
                                    <Typography variant="h5" sx={{ fontWeight: 700, mb: 2 }}>Sorry, <br />Your payment failed</Typography>
                                    <Typography sx={{ mb: 4, color: 'var(--grey900);' }}>Please try again.</Typography>
                                    <Button variant="contained" sx={{ mr: 1 }} onClick={() => { window.history.back() }}> Try Again</Button>
                                </Box>
                            }
                        </>
                    }
                </Box>}
        </>
    )
}
export default Payment;