import React from "react";
import { Card, Box, Button, Typography, Divider, Grid, CardMedia } from "@mui/material";

import * as Icon from "@mui/icons-material";

import { Link } from "react-router-dom";
import { convertTo24HourFormat, convertUtcToLocal, extractDataFromArray, formatDate, generatePhrase, getActivityType, getImageUrl } from "../../../utils/common";
import { ROUTE_MANAGEBOOKINGS } from "../../../constants/page";
import dayjs from "dayjs";
import { CommonContext } from "../../../store/context/commonContext";
import PackageCardMobile from "../../../commonComponents/PackageCardMobile";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';





const getStatusMessage = (status) => {
	switch (status) {
		case 'R':
			return <Typography sx={{ color: "#e94a4a" }}>Owner Approval Pending</Typography>
		case 'C':
			return <Typography sx={{ color: "#26C77A" }}>Confirmed</Typography>
		case 'D':
			return <Typography sx={{ color: "#e94a4a" }}>Declined</Typography>
		case 'N':
			return <Typography sx={{ color: "#e94a4a" }}>Booking Cancelled</Typography>
		case 'CR':
			return <Typography sx={{ color: "#e94a4a" }}>Requested to Cancel</Typography>
		case 'M':
			return <Typography sx={{ color: "#26C77A" }}>Completed</Typography>
		case 'F':
			return <Typography sx={{ color: "#e94a4a" }}>Booking Failed</Typography>
		case 'CI':
			return <Typography sx={{ color: "primary" }}>Checked-In</Typography>
		default:
			return ''
	}
}
const BookingCard = ({ title, address, nearest_town, guest, days, checkin, checkout, booked_by, status, date, image, bookingId, lastUpdateTime, reviewId, checkintime, checkouttime, propertyArea, activity_type, packageMedia, propertyName, listingSeq, listingSlug }) => {
	const { isMobile, isNineHundred } = CommonContext();
	const checkinDateTime = checkin.split(' ')[0] + ' ' + convertTo24HourFormat(checkintime)
	const checkoutDateTime = checkout.split(' ')[0] + ' ' + convertTo24HourFormat(checkouttime)
	console.log(image.type)
	return (<>
		{!isNineHundred ?
			<Box sx={{ borderRadius: theme => `${theme.card.borderRadius} `, p: 3, border: "1px solid var(--grey500)", display: 'flex', gap: 3, }} >
				{packageMedia?.type === 'IMAGE' ? <Box sx={{
					width: {
						xs: '100%',
						sm: '200px',
						md: '230px',
					},
					borderRadius: '8px',
					backgroundRepeat: "no-repeat",
					backgroundPosition: "center center",
					backgroundSize: "cover",
					backgroundImage: "url('" + getImageUrl(extractDataFromArray(image, ['url'], ''), "m") + "')"
				}} />
					:
					<>
						{
							!packageMedia.thumbnail_url ?
								<div className='' style={{ position: 'relative', display: 'flex' }}>
									<div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '257px', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
										<PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
									</div>
									<CardMedia
										component='video'
										sx={{
											width: {
												xs: '100%',
												sm: '200px',
												md: '230px',
											},
											backgroundSize: 'cover',
											borderRadius: '8px',
											position: 'relative',
											objectFit: 'cover',
											height: '257px'
										}}
										image={extractDataFromArray(image, ['url'], '')}
									/>
								</div>
								:
								<div className='' style={{ position: 'relative', display: 'flex' }}>
									<div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
										<PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
									</div>
									<Box sx={{
										width: {
											xs: '100%',
											sm: '200px',
											md: '230px',
										},
										borderRadius: '8px',
										backgroundRepeat: "no-repeat",
										backgroundPosition: "center center",
										backgroundSize: "cover",
										backgroundImage: "url('" + packageMedia?.thumbnail_url + "')"
									}} />
								</div>
						}
					</>
				}
				<Box sx={{
					width: {
						xs: '100%',
						sm: '100%',
						md: "calc(100% - 255px)",
					},
					display: 'flex',
					height: '100%',
					flexDirection: 'column',
					minWidth: "200px"
				}}>
					<Box sx={{
						display: 'flex',
						height: '100%',
						justifyContent: 'space-between',
						minWidth: "200px"
					}}>
						<Box >
							<Typography variant="h6" sx={{ fontWeight: 700, mb: 1, mr: 1 }}>{title}</Typography>
							<Box sx={{ mb: 2 }}>
								<Typography component={'span'} sx={{ color: 'var(--grey900)' }}>{propertyArea} acres, {getActivityType(activity_type)} at </Typography>
								<Link className="propertyName" target={isMobile ? '_self' : '_blank'} to={`/property/${listingSlug}-${listingSeq}`} style={{ color: '#000000', textDecoration: 'underline' }} component={'span'} variant='body2'>
									{propertyName}
								</Link>
								<Typography component={'span'} sx={{ color: 'var(--grey900)' }}>{(' Near ' + nearest_town)}</Typography>
							</Box>
							<Box sx={{ display: 'flex', gap: 3, color: 'var(--grey900)' }}>
								<Box sx={{ display: 'flex', gap: 1 }}>
									<Icon.Person />
									<Typography sx={{ color: 'var(--grey900)' }}>{generatePhrase(guest, 'Guest')}</Typography>
								</Box>
								<Box sx={{ display: 'flex', gap: 1 }}>
									<Icon.CalendarTodayOutlined />
									<Typography sx={{ color: 'var(--grey900)' }}>{generatePhrase(days, 'Day')}</Typography>
								</Box>
							</Box>
						</Box>
						<Box sx={{ display: 'flex', alignItems: 'end', flexDirection: 'column' }}>
							{/* reviewId */}
							<Box sx={{ mb: 1, minWidth: '142px' }}>
								<Link to={"/booking/" + bookingId} >
									<Button variant="light" >View Details</Button>
								</Link>
							</Box>
							{/* <Link to={"/booking/" + bookingId} >
							<Button variant="light" >Submit Review</Button>
						</Link> */}
						</Box>
					</Box>
					<Divider sx={{ my: 2 }} />
					<Box sx={{ display: 'flex', gap: 3 }}>
						<Box sx={{ flexGrow: 1 }}>
							<Typography sx={{ color: 'var(--grey900)' }}>Check-In</Typography>
							<Typography sx={{ fontWeight: 700 }}>{dayjs(checkinDateTime).format("ddd D MMM YYYY, hh:mm A")}</Typography>
						</Box>
						<Box sx={{ flexGrow: 1 }}>
							<Typography sx={{ color: 'var(--grey900)' }}>Check Out</Typography>
							<Typography sx={{ fontWeight: 700 }}>{dayjs(checkoutDateTime).format("ddd D MMM YYYY, hh:mm A")}</Typography>
						</Box>
						<Box sx={{ flexGrow: 1, textAlign: 'right' }}>
							{getStatusMessage(status)}
							<Typography variant="caption" sx={{ color: "var(--grey900)" }}>
								{convertUtcToLocal(dayjs(lastUpdateTime).format("ddd D MMM YYYY, hh:mm A"))}
							</Typography>
							{/* <Box sx={{ flexGrow: 1 }}>
						<Typography sx={{ color: 'var(--grey900)' }}>Booked by</Typography>
						<Typography sx={{ fontWeight: 700 }}>{booked_by}</Typography>
					</Box> */}
						</Box>
					</Box>
				</Box>
			</Box>
			:
			<Box sx={{ borderRadius: theme => `${theme.card.borderRadius} `, p: 2, border: "1px solid var(--grey500)", display: 'flex', flexDirection: 'column', gap: 2, }} >
				<PackageCardMobile
					image={image}
					title={title}
					area={propertyArea}
					propertyName={propertyName}
					listingSlug={listingSeq}
					listing_seq={listingSeq}
					address={address}
					nearest_town={nearest_town}
					showBorder={false}
					activity_type={activity_type}
					type={image.type}
					thumbnail={extractDataFromArray(packageMedia, ['thumbnail_url'], '')}
				/>
				<Divider />
				<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
					<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
						<Typography sx={{ color: 'var(--grey900)' }}>{guest == 1 ? 'Guest' : 'Guests'}</Typography>
						<Typography sx={{ fontWeight: 'bold' }}>{guest}</Typography>
					</Box>
					<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
						<Typography sx={{ color: 'var(--grey900)' }}>{days == 1 ? 'Day' : 'Days'}</Typography>
						<Typography sx={{ fontWeight: 'bold' }}>{days}</Typography>
					</Box>
					<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
						<Typography sx={{ color: 'var(--grey900)' }}>Check-In</Typography>
						<Typography sx={{ fontWeight: 'bold' }}>{dayjs(checkinDateTime).format("ddd D MMM YYYY, hh:mm A")}</Typography>
					</Box>
					<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
						<Typography sx={{ color: 'var(--grey900)' }}>Check Out</Typography>
						<Typography sx={{ fontWeight: 'bold' }}>{dayjs(checkoutDateTime).format("ddd D MMM YYYY, hh:mm A")}</Typography>
					</Box>
				</Box>
				<Divider />
				<Box sx={{ display: 'flex' }}>
					<Box sx={{ flexGrow: 1, textAlign: 'left' }}>
						{getStatusMessage(status)}
						<Typography variant="caption" sx={{ color: "var(--grey900)" }}>
							{convertUtcToLocal(dayjs(lastUpdateTime).format("ddd D MMM YYYY, hh:mm A"))}
						</Typography>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
						{/* reviewId */}
						<Box>
							<Link to={"/booking/" + bookingId}>
								<Button variant="light" sx={{ width: '137px', height: '42px', fontSize: '15px' }}>View Details</Button>
							</Link>
						</Box>
					</Box>
				</Box>
			</Box>
		}
	</>
	);
};
export default BookingCard;