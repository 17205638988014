import { Box, Button, Divider, IconButton, TextField, Typography } from "@mui/material"
import * as Icon from '@mui/icons-material';
import GreyLabel from "../formElement/GreyLabel";
import { useState } from "react";
import { empty } from "../../utils/common";
import ListingService from "../../services/listing";
import { toast } from "react-toastify";

const ApprovalContent = ({ handleClose, listing_seq }) => {
    const [remark, setRemark] = useState('')
    const [error, setError] = useState('')
    const handleClick = () => {
        if (empty(remark)) {
            setError("Required field.")
            return;
        }
        setError('')
        handleClose()
        ListingService.approveListing(listing_seq, remark, (data) => {
            toast.success("Verification status updated.", { theme: 'colored' })
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        })
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ py: 1.5, px: 2.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}> Approve Listing</Typography>
                <IconButton onClick={handleClose}><Icon.Close /> </IconButton>
            </Box>
            <Divider />
            <Box sx={{ p: 2.5, width: "600px" }}>
                <GreyLabel label="Remark" required></GreyLabel>
                <TextField
                    multiline
                    fullWidth
                    rows={5}
                    placeholder="Type here..."
                    value={remark}
                    onInput={(e) => { setRemark(e.target.value) }}
                    helperText={error}
                    error={!empty(error)}
                    sx={{ mb: 3 }}
                />
                <Button fullWidth variant="contained" color="primary" onClick={handleClick}>Approve</Button>
            </Box>
        </Box>
    )
}

export default ApprovalContent;