import { useState, useRef, useEffect } from 'react';

import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';


// project imports
import * as Icon from "@mui/icons-material"
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { UserAuth } from '../store/context/userContext';
import { Avatar, Box, Divider, ListItemButton, ListItemIcon, ListItemText, Popover, Typography } from '@mui/material';
import { decodeHTML, extractDataFromArray } from '../utils/common';
import { CommonContext } from '../store/context/commonContext';
import ListingService from '../services/listing';

// ==============================|| PROFILE MENU ||============================== //



const ProfileSection = () => {

	let { user, logout } = UserAuth();
	const {isListingPresent, setIsListingPresent} = CommonContext()

	const theme = useTheme();
	const param = useParams();

	const navigate = useNavigate();
	const location = useLocation();

	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [isDashboard, setIsDashboard] = useState(false);
	const [pathValue, setPathValue] = useState('');
	const [open, setOpen] = useState(false);
	/**
	 * anchorRef is used on different componets and specifying one type leads to other components throwing an error
	 * */
	const anchorRef = useRef(null);

	const handleClose = (event) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}
		setOpen(false);
		setAnchorEl(null);
	};

	const handleListItemClick = (event, index, route = '') => {
		setSelectedIndex(index);
		handleClose(event);

		if (route === 'landowner') {
			window.location.href = window.location.origin + '/landowner';
			return
		} else if (route === 'logout') {
			logout()
			return
		}

		if (route && route !== '') {
			navigate(route);
		}
	};
	const handleToggle = (e) => {
		setAnchorEl(e.currentTarget);
		setOpen((prevOpen) => !prevOpen);
	};

	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);


	useEffect(() => {

		async function getAllListings() {
			await ListingService.checkIfListingExists((data) => {
				if(data) {
				  setIsListingPresent(true)
				  setPathValue('/dashboard')
				} else {
				setIsListingPresent(false)
			  }
			});
		}
		getAllListings()

		if(location.pathname === '/dashboard' || location.pathname === '/wishlist') {
			setIsDashboard(true)
		} else {
			setIsDashboard(false)
		}

	}, [user])


	const userProfileItem = [
		{
			label: 'Dashboard',
			icon: <Icon.HomeOutlined />,
			onClick: (e) => {
				handleListItemClick(e, 0, '/dashboard')
			},
			link: '/dashboard'
		},
		{
			label: 'Bookings',
			icon: <Icon.EventOutlined />,
			onClick: (e) => {
				handleListItemClick(e, 0, '/bookings')
			},
			link: '/bookings'
		},
		{
			label: 'Messages',
			icon: <Icon.MessageOutlined />,
			onClick: (e) => {
				handleListItemClick(e, 0, '/chat')
			},
			link: '/chat'
		},
		{
			label: 'Wishlist',
			icon: <Icon.FavoriteBorderOutlined />,
			onClick: (e) => {
				handleListItemClick(e, 0, '/wishlist')
			},
			link: '/wishlist'
		},
		{
			label: 'Transactions',
			icon: <Icon.MonetizationOnOutlined />,
			onClick: (e) => {
				handleListItemClick(e, 0, '/transactions')
			},
			link: '/transactions',
		},
		{
			label: 'Account Settings',
			icon: <SettingsOutlinedIcon />,
			onClick: (e) => {
				handleListItemClick(e, 0, '/profile')
			},
			link: '/profile'
		},
		{
			label: 'Switch to Host',
			icon: <Icon.CompareArrows />,
			onClick: (e) => {
				handleListItemClick(e, 0, 'landowner')
			},
			link: isListingPresent || isDashboard ? `/landowner${pathValue}` : '/landowner'
		},
		{
			label: 'Logout',
			icon: <Icon.Logout />,
			onClick: (e) => {
				handleListItemClick(e, 0, 'logout')
			}
		},
	];

	const [anchorEl, setAnchorEl] = useState(null);


	useEffect(() => {
		if (location.pathname == "/") {
			setSelectedIndex(-1)
		} else {

			const selectedIndex = userProfileItem.findIndex(item => item.link === location.pathname);
			setSelectedIndex(selectedIndex);
		}
	}, [location.pathname, userProfileItem]);

	useEffect(() => {
		const dashboardPaths = [
			'/dashboard',
			'/bookings',
			'/chat',
			'/wishlist',
			'/transactions',
			'/profile',
			'/booking',
			'/transactiondetails'
		];

		const isDashboardPath = dashboardPaths.some(
			(path) =>
				location.pathname === path || location.pathname.includes(path)
		);

		setIsDashboard(isDashboardPath);

		// if (location.pathname === '/wishlist'|| location.pathname.includes('/search') || location.pathname.includes('/property') || location.pathname.includes('/package')) {
		// 	setPathValue('/dashboard')
		// } else if (location.pathname.includes('/chat')) {
		// 	setPathValue("/chat")
		// } else if (location.pathname.includes('/booking')) {
		// 	// setIsDashboard(true)
		// 	setPathValue("/bookings")
		// } else if (location.pathname.includes('/transactiondetails')) {
		// 	setPathValue('/transactions');
		// } else if (location.pathname === '/' && isListingPresent) {
		// 	setPathValue("/dashboard")
		// } else {
		// 	setPathValue(location.pathname)
		// }

		switch (true) {
			case location.pathname === '/wishlist' || location.pathname.includes('/search') || location.pathname.includes('/property') || location.pathname.includes('/package') || location.pathname.includes('/checkout') || location.pathname.includes('/payment') || location.pathname.includes('/review'):
			  setPathValue('/dashboard');
			  break;
			case location.pathname.includes('/chat'):
			  setPathValue('/chat');
			  break;
			case location.pathname.includes('/booking'):
			  // setIsDashboard(true)
			  setPathValue('/bookings');
			  break;
			case location.pathname.includes('/transactiondetails'):
			  setPathValue('/transactions');
			  break;
			case location.pathname === '/' && isListingPresent:
			  setPathValue('/dashboard');
			  break;
			default:
			  setPathValue(location.pathname);
		  }

		// console.log("path name", pathValue)
	}, [user, location.pathname, userProfileItem])



	return (
		<>

			<Avatar
				src={extractDataFromArray(user, ['profile_pic'], '')}
				sx={{
					...theme.typography.mediumAvatar,

					cursor: 'pointer'
				}}
				onClick={handleToggle}
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup="true"
				color="inherit"
				aria-describedby={"user_profile_popup"}
			/>

			<Popover
				id={"user_profile_popup"}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				sx={{ top: "10px", p: 1 }}
				className='viewMorePopupover'
			>
				<Box sx={{ display: 'flex', gap: 0, mb: 1, alignItems: 'center', pl: 2, pr: 2, pt: 1 }}>
					<Avatar
						alt='profile_logo'
						src={extractDataFromArray(user, ['profile_pic'], '')}
						sx={{ width: 52, height: 52, mr: 2 }}
					/>
					<Box>
						<Typography sx={{ fontWeight: 'bold' }}>
							{decodeHTML(`${extractDataFromArray(user, ['fname'], '')} ${extractDataFromArray(user, ['lname'], '')}`)}
						</Typography>
						<Typography>{extractDataFromArray(user, ['email'], '')}</Typography>
					</Box>
				</Box>
				<Divider sx={{ mb: 2, mt: 2 }} />

				{/* {
					userProfileItem.map((item, index) => {
						return <ListItemButton key={"user_profile_" + index} onClick={item.onClick}>
							<ListItemIcon>
								{item.icon}
							</ListItemIcon>
							<ListItemText disableTypography sx={{ fontWeight: '500', color: "#1B1B1B", mb: 0.5 }}
								primary={item.label} />
						</ListItemButton>
					})
				} */}
				{userProfileItem.map((item, index) => {
					return (
						< a
							// Link
							key={`user_profile_${index}`}
							href={item.link} // Add the route to the href attribute
							// to={item.link
							style={{ textDecoration: 'none', color: 'inherit' }}

						>
							<ListItemButton
								key={"user_profile_" + index}
								onClick={(event) => item.onClick(event, index, item.link)}
								selected={selectedIndex === index} // Add selected prop to highlight the selected index
								sx={{
									backgroundColor: index === selectedIndex ? '#E0F7EC' : 'transparent',
									color: index === selectedIndex ? '#E0F7EC' : '#1B1B1B',
									fontWeight: '600',
									marginBottom: '0.5',
									lineHeight: '23px',
									fontSize: '15px',
									transition: 'background-color 0.3s ease',
									'&:hover': {
										backgroundColor: index !== selectedIndex ? 'rgba(0, 0, 0, 0.04) !important' : 'lightgreen'
									},

								}}
							>
								<ListItemIcon sx={{
									color: index === selectedIndex ? '#26C77A' : "#1B1B1B",

									'&:hover': {
										color: index !== selectedIndex ? 'var(--black) !important' : 'lightgreen'
									},
									// '&:hover': {

									//   }
								}}>
									{item.icon}
								</ListItemIcon>
								<ListItemText
									disableTypography
									sx={{
										fontWeight: '500',
										color: index === selectedIndex ? '#26C77A' : "#1B1B1B",
										mb: 0.5,
										// ...(selectedIndex === index && { backgroundColor: '#e0e0e0' }) // Conditionally apply background color
									}}
									primary={item.label}
								/>
							</ListItemButton>

						</a>
						// </Link>
					)
				})}

			</Popover >
		</>
	);
};

export default ProfileSection;