import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import ProfileSection from '../../ProfileSection';
import { CommonContext } from '../../../store/context/commonContext';
import { Divider, FormGroup, InputAdornment, Menu, MenuItem, Select, TextField } from '@mui/material';
import * as Icon from '@mui/icons-material';
import { empty } from '../../../utils/common';
import AuthPopup from '../../../commonComponents/authComponents/AuthPopup';
import { UserAuth } from '../../../store/context/userContext';
import { useEffect, useState } from 'react';
import LinkHref from '@mui/material/Link'
import { ROUTE_LISTER_HOME } from '../../../constants/page';
import config from '../../../config';
import SearchService from '../../../services/search';


const drawerWidth = 267;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - 0px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	position: 'fixed', // Make the position fixed
	top: 0, // Position at the top of the viewport
}));


const Header = (props) => {
	const { user, authPopup, setAuthPopup } = UserAuth()
	const navigate = useNavigate()
	const openAuthPopup = (type) => {
		setAuthPopup({ ...authPopup, type: type, open: true })
	}
	const closeAuthPopup = () => {
		setAuthPopup({ ...authPopup, open: false })
	}
	const { isTablet, isLoginHamburger } = CommonContext();

	const location = useLocation();
	// const showSidebar = ['/checkout'].includes(
	// 	location.pathname
	// );

	const [activityOptions, setActivityOptions] = useState({})


	const handleClearSearch = (e) => {
		setSearchFilters({ 'kw': '' });
		const newSearchParams = new URLSearchParams(window.location.search);
		newSearchParams.set('kw', '');
		const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;

		// Merge existing searchFilters with the updated parameter
		const updatedFilters = { ...searchFilters, kw: '' };

		// Update URL without removing searchFilters
		window.history.pushState({ searchFilters: updatedFilters }, '', newUrl);
	};

	const handleChangeActivity = (e) => {
		// console.log(e.target.value)
		setSearchFilters({ 'activity': e.target.value });
		const newSearchParams = new URLSearchParams(window.location.search);
		newSearchParams.set('at', e.target.value);
		const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;

		// Merge existing searchFilters with the updated parameter
		const updatedFilters = { ...searchFilters, activity: e.target.value };

		// Update URL without removing searchFilters
		window.history.pushState({ searchFilters: updatedFilters }, '', newUrl);
	};

	const handleChangeSearch = (e) => {
		setSearchFilters({ 'kw': e.target.value })
		const newSearchParams = new URLSearchParams(window.location.search);
		newSearchParams.set('kw', e.target.value);
		const newUrl = `${window.location.pathname}?${newSearchParams.toString()}`;

		// Merge existing searchFilters with the updated parameter
		const updatedFilters = { ...searchFilters, kw: e.target.value };

		// Update URL without removing searchFilters
		window.history.pushState({ searchFilters: updatedFilters }, '', newUrl);
	};



	const NotShowSidebar = !['/checkout', '/search', '/package', '/property', '/payment'].some(path => location.pathname.includes(path));

	// console.log(NotShowSidebar)

	const { searchFilters, setSearchFilters } = CommonContext()
	// console.log(searchFilters,'header')
	const { isMobile } = CommonContext();

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const isGuestorLister = config.module === 'guest' ? '' : '/landowner'
	const logoLink = window.location.origin + isGuestorLister + '/assets/images/farwide-new-logo.svg'
	const logoLinkMobile = window.location.origin + isGuestorLister + '/assets/images/farwide-new-logo-white-icon.svg'

	// console.log(logoLink)

	useEffect(() => {
		SearchService.getActivityType((data) => {
			setActivityOptions(data)
		})
	}, [])

	return (
		<AppBar className='fw_appbar' sx={{ background: '#fff' }} position="absolute" open={props.open}>

			<Toolbar
				sx={{
					height: "72px"
				}}
			>
				<Box sx={{ mr: 'auto', display: 'flex', alignItems: 'center', }}>
					{NotShowSidebar &&
						<IconButton
							className='fw_hdr_toggle_btn'
							edge="start"
							aria-label="open drawer"
							onClick={props.toggleDrawer}
							sx={{
								height: '40px',
								width: '40px',
								marginRight: '20px',
								marginLeft: '-4px',
								color: 'var(--black)',
								...(props.open),
								display: { md: 'block' },
								backgroundColor: props.open ? 'white' : 'rgba(0, 0, 0, 0.04)'

							}}
						>
							<MenuIcon />
						</IconButton>
					}
					<Link to="/">
						<Box className="fw_logo" sx={{ display: { xs: 'none', sm: 'none', md: 'block' } }}><img src={logoLink} alt='FarWide' style={{ display: 'block' }} /></Box>
					</Link>
					<Link to="/">
						<Box className="fw_logo_mob" sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, width: '40px' }}><img src={logoLinkMobile} alt='FarWide' style={{ display: 'block' }} /></Box>
					</Link>
				</Box>
				<Box sx={{ margin: 'auto' }}>
					{
						location.pathname !== '/dashboard' &&
						<FormGroup sx={{
							alignItems: 'center', flexWrap: 'nowrap', flexDirection: 'row', justifyContent: 'center', border: 'solid 1px #C5C5C7', borderRadius: '6px', m: 'auto', ml: 2, mr: 2, height: '48px',
							"&:hover": {
								"& .MuiSvgIcon-root": {
									color: 'var(--black)',
								},
							},
						}}>
							<Select
								name="listing_sort"
								size="larg`e"
								value={searchFilters.activity}
								onChange={handleChangeActivity}
								IconComponent={Icon.KeyboardArrowDownOutlined}
								sx={{
									border: 'none',
									"& fieldset": { border: 'none' },
									m: 0,
									pt: 0,
									mb: 0,
									mt: "0px",
									display: { xs: 'none', sm: 'none', md: 'block' },
									'& .MuiSelect-select': { minWidth: '100px' }
								}}
							>
								<MenuItem value={"A"} selected>All Activities</MenuItem>
								{Object.keys(activityOptions).map((key) => (
									<MenuItem key={key} value={key}>{activityOptions[key]}</MenuItem>
								))}
							</Select>
							<Divider orientation="vertical" variant="middle" flexItem sx={{ mt: 0, mb: 0, display: { xs: 'none', sm: 'none', md: 'block' } }} />
							<TextField
								className='top_hdr_search_input'
								placeholder={isTablet ? 'Search..' : 'Where would you like to go?'}
								variant="outlined"
								autoComplete="off" //Added this to avoid password and email or any suggestions by browser
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{
												!empty(searchFilters.kw) &&
												<IconButton sx={{ width: "34px", height: "34px", p: 0, mr: '2px', color: 'var(--black)', backgroundColor: 'var(--grey300)' }} onClick={handleClearSearch}>
													<Icon.Close />
												</IconButton>
											}
											<IconButton variant="contained" style={{ color: empty(searchFilters.kw) ? "#C5C5C7" : "var(--black)", padding: '5px' }} onClick={() => {
												navigate("/search?kw=" + searchFilters.kw + "&at=" + (searchFilters.activity === "A" ? '' : searchFilters.activity))
											}}>
												<Icon.SearchOutlined />
											</IconButton>
										</InputAdornment>
									),
									autoComplete: 'off' //Added this to avoid password and email or any suggestions by browser
								}}
								sx={{
									border: 'none', "& fieldset": { border: 'none' }, m: 0, pt: '2px', pb: '2px', maxWidth: '520px',
									'& .MuiOutlinedInput-root': {
										paddingRight: '2px',
										"& .MuiInputBase-input": {
											padding: '11.5px 8px',
											borderRadius: 0,
											minWidth: { lg: '230px' },
										}
									}
								}
								}
								size='large'
								value={searchFilters.kw}
								onInput={handleChangeSearch}
								onKeyDown={(e) => {
									if (e.key === 'Enter') {
										navigate("/search?kw=" + searchFilters.kw + "&at=" + (searchFilters.activity === "A" ? '' : searchFilters.activity))
									}
								}}

							>
							</TextField>
						</FormGroup>
					}
				</Box>
				<Box sx={{ ml: 'auto' }}>
					{
						empty(user) ?
							<>

								{
									isLoginHamburger &&
									<>
										<Box>
											<IconButton
												id="basic-button"
												aria-controls={open ? 'basic-menu' : undefined}
												aria-haspopup="true"
												aria-expanded={open ? 'true' : undefined}
												sx={{
													color: 'var(--black)',
												}}
												onClick={handleClick}
											>
												<Icon.MenuOutlined />
											</IconButton>

											<Menu
												id="basic-menu"
												anchorEl={anchorEl}
												open={open}
												onClose={handleClose}
												MenuListProps={{
													'aria-labelledby': 'basic-button',
												}}
											>
												<MenuItem><LinkHref href={ROUTE_LISTER_HOME} sx={{ color: 'var(--black)', display: { md: 'block' }, textDecoration: 'none' }}>List Your Property </LinkHref></MenuItem>
												<MenuItem onClick={() => { openAuthPopup('signin') }}>Sign In</MenuItem>
												<MenuItem onClick={() => { openAuthPopup('signup') }}>Sign Up</MenuItem>
											</Menu>

										</Box>

									</>
								}
								{
									!isLoginHamburger &&
									<Box>
										<Button variant="outlined" sx={{ mr: 2 }} onClick={() => { openAuthPopup('signin') }}>Sign In</Button>
										<Button variant="contained" onClick={() => { openAuthPopup('signup') }}>Sign Up</Button>
									</Box>

								}


								<AuthPopup open={authPopup.open} type={authPopup.type} handleClose={() => { closeAuthPopup(false) }} handleOpen={openAuthPopup} />
							</> :
							<Box sx={{ display: 'flex', alignItems: 'center' }}>
								<ProfileSection />
							</Box>
					}
				</Box>
			</Toolbar>
		</AppBar >)
}

export default Header

