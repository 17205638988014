import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Divider,
    Grid,
    MenuItem,
    Select,
    Typography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker, DesktopDateRangePicker, SingleInputDateRangeField } from "@mui/x-date-pickers-pro";
import CustomTooltip from "../CustomTooltip";
import dayjs from "dayjs";
import { LicenseInfo } from "@mui/x-date-pickers-pro";
import { LocalizationProvider } from '@mui/x-date-pickers-pro/';
import { empty, getDateToString } from "../../utils/common";
import { useLocation, useNavigate } from "react-router";
import { toast } from "react-toastify";
import { UserAuth } from "../../store/context/userContext";
// import CustomDateRangePicker from "../../projectComponents/CustomDateRangePicker";

LicenseInfo.setLicenseKey(
    "370e579ab4fef48a1739afecb9c68d3bTz04OTIyMyxFPTE3NDU3NTAwNTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const BookingCardMobile = ({
    packageSeq,
    guests = 0,
    maxGuests,
    minGuests,
    minDays,
    maxDays,
    price = 1,
    listerSeq,
    notAvailableDates,
    handleGuestChange = () => { },
    handleDateChange = () => { },
    setIsReviewPopup,
    isReviewPopup,
    initPayment
}) => {
    const { user, authPopup, setAuthPopup } = UserAuth();

    const openAuthPopup = (type) => {
        setAuthPopup({
            ...authPopup,
            type: type,
            open: true,
            callback: () => {
                handleCheckOut();
            },
        });
    };

    const navigate = useNavigate();
    const location = useLocation();

    const tomorrow = dayjs().add(1, "day");
    const today = dayjs()
    const defaultStartDate = tomorrow;
    const defaultEndDate = tomorrow.add(minDays - 1, "day");
    const [dateRange, setDateRange] = useState([
        // defaultStartDate,
        // defaultEndDate,
        null,
        null
    ]);

    // console.log(notAvailableDates)

    const [errorText, setErrorText] = useState('')
    const [dateError, setDateError] = useState(false)

    const disableCustomDates = (date) => {
        return date.isSame(today, 'day')
            || (notAvailableDates.length > 0 && notAvailableDates.some(d => date.isSame(d, 'day')));
    };


    function dateRangeContainsUnavailable(dateRange, unavailableDates) {

        const start = dayjs(dateRange[0])
        const end = dayjs(dateRange[1])

        for (let date of unavailableDates) {
            const curDate = dayjs(date)
            // console.log(curDate)
            if (date.isBetween(start, end)) {
                return true
            }
        }

        return false
    }

    const setDates = (date) => {
        console.log(date)
        // console.log(minDays===maxDays)
        // console.log(minDays,maxDays)
        if (minDays === maxDays) {
            date[1] = dayjs(date[0]).add(minDays - 1, 'day')
            setDateRange(date)
        } else {
            setDateRange(date)
        }
    }



    const handleSetSelectedDaysCount = (days) => {
        setSelectedDays(isNaN(days) ? 0 : days);
    }


    const handleCheckOut = () => {
        if (empty(user)) {
            openAuthPopup("signup");
            return;
        }
        if (empty(guests) || empty(dateRange[0]) || empty(dateRange[1])) {
            toast.error("Please select booking dates and number of guests.", {
                theme: "colored",
            });
            return;
        }
        const minGuestsParam = "&minGuests=" + minGuests;
        const maxGuestsParam = "&maxGuests=" + maxGuests;
        const minDaysParam = "&minDays=" + minDays;
        const maxDaysParam = "&maxDays=" + maxDays;
        const startDateParam = "&sd=" + getDateToString(dateRange[0]);
        const endDateParam = "&ed=" + getDateToString(dateRange[1]);
        const selectedDaysParam = "&s=" + dayjs(dateRange[1]).diff(dateRange[0], 'days') + 1;
        const packageSeqParam = "&p=" + packageSeq;
        const listingSeqParam = "&lseq=" + listerSeq;

        navigate(
            "/checkout?g=" +
            guests +
            minGuestsParam +
            maxGuestsParam +
            startDateParam +
            endDateParam +
            selectedDaysParam +
            packageSeqParam +
            maxDaysParam +
            listingSeqParam +
            minDaysParam
        );
    };

    const [selectedDays, setSelectedDays] = useState(0);

    useEffect(() => {

        let days = dayjs(dateRange[1]).diff(dateRange[0], 'days') + 1;

        console.log(days)

        if (isNaN(days) || (dateRange[0] === null) || (dateRange[1] === null)) {
            setErrorText("Select valid date range for this package")
            setDateError(true)
        }

        if (dateRangeContainsUnavailable(dateRange, notAvailableDates)) {
            setErrorText(`The selected date range includes some unavailable dates`)
            setDateError(true)
            // console.log(`The selected date range includes some unavailable dates`)
            return
        }


        if (days < minDays) {
            setErrorText(`You can not book this package for less than ${minDays} days.`)
            setDateError(true)
        } else if (days > maxDays) {
            setErrorText(`You can not book this package for more than ${maxDays} days`)
            setDateError(true)
        } else {
            setErrorText('')
            setDateError(false)
        }


        handleSetSelectedDaysCount(days)

        handleDateChange(dateRange);



    }, [notAvailableDates, dateRange]);

    if (isNaN(selectedDays)) {
        selectedDays = 0;
    }




    let total_package_price =
        price * (guests ? parseInt(guests) : minGuests) * selectedDays;
    let booking_fee = parseFloat((total_package_price * 0.05).toFixed(2));
    let conservation_donation = 0;

    return (
        <>
            <Grid item xs={12} sm={12} md={5}>
                <Box
                    sx={{
                        p: 2,
                        border: "1px solid var(--grey500)",
                        borderRadius: (theme) => `${theme.card.borderRadius} `,
                        position: "sticky",
                        top: "100px",
                        color: '#747579'
                    }}
                    variant="outlined"
                >
                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                        <Typography sx={{ mr: 1 }}>Price starts at</Typography>
                        <Typography style={{ fontWeight: "600" }}> ${price}</Typography>
                    </Box>
                    <Box
                    >
                        <Box sx={{ alignItems: "center", mb: 2 }}>
                            <Box sx={{ width: "42px", display: 'flex', mb: 1 }}>
                                <Icon.PersonOutlineOutlined />
                                <Typography sx={{ color: 'var(--grey900)', ml: 1 }}>
                                    Guests
                                </Typography>
                            </Box>
                            <Box>
                                <Select
                                    displayEmpty
                                    value={guests}
                                    IconComponent={Icon.KeyboardArrowDownOutlined}
                                    onChange={handleGuestChange}
                                    sx={{
                                        height: "42px",
                                        width: '100%'
                                    }}
                                >
                                    {Array.from({ length: maxGuests - minGuests + 1 }, (_, index) => (
                                        <MenuItem
                                            key={index + parseInt(minGuests)}
                                            value={index + parseInt(minGuests)}
                                        >
                                            {index + parseInt(minGuests)}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                        <Box sx={{ alignItems: "center" }}>
                            <Box sx={{ display: 'flex', mb: 1 }}>
                                <Icon.CalendarTodayOutlined />
                                <Typography sx={{ color: "var(--grey900)", ml: 1, mb: 1 }}>Add dates</Typography>
                            </Box>
                            <Box sx={{ width: "100%" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DesktopDateRangePicker
                                        calendars={1}
                                        clearable={true}
                                        slots={{ field: SingleInputDateRangeField }}
                                        // label="Select Dates"
                                        slotProps={{
                                            textField: {
                                                size: "small",
                                                InputProps: { endAdornment: <Icon.CalendarMonth />, placeholder: 'Select Dates' },
                                                error: !!dateError,
                                                helperText: errorText
                                            },
                                            actionBar: { actions: ["clear", "accept"] },

                                        }}
                                        sx={{
                                            width: '100%',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: 'white',

                                            }
                                            // minWidth: "unset !important",
                                            // '& .MuiInputBase-root .MuiFormLabel-root .MuiInputLabel-root .Mui-error': {
                                            //     color: 'rgba(0, 0, 0, 0.6)',
                                            // },                                        
                                            // '& .MuiInputBase-root .MuiOutlinedInput-notchedOutline label': {
                                            //     borderColor: 'rgba(0, 0, 0, 0.6)',
                                            // }
                                        }}
                                        format="DD MMM YYYY"
                                        // value={dateRange}
                                        onChange={(date) => setDates(date)}
                                        disablePast
                                        disableHighlightToday
                                        closeOnSelect
                                        shouldDisableDate={disableCustomDates}
                                    // minDate={
                                    //     dateRange[0] != null
                                    //         ? dateRange[0].add(minDays - 1, "day")
                                    //         : null
                                    // }
                                    // maxDate={
                                    //     dateRange[0] != null
                                    //         ? dateRange[0].add(maxDays - 1, "day")
                                    //         : null
                                    // }
                                    />
                                </LocalizationProvider>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                        <Box sx={{ display: "flex", alignItems: "center", gap: 1, zIndex: 9999 }}>
                            <Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
                                Pricing Details
                            </Typography>
                            <CustomTooltip
                                content={
                                    <>The amount paid is inclusive of the FarWide fee. </>
                                }
                            />
                        </Box>
                        <Typography variant="caption">
                            Prices are for exclusive access to this property.
                        </Typography>
                        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
                            <Box>{`$${price} x ${guests} Guest x ${isNaN(selectedDays) ? 0 : selectedDays} Day `}</Box>
                            <Box>${isNaN(total_package_price) ? 0 : total_package_price}</Box>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Box>Platform Fee (5%)</Box>
                            <Box>+${isNaN(booking_fee) ? 0 : booking_fee}</Box>
                        </Box>
                        {/* <Box
                sx={{ display: "flex", justifyContent: "space-between" }}
            >
                <Box>Conservation Donation</Box>
                <Box sx={{ textAlign: "right" }}>+${conservation_donation}</Box>
            </Box> */}
                        <Divider sx={{ mt: 2, mb: 2 }} />
                        <Box
                            sx={{
                                display: "flex",
                                fontWeight: "600",
                                justifyContent: "space-between",
                            }}
                        >
                            <Box>Total</Box>
                            <Box>
                                ${isNaN(total_package_price + conservation_donation + booking_fee) ? 0 : (total_package_price + conservation_donation + booking_fee)}
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Grid>
            <Box
                sx={{
                    width: '100%',
                    ml: -2,
                    height: '82px',
                    position: 'fixed',
                    bottom: 0,
                    backgroundColor: 'white',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    display: 'flex',
                    flexDirection: 'column',
                    boxShadow: '0 -2px 4px 0 rgba(0,0,0,0.15)',
                    zIndex: '9999',
                    pl: 2,
                    pr: 2
                }}
            >

                <Button
                    fullWidth
                    size="large"
                    variant="contained"
                    disabled={dateError}
                    onClick={location.pathname.includes('checkout') ? () => {
                        setIsReviewPopup(false)
                        initPayment();
                    } : handleCheckOut}
                    sx={{ mt: 'auto', mb: 'auto' }} // Vertically center the button

                >
                    Confirm to Pay
                </Button>

            </Box>
        </>
    );
};

export default BookingCardMobile;