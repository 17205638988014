import { Avatar, Box, Button, Divider, Typography } from "@mui/material";
import { extractDataFromArray } from "../../utils/common";
import * as Icon from "@mui/icons-material";
import { Link } from "react-router-dom";
import { ROUTE_SUBMIT_REVIEW } from "../../constants/page";
import { CommonContext } from "../../store/context/commonContext";
import { useEffect, useState } from "react";
import Review from "../../services/review";
import { toast } from "react-toastify";
import moment from "moment";
import { UserAuth } from "../../store/context/userContext";
import { ImagePreview } from "../previewPageComponents/HeaderGallery";

const ReviewRecievedCard = ({ listingData, type }) => {
    const { isTablet } = CommonContext();
    const [revieRecievedData, setRevieRecievedData] = useState([]);
    const [start, setStart] = useState();
    const [limit, setLimit] = useState();
    const [saveUserSeq, setSaveUserSeq] = useState('');
    const [listingSeq, setListingSeq] = useState(
        extractDataFromArray(listingData, ['package_seq'], '') === ""
            ? extractDataFromArray(listingData, ['listing_seq'], '')
            : extractDataFromArray(listingData, ['package_seq'], '')
    );
    const { user } = UserAuth();

    const [open, setOpen] = useState(false);
    const [selectedImages, setSelectedImages] = useState([]);
    const [selectedImagesIndex, setSelectedImagesIndex] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setSelectedImages([]); // Reset selected images when closing
        setSelectedImagesIndex(null)
    };

    const handleOpen = (images, index) => {
        setSelectedImages(images); // Set the images and the index of the clicked image
        setSelectedImagesIndex(index)
        setOpen(true);
    };

    useEffect(() => {
        Review.getReviewsPublic(type, listingSeq, start, limit,
            (data) => setRevieRecievedData(data),
            (error) => toast.error(error, { theme: 'colored' })
        );
        setSaveUserSeq(extractDataFromArray(user, ['seq'], ''));
    }, []);

    return (
        <>
            {revieRecievedData.length > 0 && (
                <Box>
                    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                        <Typography variant="h4">Reviews</Typography>
                        <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 1 }}>
                            <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                {"(" + extractDataFromArray(listingData, ['review_count'], '') + ")"}
                            </Typography>
                            <Box sx={{ display: "flex", flexDirection: "row" }}>
                                <Icon.Star fontSize="small" sx={{ color: "#F7C32E" }} />
                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                    {extractDataFromArray(listingData, ['average_rating'], '')}
                                </Typography>
                            </Box>
                        </Box>
                    </Box>

                    <Divider sx={{ mb: 4, mt: 2 }} />
                    {revieRecievedData.map((review, i) => (
                        <Box key={i} sx={{ mb: 5, mt: 5 }}>
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 2, mb: 2 }}>
                                <Box>
                                    <Avatar
                                        alt="Remy Sharp"
                                        src={extractDataFromArray(review, ['guest', 'profile_pic'], '')}
                                        sx={{ width: 52, height: 52 }}
                                    />
                                </Box>
                                <Box sx={{ width: "100%" }}>
                                    <Box sx={{ mb: 2, width: "100%" }}>
                                        <Box sx={{
                                            display: "flex", gap: "15%", justifyContent: "space-between",
                                            width: "100%"
                                        }}>
                                            <Box>
                                                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                                    {extractDataFromArray(review, ['guest', "fname"], '') + " " + extractDataFromArray(review, ['guest', "lname"], '')}
                                                </Typography>
                                                <Typography variant="body1" sx={{ textWrap: { xs: 'wrap', sm: 'wrap', md: 'nowrap', lg: "nowrap" } }}>
                                                    {"Reviewed by Guest on " + moment(extractDataFromArray(review, ['review_date'], ''), "DD-MMM-YYYY").format("MMMM YYYY")}
                                                </Typography>
                                            </Box>
                                            <Box sx={{ display: { xs: "none", md: "flex" }, alignItems: "center", gap: 1, justifyContent: "flex-end" }}>
                                                {extractDataFromArray(review, ['guest', 'guest_seq'], '') === saveUserSeq && (
                                                    <Link to={ROUTE_SUBMIT_REVIEW + `?bookingId=${extractDataFromArray(review, ['booking_id'], '')}`}>
                                                        <Button startIcon={<Icon.EditOutlined sx={{ color: "#747579" }} />} variant="outlined" sx={{
                                                            paddingBlock: 1, paddingInline: 1.5, backgroundColor: '#FFFFFF', fontWeight: 400, lineHeight: 1.5, border: "1px solid #747579", color: "#747579", "& .MuiButtonBase-root .MuiButton-root:hover": {
                                                                border: "1ppx solid red !important"
                                                            }
                                                        }}>Edit</Button>
                                                    </Link>
                                                )}
                                                <Box sx={{
                                                    height: '40px',
                                                    bgcolor: '#F7C32E',
                                                    borderRadius: 2,
                                                    p: '10px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    verticalAlign: 'middle',
                                                    color: '#1b1b1b',
                                                    gap: 0.5
                                                }}>
                                                    <Icon.Star fontSize="small" />
                                                    {extractDataFromArray(review, ['rating'], '')}
                                                </Box>
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box sx={{ display: { xs: "flex", sm: "flex", md: "none" }, alignItems: "center", gap: 1, justifyContent: "flex-start" }}>
                                                {extractDataFromArray(review, ['guest', 'guest_seq'], '') === saveUserSeq && (
                                                    <Link to={ROUTE_SUBMIT_REVIEW + `?bookingId=${extractDataFromArray(review, ['booking_id'], '')}`}>
                                                        <Button startIcon={<Icon.EditOutlined sx={{ color: "#747579" }} />} variant="outlined" sx={{
                                                            paddingBlock: 1, paddingInline: 1.5, backgroundColor: '#FFFFFF', fontWeight: 400, lineHeight: 1.5, border: "1px solid #747579", color: "#747579", "& .MuiButtonBase-root .MuiButton-root:hover": {
                                                                border: "1px solid red !important"
                                                            }
                                                        }}>Edit</Button>
                                                    </Link>
                                                )}
                                                <Box sx={{
                                                    height: '40px',
                                                    bgcolor: '#F7C32E',
                                                    borderRadius: 2,
                                                    p: '10px',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    verticalAlign: 'middle',
                                                    color: '#1b1b1b',
                                                    gap: 0.5
                                                }}>
                                                    <Icon.Star fontSize="small" />
                                                    {extractDataFromArray(review, ['rating'], '')}
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Typography variant="body1" color="#747579" mb={2}>{extractDataFromArray(review, ['review'], '')}</Typography>
                                    <Box sx={{ display: "flex", flexDirection: "row", gap: 2, flexWrap: "wrap" }}>
                                        {Array.isArray(review.images) && review.images.length > 0 && (
                                            review.images.map((image, imgIndex) => (
                                                <Box
                                                    className="upload_image"
                                                    key={imgIndex}
                                                    onClick={() => handleOpen(review.images, imgIndex)}
                                                    sx={{
                                                        backgroundImage: `url(${image.url})`,
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundPosition: "center",
                                                        backgroundSize: "cover !important",
                                                        width: "100%",
                                                        aspectRatio: 1,
                                                        cursor: 'pointer'
                                                    }}
                                                />
                                            ))
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                            {extractDataFromArray(review, ['replyobj', 'text'], "") !== "" && (
                                <Box sx={{ ml: 1, display: "flex", flexDirection: "row", gap: 2, backgroundColor: "#F5F5F6", borderRadius: 4, p: 2 }}>
                                    <Avatar
                                        alt="Remy Sharp"
                                        src={extractDataFromArray(review, ['replyobj', 'profile_pic'], '')}
                                        sx={{ width: 42, height: 42 }}
                                    />
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                                            {extractDataFromArray(review, ['replyobj', 'fname'], '') + " " + extractDataFromArray(review, ['replyobj', 'lname'], '')}
                                        </Typography>
                                        <Typography variant="body1" sx={{ color: "#747579" }}>
                                            {extractDataFromArray(review, ['replyobj', 'text'], '')}
                                        </Typography>
                                    </Box>
                                </Box>
                            )}
                        </Box>
                    ))}
                    {open && Array.isArray(selectedImages) && (
                        <ImagePreview
                            open={open}
                            handleClose={handleClose}
                            images={selectedImages}
                            imageIndex={selectedImagesIndex}
                            setSelectedImageIndex={setSelectedImagesIndex}
                        />
                    )}
                </Box>
            )}
        </>
    );
};

export default ReviewRecievedCard;
