import { AppBar, Box, Button, Chip, Dialog, Divider, Grid, IconButton, MenuItem, Select, Skeleton, Toolbar, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material';
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router";
import PackageService from "../../services/package";
import { empty, extractDataFromArray, generatePhrase, getDateToString, getImageUrl, showZendeskLauncher } from "../../utils/common";
import ListingService from "../../services/listing";
import dayjs from "dayjs";
import CustomTooltip from "../../commonComponents/CustomTooltip";
import PaymentService from "../../services/payments";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import PackageCardSmall from "../../commonComponents/PackageCardSmall";
import { toast } from "react-toastify";
import CustomDateRangePicker from "../../projectComponents/CustomDateRangePicker";
import UserService from "../../services/user";
import AccountVerification from "../../commonComponents/AccountVerification";
import { CommonContext } from "../../store/context/commonContext";
import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import PackageCardMobile from "../../commonComponents/PackageCardMobile";
import LoaderButton from "../../commonComponents/LoaderButton";
import BookingCard from "../../commonComponents/previewPageComponents/BookingCard";
import theme from "../../theme";
import CloseIcon from '@mui/icons-material/Close';

const GuestAndDatesSelection = ({ guests, startDate, endDate, minDays, maxDays, notAvailableDates, onDateChange = () => { }, onGuestsChange = () => { }, minGuests, maxGuests, isPopup = false, }) => {
	const { isMobile } = CommonContext()
	startDate = dayjs(startDate);
	endDate = dayjs(endDate);


	const handleDateChange = (dateRange) => {
		onDateChange(dateRange);
	};

	const handleGuestChange = (e) => {
		const selectedValue = parseInt(e.target.value, 10);
		onGuestsChange(selectedValue);
	};

	return (
		<Box sx={{
			border: '1px solid var(--grey500)',
			borderRadius: (theme) => `${theme.card.borderRadius} `,
			mb: isMobile ? 2 : 3,
		}}>
			<Box sx={{ display: "flex", gap: 2, p: isMobile ? 2 : 3, alignItems: 'center' }}>
				<Icon.PersonOutlineOutlined />
				<Typography >
					Guests
				</Typography>
				<Box sx={{ marginLeft: "auto", }}>
					<Select
						displayEmpty
						value={guests}
						disabled={isPopup}
						IconComponent={Icon.KeyboardArrowDownOutlined}
						onChange={handleGuestChange}
						sx={{
							height: "42px",
						}}
					>
						{Array.from({ length: maxGuests - minGuests + 1 }, (_, index) => (
							<MenuItem
								key={index + parseInt(minGuests)}
								value={index + parseInt(minGuests)}
							>
								{index + parseInt(minGuests)}
							</MenuItem>
						))}
					</Select>
				</Box>
			</Box>
			<Divider />
			<Box sx={{ display: "flex", gap: 2, p: { xs: 2, sm: 3, md: 3, lg: 3 }, alignItems: 'center', justifyContent: 'space-between' }}>
				<Box sx={{ display: 'flex', gap: 3 }}>
					<Icon.CalendarTodayOutlined />
					<Typography >
						Days
					</Typography>
				</Box>
				<Box sx={{
					'& :hover': {
						cursor: 'pointer',
					},
				}}>
					<CustomDateRangePicker
						onChange={(date) => handleDateChange(date)}
						defaultDateRange={[startDate, endDate]}
						minDays={parseInt(minDays)}
						maxDays={parseInt(maxDays)}
						notAvailableDates={notAvailableDates}
						isPopup={isPopup}
					/>
				</Box>
			</Box>
		</Box>
	);
}



const IDVerificationPopup = ({ open, handleClose, handleProceed }) => (
	<Dialog open={open} onClose={handleClose} maxWidth="md">
		<Box >
			<Box sx={{ py: 1.5, px: 2.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
				<Typography variant="h6" sx={{ fontWeight: 700 }}>ID Verification Required</Typography>
				<IconButton onClick={handleClose}><Icon.Close /> </IconButton>
			</Box>
			<Divider />
			<Box sx={{ p: 2.5, width: '500px' }} >
				<Typography sx={{ mb: 2 }}>
					All users, hosts, and guests are required to undergo an ID verifcation process on FarWide to enhance safety and activate insurance coverage. Please submit a valid photo ID to proceed with your booking.
					<br /><br />
					It's a one-time requirement that only takes a few minutes.
				</Typography>
				<Button fullWidth variant="contained" color="primary" sx={{ mb: 2 }} onClick={() => { handleClose(); handleProceed() }}>Verify My ID</Button>
				<Typography variant="body2" sx={{ lineHeight: '16px', color: 'var(--grey900)' }}>
				</Typography>
			</Box>

		</Box>
	</Dialog>
)
const PricingCard = ({ price, guests, selectedDays, initPayment }) => {
	let total_package_price = price * (guests) * (selectedDays)
	let booking_fee = parseFloat((total_package_price * 0.05).toFixed(2));
	let conservation_donation = 0;
	const { isMobile, isTablet } = CommonContext()

	return (
		<Box sx={{ mb: isTablet && 12 }}>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, }}>
				<Typography variant="subtitle1" sx={{ fontWeight: "600" }}>
					Pricing Details
				</Typography>
				<CustomTooltip content={<>Total price is inclusive of FarWide fees </>} />
			</Box>
			<Typography variant="caption" sx={{ color: 'var(--grey900)', fontWeight: '0.875rem' }}>
				Prices Are for Exclusive Access to This Property.
			</Typography>
			<Box sx={{
				display: "flex",
				justifyContent: "space-between",
				mt: isMobile ? 2 : 3,
				color: 'var(--grey900)'
			}}>
				<Box>
					{`$${price} x ${generatePhrase(guests, 'Guest')} x ${generatePhrase(selectedDays, 'Day')}`}
				</Box>

				<Box>
					${parseFloat(total_package_price).toFixed(2)}
				</Box>
			</Box>
			<Box sx={{ display: "flex", justifyContent: "space-between", color: 'var(--grey900)' }}>
				<Box>Platform Fee (5%)</Box>
				<Box>+${parseFloat(booking_fee).toFixed(2)}</Box>
			</Box>
			<Divider sx={{ mt: 2, mb: 2 }} />
			<Box sx={{
				display: "flex",
				fontWeight: "600",
				justifyContent: "space-between",
			}}>
				<Box>Total</Box>
				<Box>
						${parseFloat((price * guests * selectedDays)
						+ conservation_donation + booking_fee).toFixed(2)}
				</Box>
			</Box>
		</Box>
	)
}

const PayPopup = ({ open, handleClose, listingData, packageData, guests, startDate, endDate, selectedDays, maxDays, minDays, minGuests, maxGuests, handleGuestsChange, handleDateChange, setLoaderButton }) => {
	// console.log(startDate, endDate)
	const stripe = useStripe();
	const elements = useElements();
	const [bookingLoader, setBookingLoader] = useState(false);
	const { isMobile, isNineHundred } = CommonContext();
	// const handleSubmit = async (event) => {
	// 	// We don't want to let default form submission happen here,
	// 	// which would refresh the page.
	// 	event.preventDefault();
	// 	// setLoaderButton(false)
	// 	if (!stripe || !elements) {
	// 		// Stripe.js hasn't yet loaded.
	// 		// Make sure to disable form submission until Stripe.js has loaded.
	// 		return;
	// 	}
	// 	setBookingLoader(true);

	// 	const result = await stripe.confirmPayment({
	// 		//`Elements` instance that was used to create the Payment Element
	// 		elements,
	// 		confirmParams: {
	// 			return_url: window.location.origin + "/payment",
	// 		},
	// 	});
	// 	// setLoaderButton(false)

	// 	if (result.error) {
	// 		window.location.href = "/payment?payment_intent=" + result.error.payment_intent.id + "&redirect_status=failed&em=" + result.error.message;
	// 		// setLoaderButton(false)
	// 	} else {
	// 		// setLoaderButton(false)
	// 		// Your customer will be redirected to your `return_url`. For some payment
	// 		// methods like iDEAL, your customer will be redirected to an intermediate
	// 		// site first to authorize the payment, then redirected to the `return_url`.
	// 	}
	// };

	const handleSubmit = async (event) => {
		// Check if event is defined before attempting to access its properties
		if (event) {
			event.preventDefault();
		}

		if (!stripe || !elements) {
			setBookingLoader(false);
			return;
		}

		setBookingLoader(true);
		const result = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: window.location.origin + "/payment",
			},
		});

		if (result.error) {
			setBookingLoader(false);
			window.location.href = "/payment?payment_intent=" + result.error.payment_intent.id + "&redirect_status=failed&em=" + result.error.message;
		} else {
			setBookingLoader(false);
			// Handle successful payment
		}

		// Reset loader state after payment attempt
		setBookingLoader(false);
	};
	console.log(packageData)


	const [listerSeq, setListerSeq] = useState('')
	const [paymentDetails, setPaymentDetails] = useState({})

	useEffect(()=> {

		setListerSeq(extractDataFromArray(listingData,['lister_seq'],''))

	},[listingData])

	useEffect(()=> {
	
		PackageService.getCancellationPolicyData('lister',listerSeq, (data) => {
			setPaymentDetails(data)
			// setCancelReasons(data.cancelationResaons)
		})
	},[listerSeq])


	return (
		<Dialog open={open} fullScreen={isNineHundred} onClose={handleClose} sx={{
			borderRadius: (theme) => `${theme.card.borderRadius} `,
			'& .MuiDialog-paper': isNineHundred && {
				borderRadius: 0,
				padding: 0,
			},
			'& .MuiDialog-paper': { maxWidth: '1000px' }
		}}
			PaperProps={{ style: { borderRadius: (theme) => `${theme.card.borderRadius} ` } }}
			fullWidth

		>
			<Box sx={{ mb: 8, borderRadius: (theme) => `${theme.card.borderRadius} ` }}>
				{!isNineHundred ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Typography variant="h5" sx={{ p: 2, fontWeight: 700 }}>Confirm and Pay</Typography>
					<IconButton onClick={handleClose} sx={{ mr: 2 }}>
						<CloseIcon />
					</IconButton>
				</Box> :
					<AppBar sx={{ position: 'relative', backgroundColor: 'white', boxShadow: 'none', height: '56px' }}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={handleClose}
								aria-label="close"
							>
								<ArrowBackOutlined color="black" />
							</IconButton>
							<Typography sx={{ m: 'auto' }} variant="h6" component="div">
								Confirm and Pay
							</Typography>
						</Toolbar>
						<Divider />
					</AppBar>}
				{!isNineHundred && <Divider />}
				<Grid container spacing={2} sx={{ p: 2 }}>
					<Grid item xs={12} sm={12} md={6}>
						<form onSubmit={handleSubmit}>
							<PaymentElement options={{ terms: { card: 'never' } }} />
							{!isNineHundred && <Divider sx={{ my: 3 }} />}
							<Box sx={{ p: 2, my: isNineHundred ? 2 : 0, backgroundColor: 'var(--grey300)', borderRadius: (theme) => `${theme.card.borderRadius} ` }}>
								<Typography variant="body2">Cancellation Policy:</Typography>
								<Typography variant="body2" sx={{ color: "var(--grey900)" }}>
								{extractDataFromArray(paymentDetails, ['messageToguest'], '')}</Typography>
							</Box>
							<Box
								sx={isNineHundred && {
									width: '100%',
									ml: -2,
									height: '82px',
									position: 'fixed',
									bottom: 0,
									backgroundColor: 'white',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									flexDirection: 'column',
									boxShadow: '0 -2px 4px 0 rgba(0,0,0,0.15)',
									zIndex: '9999',
									pl: 2,
									pr: 2
								}}
							>
								{/* <Button onClick={handleSubmit} fullWidth variant="contained" sx={{ mt: isNineHundred ? 2 : 3 }}>
									Send Booking Request
								</Button> */}
								<LoaderButton
									variantType='contained'
									size="large"
									onClick={() => { handleSubmit() }}
									isLoading={bookingLoader}
									buttonText="Send Booking Request"
									isFullWidth={true}
									mt={isNineHundred ? 2 : 3}
									sx={{ borderRadius: 2 }}
								/>
							</Box>
						</form>
					</Grid>
					<Grid item xs={12} sm={12} md={6} >
						<Box sx={!isNineHundred && { border: '1px solid var(--grey500)', p: 3, borderRadius: (theme) => `${theme.card.borderRadius} ` }}>
							{!isNineHundred && <PackageCardSmall
								image={extractDataFromArray(packageData, ['package_json', 'media_library', '0'])}
								title={extractDataFromArray(packageData, ['title'])}
								rating={extractDataFromArray(packageData, ['average_rating'], 0)}
								area={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], '')}
								address={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_address'], '')}
								nearest_town={extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '')}
								review={extractDataFromArray(packageData, ['review_count'], 0)}
								propertyName={extractDataFromArray(listingData,['listing_json','property_details','name'],'')}
								listingSlug={extractDataFromArray(listingData,['listing_json','property_details','slug'],'')}
								listing_seq= {extractDataFromArray(listingData,['listing_seq'],'')}
								activity_type={extractDataFromArray(packageData,['package_json','activity_type'],'')}
								borderRadius={theme.card.borderRadiusMd}
							/>}
							{/* <GuestAndDatesSelection
								guests={guests}
								startDate={startDate}
								endDate={endDate}
								minDays={minDays}
								maxDays={maxDays}
								minGuests={minGuests}
								maxGuests={maxGuests}
								onDateChange={handleDateChange}
								onGuestsChange={handleGuestsChange}
								isPopup={true}
							/> */}
							<Box sx={{
								border: '1px solid var(--grey500)',
								borderRadius: (theme) => `${theme.card.borderRadiusMd} `,
								mb: 2,
							}}>
								<Box sx={{
									display: 'flex',
									height: '4rem',
									pl: 2,
									alignItems: 'center'
								}}>
									<Icon.PersonOutlineOutlined sx={{ mr: 1, color: 'var(--grey900)' }} />
									<Typography variant="body1" fontWeight={'600'}>{generatePhrase(guests, 'Guest')}</Typography>
								</Box>
								<Divider sx={{ borderColor: 'var(--grey500)' }} />
								<Box sx={{
									display: 'flex',
									height: '4rem',
									pl: 2,
									alignItems: 'center'
								}}>
									<Icon.CalendarTodayOutlined sx={{ mr: 1, color: 'var(--grey900)' }} />
									<Typography variant="body1" fontWeight={'600'}>{generatePhrase(selectedDays, 'Day')} {dayjs(startDate).format('DD MMM') == dayjs(endDate).format('DD MMM') ? `(${dayjs(endDate).format('DD MMM')})` : `(${dayjs(startDate).format('DD')}-${dayjs(endDate).format('DD MMM')})`}</Typography>
								</Box>
							</Box>
							<Box>
								<PricingCard
									listerSeq={extractDataFromArray(listingData, ['lister_seq'])}
									price={extractDataFromArray(packageData, ['package_json', 'price_per_guest'], 1)}
									selectedDays={selectedDays}
									guests={guests}
									listingData={listingData}
									packageData={packageData}
								/>
							</Box>
						</Box>
					</Grid>
				</Grid>

			</Box>
		</Dialog>
	)
}
const Checkout = () => {
	const [loading, setLoading] = useState(true);
	const [loaderButton, setLoaderButton] = useState(false);
	const [packageData, setPackageData] = useState({});
	const [listingData, setListingData] = useState({});

	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const packageSeq = queryParams.get("p");
	const totalguests = queryParams.get("g");
	// const startDate = queryParams.get("sd");
	// const endDate = queryParams.get("ed");
	const [startDate, setStartDate] = useState(dayjs(queryParams.get("sd")));
	const [endDate, setEndDate] = useState(dayjs(queryParams.get("ed")));
	const totalSelectedDays = dayjs(endDate).diff(startDate, 'days') + 1
	const { isMobile, isTablet } = CommonContext()
	useEffect(() => {
		showZendeskLauncher(!isTablet)
	}, [isTablet])

	const [guests, setGuests] = useState(totalguests)

	const [selectedDays, setSelectedDays] = useState(parseInt(totalSelectedDays))
	const [notAvailableDates, setNotAvailableDates] = useState([]);


	const handleDateChange = (dateRange) => {
		// Calculate the difference in days between start and end dates
		const days = dayjs(dateRange[1]).diff(dayjs(dateRange[0]), 'day') + 1;
		// console.log(days)
		// Check if days is NaN and set it to 0 in that case
		setSelectedDays(isNaN(days) ? 0 : days);
		setStartDate(dateRange[0])
		setEndDate(dateRange[1])

		localStorage.setItem(`${packageSeq}-bookingSelection`, JSON.stringify({
			guest: guests,
			startDate: getDateToString(dayjs(dateRange[0])),
			endDate: getDateToString(dayjs(dateRange[1]))
		}));
	};

	const handleGuestsChange = (guests) => {
		// console.log('setting guest count',guests)
		setGuests(guests)

		let storedSelection = JSON.parse(localStorage.getItem(`${packageSeq}-bookingSelection`));
		storedSelection.guest = guests;
		localStorage.setItem(`${packageSeq}-bookingSelection`, JSON.stringify(storedSelection));
	}

	const handleNotAvailabeDates = (data) => {
		// setNotAvailableDatesStringArray(data)
		if (Array.isArray(data) && data && data.length > 0) {
			let notAvlDatesDayJS = data.map(date => dayjs(date))
			setNotAvailableDates(notAvlDatesDayJS)
		}
	}



	const pricePerGuest = extractDataFromArray(packageData, ['package_json', 'price_per_guest'], 0)
	useEffect(() => {
		PackageService.getPackageData(packageSeq, (data) => {
			setPackageData(data)
			ListingService.getListingData(extractDataFromArray(data, ['listing_seq'], 0), (listing_data) => {
				setListingData(listing_data)
				setLoading(false)
			})
			ListingService.getListingBookedDates(extractDataFromArray(data, ['listing_seq'], 0), (data) => {
				// console.log(data)
				handleNotAvailabeDates(data)
			});
		})
	}, [packageSeq])

	const minDays = extractDataFromArray(packageData, ['package_json', 'min_days'], 0)
	const maxDays = extractDataFromArray(packageData, ['package_json', 'max_days'], 0)
	const minGuests = extractDataFromArray(packageData, ['package_json', 'min_guests'], 0)
	const maxGuests = extractDataFromArray(packageData, ['package_json', 'max_guests'], 0)

	// console.log(minGuests, 'minguests', maxGuests, 'maxGuests', minDays, 'mindays', maxDays, 'maxDays')
	let total_package_price = extractDataFromArray(packageData, ['package_json', 'price_per_guest'], 1) * (guests) * (selectedDays)
	let booking_fee = parseFloat((total_package_price * 0.05).toFixed(2));
	let conservation_donation = 0;

	const [open, setOpen] = useState(false);

	const [options, setOptions] = useState({})
	const [accountActive, setAccountActive] = useState(false)
	const [openIdPopup, setOpenIdPopup] = useState(false);
	const [verificationToken, setVerificationToken] = useState(false);
	// console.log(verificationToken)

	const image = extractDataFromArray(packageData, ['package_media', '0', 'url'])


	const [isReviewPopup, setIsReviewPopup] = useState(true)

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		window.history.back();
	};

	const handleVerifyIdentity = () => {
		setOpenIdPopup(false)
		verifyIdentity(verificationToken)
	}
	const verifyIdentity = async (clientSecret) => {
		const { error } = await stripe.verifyIdentity(clientSecret);

		if (error) {

		} else {
			PaymentService.updateUserStatus(extractDataFromArray(listingData, ['lister_seq']), () => {
				// createOrder()
				toast.success("Your verifcation is submitted successfully. Awaiting approval.", { theme: 'colored' })
			})
		}
	}

	const createOrder = () => {
		let checkingInDate = dayjs(startDate).format('YYYY-MM-DD');
		let checkingOutDate = dayjs(endDate).format('YYYY-MM-DD');

		// alert(checkingInDate,checkingOutDate)


		PaymentService.createOrder(extractDataFromArray(listingData, ['lister_seq']), extractDataFromArray(listingData, ['listing_seq']), guests, checkingInDate, checkingOutDate, (extractDataFromArray(packageData, ['package_json', 'price_per_guest'], 1) * guests * selectedDays)
			, booking_fee, conservation_donation, 'new', packageSeq, setLoaderButton,extractDataFromArray(listingData, ['listing_version']),extractDataFromArray(packageData, ['package_version']), (data) => {
				if (empty(data)) {
					toast.error("Some error occured!", {
						theme: 'colored'
					})
					return
				}
				setOptions({ ...options, clientSecret: data.client_secret })
				setOpen(true)
				setLoaderButton(false)
			})
	}


	const initPayment = () => {
		setLoaderButton(true)
		UserService.getAccountCompletionStatus((data) => {
			PaymentService.isUserVerified(extractDataFromArray(listingData, ['lister_seq']), (data) => {
				setVerificationToken(data.v_token)
			})

			if (data.id_verified === 'I' || data.email_verified === false) {
				setVerificationToken(verificationToken)
				setOpenIdPopup(true)
			} else if (data.id_verified === 'V') {
				createOrder()

			} else if (data.id_verified === 'P' || data.id_verified === '1') {
				toast.error("You cannot proceed to booking because ID Verification is still pending.", {
					theme: 'colored'
				})

				return
			} else {
				toast.error("Something went wrong. Please try after sometime", {
					theme: 'colored'
				})
				setLoaderButton(true)
				return
			}
		})
	}

	const checkListerAccountStatus = () => {
		if (accountActive) {
			setOpen(true)
			setLoaderButton(false);
		} else {
			UserService.getAccountCompletionStatus((data) => {
				let statusCompleted = 0;
				Object.keys(data).forEach(key => {
					if (key === 'id_verified' && data[key] !== "I") {
						statusCompleted++;
					} else if (data[key]) {
						statusCompleted++;
					}
				})
				if (Object.keys(data).length === statusCompleted) {
					setOpen(true)
					setLoaderButton(false);
					setAccountActive(true)
				} else {
					setOpenIdPopup(true)
				}
			})
		}
	}


	const [stripe, setStripe] = useState(null)
	const [platFormStripe, setPlatFormStripe] = useState(null)
	useEffect(() => {
		if (!empty(listingData)) {
			PaymentService.getListerInfo(extractDataFromArray(listingData, ['lister_seq'], 0), async (data) => {
				let load = await loadStripe(extractDataFromArray(data, ['p_key']))
				setStripe(load)
				let pload = await loadStripe(extractDataFromArray(data, ['fw_key']), {
					stripeAccount: extractDataFromArray(data, ['account_id']),
				})
				setPlatFormStripe(pload)
			})
		}
	}, [listingData])

	return (
		<>
			<Box>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, textAlign: 'center' }}>
					<IconButton onClick={() => { window.history.back() }}><Icon.West /></IconButton>
					<Typography variant='h3' >Review your Booking </Typography>
				</Box>
				<Divider sx={isMobile ? { mt: 2, mb: 2 } : { mt: 3, mb: 3 }} />

				{
					loading && <Box sx={{ mx: 'auto' }} maxWidth={660}>
						<Skeleton sx={{ pb: 10 }} />
						<Skeleton sx={{ pb: 10 }} />
						<Skeleton sx={{ pb: 10 }} />
						<Skeleton sx={{ pb: 10 }} />
						<Skeleton sx={{ pb: 10 }} />
					</Box>
				}

				{
					!loading && <Box sx={{ mx: 'auto' }} maxWidth={660}>
						{!isMobile ? <PackageCardSmall
							image={extractDataFromArray(packageData, ['package_json', 'media_library', 0])}
							title={extractDataFromArray(packageData, ['title'])}
							rating={extractDataFromArray(packageData, ['average_rating'], 0)}
							area={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], '')}
							address={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_address'], '')}
							nearest_town={extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '')}
							propertyName={extractDataFromArray(listingData,['listing_json','property_details','name'],'')}
							listingSlug={extractDataFromArray(listingData,['listing_json','property_details','slug'],'')}
							listing_seq= {extractDataFromArray(listingData,['listing_seq'],'')}
							activity_type={extractDataFromArray(packageData,['package_json','activity_type'],'')}
							review={extractDataFromArray(packageData, ['review_count'], 0)}
						/>
							:
							// Mobile view package small card design
							<Box sx={{ mb: 2 }}>
								<PackageCardMobile
									image={extractDataFromArray(packageData, ['package_media', '0'])}
									type={extractDataFromArray(packageData, ['package_media', '0','type'])}
									thumbnail={extractDataFromArray(packageData, ['package_media', '0','thumbnail_url'])}
									title={extractDataFromArray(packageData, ['title'], '')}
									area={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], '')}
									activity_type={extractDataFromArray(packageData, ['package_json', 'activity_type'], '')}
									propertyName={extractDataFromArray(listingData, ['listing_json', 'property_details', 'name'], '')}
									listingSlug={extractDataFromArray(listingData, ['listing_json', 'property_details', 'slug'], '')}
									listing_seq={extractDataFromArray(listingData, ['listing_seq'], '')}
									address={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_address'], '')}
									nearest_town={extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '')}
									showBorder={false}

								/>
							</Box>
						}
						<GuestAndDatesSelection
							guests={guests}
							startDate={startDate}
							endDate={endDate}
							minDays={minDays}
							maxDays={maxDays}
							minGuests={minGuests}
							maxGuests={maxGuests}
							onDateChange={handleDateChange}
							onGuestsChange={handleGuestsChange}
							notAvailableDates={notAvailableDates}
						/>
						<PricingCard
							listerSeq={extractDataFromArray(listingData, ['lister_seq'])}
							price={extractDataFromArray(packageData, ['package_json', 'price_per_guest'], 1)}
							selectedDays={selectedDays}
							guests={guests}
							stripe={stripe}
							platFormStripe={platFormStripe}
							listingData={listingData}
							packageData={packageData}
							initPayment={initPayment}
						/>
						{/* <BookingCard
								packageSeq={packageSeq}
								guests={guests}
								defaultDateRange={[startDate,endDate]}
								minDays={minDays}
								maxDays={maxDays}
								minGuests={minGuests}
								maxGuests={maxGuests}
								handleGuestChange={handleGuestsChange}
								handleDateChange={handleDateChange}
								price={pricePerGuest}
								notAvailableDates={notAvailableDates}
							/> */}
						<Box
							sx={isTablet && {
								width: '100%',
								height: '82px',
								position: 'fixed',
								ml: -2,
								bottom: 0,
								backgroundColor: 'white',
								display: 'flex',
								alignItems: 'center', // Vertically align the items
								justifyContent: 'space-between',
								flexDirection: 'column',
								boxShadow: '0 -2px 4px 0 rgba(0,0,0,0.15)',
								zIndex: '999',
								px: 2
							}}
						>
							{/* <Button
								fullWidth
								size="large"
								variant="contained"
								sx={!isTablet ? { mt: 4 } : { my: 'auto' }}
								onClick={initPayment}
							>
								Confirm and Pay
							</Button> */}
							<LoaderButton
								variantType='contained'
								size="large"
								onClick={() => { initPayment() }}
								isLoading={loaderButton}
								buttonText="Confirm and Pay"
								isFullWidth={true}
								// sx={!isTablet ? { mt: 4 } : { my: 'auto' }}
								mt={!isTablet ? 4 : 'auto'}
								mb={!isTablet ? 0 : 'auto'}
							/>
						</Box>


						{
							!empty(options) &&
							<Elements stripe={platFormStripe} options={options}>
								<PayPopup open={open} handleClose={() => { setOpen(false); }} listingData={listingData}
									packageData={packageData}
									guests={guests}
									startDate={startDate}
									selectedDays={selectedDays}
									endDate={endDate}
									maxDays={maxDays}
									minGuests={minGuests}
									maxGuests={maxGuests}
									onDateChange={handleDateChange}
									onGuestsChange={handleGuestsChange}
									setLoaderButton={setLoaderButton}
								/>

							</Elements>
						}
					</Box>
				}
				{/* <IDVerificationPopup open={openIdPopup} handleClose={() => { setOpenIdPopup(false) }} handleProceed={handleVerifyIdentity} /> */}
				<Dialog open={openIdPopup} maxWidth='lg' fullWidth onClose={() => { setOpenIdPopup(false); setLoaderButton(false); }} sx={{
					'& .MuiPaper-root': {
						maxWidth: '1167px'
					}
				}} >
					<Box>
						<Box sx={{ px: isMobile ? 2 : 3, display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
							<Typography variant="h6" sx={{ fontWeight: 700 }}>One step away</Typography>
							<IconButton sx={{ p: isMobile ? 2 : 3 }} onClick={() => { setOpenIdPopup(false); setLoaderButton(false) }}><Icon.Close /></IconButton>
						</Box>
						<Divider />
						<Box sx={{ p: isMobile ? 2 : 3 }}>
							<AccountVerification
								completedCallback={() => { setAccountActive(true); setOpenIdPopup(false); setLoaderButton(false); checkListerAccountStatus(); initPayment(); }}
								skipIDVerification={true}
								sx={{ p: isMobile ? 2 : 3 }}
								subtitle={
									<Box>
										<>
											<Typography sx={{
												color: 'var(--grey900)',
												mb: 2
											}}>Please complete the following two steps in any order and you will be ready to explore and book properties instantly-</Typography>
											<Typography sx={{
												display: 'flex',
												color: 'var(--grey900)',
												mb: 1
											}}>
												<Chip label="1" variant="outlined"
													size='small'
													sx={{
														color: 'var(--grey900)',
														height: '24px',
														width: '24px',
														bgcolor: 'white',
													}} /><Box sx={{ pl: 1 }}>Verify Your Email Address</Box>
											</Typography>
											<Typography sx={{
												display: 'flex',
												color: 'var(--grey900)',
												mb: 1
											}}>
												<Chip label="2" variant="outlined"
													size='small'
													sx={{
														color: 'var(--grey900)',
														height: '24px',
														width: '24px',
														bgcolor: 'white',
														pr: 1
													}} /><Box sx={{ pl: 1 }}>Provide a government-issued ID for verification</Box>
											</Typography>
											<Typography sx={{
												color: 'var(--grey900)',
												mb: 2
											}}>
												{/* <Chip label="3" variant="outlined"
										size='small'
										sx={{
											color: 'var(--grey900)',
											height: '24px',
											width: '24px',
											bgcolor: 'white',
											pr: 1
										}} /> Set up your Stripe Connect account.<br /> */}
											</Typography>
											Please Click the buttons below to start:
										</>
									</Box>
								}
							/>
						</Box>

					</Box>
				</Dialog>
			</Box>
		</>
	)
}

export default Checkout;