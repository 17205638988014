import * as React from "react";
import { useState } from "react";
import { Divider, IconButton, InputAdornment, InputLabel } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { Box, Button, Grid, TextField, Typography, FormGroup, Select, MenuItem } from "@mui/material";
import { useNavigate } from "react-router";
import "../style.css";
import LinkHref from '@mui/material/Link'
import { XAxis } from "recharts";
import { CommonContext } from "../../../store/context/commonContext";
import { Link } from "react-router-dom";
import SearchService from "../../../services/search";

const Titlesection = (props) => {
	let navigate = useNavigate()
	const { isTablet } = CommonContext()
	const buttonData = [
		{
			text: "All",
			index: 0,
			image: "../assets/images/icon_images/all_icon.svg",
			type: ''
		},
		{
			text: "Hunting",
			index: 1,
			image: "../assets/images/icon_images/hunting.svg",
			type: 'H'
		},
		{
			text: "Fishing",
			index: 2,
			image: "../assets/images/icon_images/fishing.svg",
			type: 'F'
		},
		{
			text: "Bird Watching",
			index: 3,
			image: "../assets/images/icon_images/birdwatch.svg",
			type: 'BW'
		},
		{
			text: "RV & Camping",
			index: 4,
			image: "../assets/images/icon_images/rv_camping.svg",
			type: 'RV'
		},
		{
			text: "Horseback Riding",
			index: 5,
			image: "../assets/images/icon_images/horse_riding.svg",
			type: 'HR'
		},

	];
	const buttonsx = {
		display: "flex",
		alignItems: "center",

		borderRadius: "8px",
		minWidth: "auto !important",
		border: "none",
	};

	const [activityOptions, setActivityOptions] = useState({})

	const handleChange = (e) => {
		setSearchFilters({ 'activity': e.target.value });
	};


	const [activeButtonIndex, setActiveButtonIndex] = useState(0);
	const [kw, setKw] = useState('');

	React.useEffect(() => {
		SearchService.getActivityType((data) => {
			setActivityOptions(data)
		})
	}, [])


	const { searchFilters, setSearchFilters } = CommonContext()
	return (
		<>
			<Grid container sx={{ p: { xs: 0, md: 0 } }}>
				<Grid item xs={12} sm={12} md={12} sx={{ m: "auto", mt: { xs: 16, md: 20 } }} >
					<Box sx={{ textAlign: "center" }}>
						<Typography variant="h3" sx={{ mb: 2 }}>Recreational Access…<span style={{ color: "var(--primary)" }}>Unlocked</span></Typography>
						<Typography variant="h6" sx={{ color: 'grey', fontWeight: 'normal', mb: 4, fontSize: { xs: "0.8rem", md: '1.25rem' } }}>
							Discover and book <span style={{
								fontWeight: 'bold',
								color: 'var(--black)'
							}}>private land</span>,  water or access for<br />
							your next outdoor adventure.
						</Typography>
					</Box>

					<Box sx={{ margin: 'auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
						<Box sx={{ mx: 2, width: '100%' }}>
							<FormGroup sx={{ alignItems: 'center', flexWrap: 'nowrap', flexDirection: 'row', justifyContent: 'center', border: 'solid 1px #C5C5C7', background: '#fff', borderRadius: '16px', maxWidth: '680px', m: 'auto', }}>
								<Select
									name="listing_sort"
									size="large"
									value={searchFilters.activity}
									onChange={handleChange}
									IconComponent={Icon.KeyboardArrowDownOutlined}
									sx={{ border: 'none', "& fieldset": { border: 'none' }, m: 0, pt: 0, width: "19ch", mb: 0, mt: "0px", display: { xs: 'none', sm: 'none', md: 'block' } }}
								>
									<MenuItem value={"A"} selected>All Activities</MenuItem>
									{Object.keys(activityOptions).map((key) => (
										<MenuItem key={key} value={key}>{activityOptions[key]}</MenuItem>
									))}
								</Select>
								<Divider orientation="vertical" variant="middle" flexItem sx={{ mt: 0, mb: 0, display: { xs: 'none', sm: 'none', md: 'block' } }} />
								<TextField
									className='top_hdr_search_input'
									placeholder={isTablet ? 'Search..' : 'Where would you like to go?'}
									variant="outlined"
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton variant="contained" style={{ background: "var(--primary)", color: "#fff" }} onClick={() => {
													// navigate("/search?kw=" + searchFilters.kw + "&at=" + (searchFilters.activity === "A" ? '' : searchFilters.activity))
													window.location.href = window.location.origin + "/search?kw=" + searchFilters.kw + "&at=" + (searchFilters.activity === "A" ? '' : searchFilters.activity)
												}}>
													<Icon.SearchOutlined />
												</IconButton>
											</InputAdornment>
										),
									}}
									sx={{ border: 'none', "& fieldset": { border: 'none' }, m: 0, pr: '8px', width: { xs: '100%', md: '520px', }, "& .MuiInputBase-root": { paddingRight: '0px' } }}
									size='large'
									value={searchFilters.kw}
									onInput={(e) => { setSearchFilters({ 'kw': e.target.value }) }}
									onKeyDown={(e) => {
										if (e.key === 'Enter') {
											if (window.location.pathname !== "/search") {
												// navigate("/search?kw=" + searchFilters.kw + "&at=" + (searchFilters.activity === "A" ? '' : searchFilters.activity))
												window.location.href = window.location.origin + "/search?kw=" + searchFilters.kw + "&at=" + (searchFilters.activity === "A" ? '' : searchFilters.activity)
											}
										}
									}}

								>
								</TextField>
							</FormGroup>
							<Box sx={{ maxWidth: '680px', textAlign: 'right', margin: 'auto', mb: 5 }}>
								<Link to="/search?at=" style={{ color: 'var(--black)', fontSize: '12px', borderBottom: 'solid 1px var(--black)', textDecoration: 'none' }}>Explore All</Link>
							</Box>
						</Box>

					</Box>
					<Box sx={{ overflow: 'auto', display: 'flex', pl: 2 }}>
						<Box sx={{ margin: 'auto', display: 'flex', gap: 2, justifyContent: { xs: 'initial', md: 'center' }, mb: 1 }}>
							<Box className="search_img_link" sx={{ textAlign: 'center' }}>
								<Link to="/search?at=F" onClick={() => { setSearchFilters({ 'activity': 'F'}) }}>
									<Box className="search_img">
										<img src="/assets/images/homepage/fishing_search_img.png" alt="fishing" style={{ maxWidth: '100%' }} />
									</Box>
									<Typography>Fishing</Typography>
								</Link>
							</Box>

							<Box className="search_img_link" sx={{ textAlign: 'center' }}>
								<Link to="/search?at=BW" onClick={() => { setSearchFilters({ 'activity': 'BW'}) }}>
									<Box className="search_img">
										<img src="/assets/images/homepage/birdwatching_search_img.png" alt="bird watching" style={{ maxWidth: '100%' }} />
									</Box>
									<Typography>Bird Watching</Typography>
								</Link>
							</Box>

							<Box className="search_img_link" sx={{ textAlign: 'center' }}>
								<Link to="/search?at=FR" onClick={() => { setSearchFilters({ 'activity': 'FR'}) }}>
									<Box className="search_img">
										<img src="/assets/images/homepage/rv_search_img.png" alt="RV & Camping" style={{ maxWidth: '100%' }} />
									</Box>
									<Typography>Farm and Ranch</Typography>
								</Link>
							</Box>

							<Box className="search_img_link" sx={{ textAlign: 'center' }}>
								<Link to="/search?at=H" onClick={() => { setSearchFilters({ 'activity': 'H'}) }}>
									<Box className="search_img">
										<img src="/assets/images/homepage/hunting_search_img.png" alt="hunting" style={{ maxWidth: '100%' }} />
									</Box>
									<Typography>Hunting</Typography>
								</Link>
							</Box>

							{/* <Box className="search_img_link" sx={{ textAlign: 'center' }}>
								<Link to="/search" onClick={() => { setSearchFilters({ 'activity': 'A'}) }}>
									<Box className="search_img">
										<img src="/assets/images/homepage/horse_search_img.png" alt="horseback riding" style={{ maxWidth: '100%' }} />
									</Box>
									<Typography>Horseback Riding</Typography>
								</Link>
							</Box> */}
						</Box>
					</Box>


				</Grid>
			</Grid>

		</>
	);
};

export default Titlesection;
