import { useEffect, useState } from "react";
import { Box, Button, Chip, FormControl, InputAdornment, MenuItem, Select, Skeleton, TextField, Typography, } from "@mui/material";
import { Link } from "react-router-dom";
import TransactionService from "../../../services/transaction";
import * as Icon from "@mui/icons-material";
import TableNoData from "../../../commonComponents/placeholders/TableNoData";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import Pagination from "../../../commonComponents/tableListingComponents/Pagination";
import { empty, extractDataFromArray } from "../../../utils/common";
import moment from "moment";
import { CommonContext } from "../../../store/context/commonContext";
import ListingService from "../../../services/listing";
import config from "../../../config";

const Transactiontable = () => {
	const [transaction, setTransaction] = useState([]);
	const [page, setPage] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [searchQuery, setSearchQuery] = useState("");
	const [filterConfig, setFilterConfig] = useState({ search: "", sort: "R", });
	const [totalRecords, setTotalRecords] = useState(0);
	const [loading, setLoading] = useState(true)
	const [loadingSkele, setLoadingSkele] = useState(true)
	const [toggle, setToggle] = useState(false)
	const { isMobile } = CommonContext();
	const [totalListingCount, setTotalListingCount] = useState(null)

	const getDashboardData = () => {
		ListingService.getDashboardData((data) => {
			setTotalListingCount(parseInt(parseInt(extractDataFromArray(data, ['published_listing_count'], 0))))
		})
	}

	useEffect(() => {


		TransactionService.getAllTransactions(searchQuery, filterConfig.sort, (page - 1) * rowsPerPage, rowsPerPage,
			(data) => {
				setTransaction(extractDataFromArray(data, ['records'], []))
				setTotalRecords(extractDataFromArray(data, ['count'], []))
				setLoading(false)

				if (config.module === 'lister') {
					if (empty(extractDataFromArray(data, ['records'], []))) {
						getDashboardData()
					}
				}
			})
	}, [searchQuery, filterConfig.sort, page, rowsPerPage])


	useEffect(() => {
		// Reset parameters when the filter changes
		setPage(1); // Reset page to 1
		setSearchQuery(""); // Reset searchQuery to an empty string
		setFilterConfig((prevFilterConfig) => ({ ...prevFilterConfig, sort: "R" })); // Reset sort to "R"
	}, []);

	const handleSearch = (event) => {

		// if(event.target.value === '') {
		// 	setToggle(false)
		// } else{
		setToggle(true)
		const searchText = event.target.value;
		setSearchQuery(searchText); // Update the searchQuery state with the input value
		// }

	};
	const columns = [

		{
			field: "transaction_seq",
			resizable: false,
			headerName: (
				<Box style={{ marginLeft: "16px" }}>ID</Box>
			),
			flex: 1,
			minWidth: 100,
			fontWeight: '600',
			marginLeft: '16px',
			renderCell: (params) => (
				<Box style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>

					{params.value}
				</Box>
			),

		},

		{
			field: "transaction_date",
			minWidth: 180,
			resizable: false,
			headerName: "Date",
			flex: 1,
			renderCell: (params) => {
				const parsedDate = moment.utc(params.value, "YYYY-MM-DD HH:mm:ss").local();

				const formattedInvoiceDate = parsedDate.format("DD MMM YYYY h:mmA");


				if (parsedDate) {
					return (
						<Box style={{ display: "flex", alignItems: "center" }}>
							{formattedInvoiceDate}
						</Box>
					);
				} else {
					return (
						<Box style={{ display: "flex", alignItems: "center" }}>
							--/--/----
						</Box>
					);
				}
			},
		},
		{ field: "package_name", headerName: "Package Name", flex: 1, minWidth: 300, resizable: false, },
		{ field: "billing_name", headerName: "Billing Name", flex: 1.5, minWidth: 100, resizable: false, },
		{
			field: "total", headerName: "Amount", flex: 1, minWidth: 100, resizable: false,
			renderCell: (params) => (

				<Box style={{ display: "flex", alignItems: "center", marginLeft: "16px" }}>
					${parseFloat(params.value).toFixed(2)}
				</Box>
			),

		},


		{
			field: "payment_status",
			minWidth: 90,
			headerName: "Status",
			flex: 1.5,
			resizable: false,
			renderCell: (params) => {
				// console.log("paarams", statusStyles)
				const { payment_status, payment_status_caption } = params.row;

				const statusStyle = statusStyles[payment_status?.toLowerCase()]; // Use optional chaining (?.) to avoid the error

				return (
					<Chip
						label={payment_status_caption}
						size="small"

						sx={{
							height: statusStyle?.height,
							backgroundColor: statusStyle?.backgroundColor,
							color: statusStyle?.color,
							borderRadius: statusStyle?.borderRadius,
							border: statusStyle?.border,

							// '& .MuiChip-label': {
							// 	textAlign:"center",
							// 	whiteSpace: 'normal',
							//   },
						}}
					/>
				);
			},
		},

		{
			field: "id",
			headerName: "Action",
			flex: 1,
			resizable: false,
			minWidth: 100,
			renderCell: (params) => {
				// console.log(params)
				return (
					<Link to={`/transactiondetails/${params.row.transaction_seq}`}>
						<Button
							size="small"
							variant="outlined"
							label={params.value}
							sx={{ p: 0 }}
						>
							View
						</Button>
					</Link>
				);
			},
		},
	];


	let nextId = 1;
	const generateUniqueId = () => {
		return nextId++;
	};

	// Conditional mapping based on whether 'transaction' is not empty
	const transactionslist =
		transaction.length > 0
			? transaction.map((transactions) => ({
				id: generateUniqueId(), // Generate a unique ID
				...transactions, // Include the rest of the transaction data
			}))
			: [];



	const statusStyles = {
		s: { height: "auto", backgroundColor: "#26C77A", color: "white", borderRadius: 4 },
		f: { height: "auto", backgroundColor: "#e94a4a", color: "white", borderRadius: 4 },
		a: { height: "auto", backgroundColor: "#FF962D", color: "white", borderRadius: 4 },
		r: { height: "auto", backgroundColor: "#FFFFFF", color: "#26C77A", borderRadius: 4, border: "1px solid #26C77A" },
		c: { height: "auto", backgroundColor: "#FFFFFF", color: "#26C77A", borderRadius: 4, border: "1px solid #26C77A" },
		i: { height: "auto", backgroundColor: "#FFFFFF", color: "#FF962D", borderRadius: 4, border: "1px solid #FF962D" },
		rc: { height: "auto", backgroundColor: "#C5C5C7", color: "white", borderRadius: 4 },
		rf: { height: "auto", backgroundColor: "#C5C5C7", color: "white", borderRadius: 4 },
		rp: { height: "auto", backgroundColor: "#C5C5C7", color: "white", borderRadius: 4 },
		ra: { height: "auto", backgroundColor: "#C5C5C7", color: "white", borderRadius: 4 },
	};

	const SkeletonStyle = { borderRadius: theme => `${theme.card.borderRadius}`, height: 56, mb: 2, ml: 1, mr: 1 }

	return (
		<Box>
			{
				loading && !toggle ?
					<Box>
						<Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: 56, mt: 2, ml: 1, mr: 1, mb: 2 }} />
						<Skeleton variant="rectangular" sx={SkeletonStyle} />
						<Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: 56, mb: 3, ml: 1, mr: 1, width: "50%", pl: 1, pr: 1 }} />
						<Skeleton variant="rectangular" sx={SkeletonStyle} />
						<Skeleton variant="rectangular" sx={SkeletonStyle} />
						<Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: 56, mb: 2, ml: 1, mr: 1, width: "50%", pl: 1, pr: 1 }} />
					</Box>
					:
					empty(transaction) && !toggle ? <Box sx={{ p: { xs: 2, sm: 2, md: 3, lg: 3 } }}>
						<TableNoData
							header="There are no transactions yet"
							title="All your transactions and payments will show up here once you start using FarWide."
							buttonLink={config.module === 'lister' ? totalListingCount === 0 ? '/createProperty' : '/listings' : "/"}
							buttonText={config.module === 'lister' ? totalListingCount === 0 ? 'Create a Listing Now' : 'Review Listings' : "Explore FarWide"}
							image="/landowner/assets/images/placeholders/transactions_zero_img.svg"
						/>
					</Box> :
						<Box>
							<Box
								sx={{
									p: { xs: 2, sm: 2, md: 3, lg: 3 },
									display: "flex",
									gap: 1,
									alignItems: "center",
									flexWrap: "wrap", // Allow content to wrap on smaller screens
									justifyContent: { xs: "center", sm: "flex-start", md: "flex-start" }, // Center content on small screens, align to the start on medium and larger screens
								}}
							>
								<TextField
									value={filterConfig.search}
									size="small"

									onInput={(e) => {
										setFilterConfig({ ...filterConfig, search: e.target.value });
									}}
									placeholder="Search"
									onChange={handleSearch}
									sx={{
										borderRadius: 1.5, paddingInline: 0,
										width: isMobile ? "45%" : "204px",
										"& .MuiOutlinedInput-input": {
											paddingInline: 0,
											fontSize:"16px !important"
										},
										"& .MuiOutlinedInput-root": {
											height: 42,
											pl: "11px"
										},"& .MuiInputBase-root":{
											fontSize:"16px !important"
										}
									}}
									InputProps={{
										startAdornment: <InputAdornment position='start'><Icon.SearchOutlined sx={{ ml: 0, color: "grey900" }} /></InputAdornment>
									}}
								/>
								<Box sx={{ borderRadius: 1.5, width: isMobile ? "50%" : "204px" }}>
									<FormControl fullWidth>
										<Select
											size="small"
											labelId="wishlist_sort_label"
											sx={{ p: 0, height: 42, color: "var(--grey600)" }}
											id="wishlist_sort"
											name="wishlist_sort"
											value={filterConfig.sort}
											onChange={(e) => {
												setFilterConfig({ ...filterConfig, sort: e.target.value });
											}}

										>

											<MenuItem value="R">Recent </MenuItem>
											<MenuItem value="O">Oldest </MenuItem>
											<MenuItem value="L">Low to High Amount</MenuItem>
											<MenuItem value="H"> High to Low Amount</MenuItem>
											{/* <MenuItem value="LA">Low to High Acre </MenuItem> */}
											<MenuItem value="PA">Failed transactions first</MenuItem>
											<MenuItem value="PD">Refund transactions first </MenuItem>
										</Select>
									</FormControl>
								</Box>
							</Box>

						</Box>
			}
			{empty(transaction) && searchQuery !== "" && <Box sx={{ borderTop: "1px solid var(--grey500)", minHeight: "250px", textAlign: 'center', paddingTop: "100px", }}><Typography color='var(--grey900)' variant="body1" sx={{ fontWeight: 700 }}>No results found</Typography></Box>}


			{!empty(transaction) && <Box>
				<DataGridPremium
					rows={transactionslist}
					sx={{


					}}
					columns={columns}
					rowHeight={80}
					scrollbar={false}
					headerClassName="custom-header" // Optional, apply class to the entire header
					disableColumnReorder={true}
					disableMultipleRowSelection={true}
					disableRowSelectionOnClick={true}
					disableColumnMenu={true}
					rowSpacingType="border"
					hideFooter={true}
					hideScrollbar={true}
				// hideFooter={true}

				/>
				<Pagination totalRecords={totalRecords} setRowsPerPage={setRowsPerPage} setCurrentPage={setPage} />
			</Box>}

		</Box>

	)


	// return (
	// 	<Box>
	// 		<Box
	// 			sx={{
	// 				p: 3,
	// 				display: "flex",
	// 				gap: 1,
	// 				alignItems: "center",
	// 				flexWrap: "wrap", // Allow content to wrap on smaller screens
	// 				justifyContent: { xs: "center", md: "flex-start" }, // Center content on small screens, align to the start on medium and larger screens
	// 			}}
	// 		>
	// 			<TextField
	// 				value={filterConfig.search}
	// 				size="small"
	// 				onInput={(e) => {
	// 					setFilterConfig({ ...filterConfig, search: e.target.value });
	// 				}}
	// 				placeholder="search"
	// 				onChange={handleSearch}
	// 				sx={{ width: "23ch", borderRadius: 1.5 }}
	// 				InputProps={{
	// 					startAdornment: (
	// 						<InputAdornment position="start">
	// 							<Icon.Search />
	// 						</InputAdornment>
	// 					),
	// 				}}
	// 			/>
	// 			&emsp;
	// 			<Box sx={{ minWidth: "20ch", borderRadius: 1.5 }}>
	// 				<FormControl fullWidth>


	// 					<Select
	// 						size="small"
	// 						labelId="wishlist_sort_label"

	// 						sx={{ p: 0 }}
	// 						id="wishlist_sort"
	// 						name="wishlist_sort"

	// 						value={filterConfig.sort}
	// 						onChange={(e) => {
	// 							setFilterConfig({ ...filterConfig, sort: e.target.value });
	// 						}}

	// 					>

	// 						<MenuItem value="R">Recent </MenuItem>
	// 						<MenuItem value="O">Oldest </MenuItem>
	// 						<MenuItem value="L">Low to High Amount</MenuItem>
	// 						<MenuItem value="H"> High to Low Amount</MenuItem>
	// 						{/* <MenuItem value="LA">Low to High Acre </MenuItem> */}
	// 						<MenuItem value="PA">Failed transactions first</MenuItem>
	// 						<MenuItem value="PD">Refund transactions first </MenuItem>
	// 					</Select>
	// 				</FormControl>
	// 			</Box>
	// 		</Box>


	// 		<Box sx={{ width: "100%" }}>
	// 			{
	// 				toggle ?
	// 				<Typography>No result found</Typography> 
	// 				:
	// 			<>
	// 			<DataGridPremium
	// 				rows={transactionslist}
	// 				sx={{


	// 				}}
	// 				columns={columns}
	// 				rowHeight={80}
	// 				scrollbar={false}
	// 				headerClassName="custom-header" // Optional, apply class to the entire header
	// 				disableColumnReorder={true}
	// 				disableMultipleRowSelection={true}
	// 				disableRowSelectionOnClick={true}
	// 				disableColumnMenu={true} 
	// 				rowSpacingType="border"
	// 				hideFooter={true}
	// 				hideScrollbar={true}
	// 			// hideFooter={true}

	// 			/>
	// 			<Pagination totalRecords={totalRecords} setRowsPerPage={setRowsPerPage} setCurrentPage={setPage} />
	// 			{/* <Pagination /> */}

	// 			</>	
	// 			}
	// 		</Box>
	// 	</Box>
	// );
};
export default Transactiontable;
