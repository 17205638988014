import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Button, Typography, Divider } from "@mui/material";
import * as Icon from "@mui/icons-material";
import WishlistBody from "../wishlist/sections/WishlistBody";
import { Link } from 'react-router-dom';
import { showZendeskLauncher } from "../../utils/common";

const Wishlist = () => {
	const [selectedTab, setSelectedTab] = useState(0);


	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};
	useEffect(() => {
        showZendeskLauncher(true)
    }, [])
	return (
		<>
			<Box
				sx={{
					display: "flex",
					flexDirection: "row",
					justifyContent: "space-between",
					alignItems: "center",
					mb: { xs: 2, sm: 2, md: 3, lg: 3 }
				}}
			>
				<Typography variant="h4">Wishlist</Typography>

				<Box
					sx={{
						display: "flex",
						flexDirection: { xs: "column", md: "row" }, // Column layout on small screens, row layout on medium and larger screens
						justifyContent: "space-between",
						alignItems: "center",
					}}
				>
					{/* <Link to="/search">
						<Button
							variant="light"
							startIcon={<Icon.Search />}

						>
							Search Property
						</Button>
					</Link> */}
				</Box>
			</Box>


			<Box
				sx={{
					border: "1px solid #E5E7EB",
					borderRadius: "16px",
					width: "100%",
					mb:10 // added this margin bottom because of chatbot icon overlapping on pagination
					// Optional: Add a box shadow
				}}
			>
				<Tabs
					value={selectedTab}
					onChange={handleTabChange}
					variant="fullWidth"
					centered
					sx={{

						borderTopLeftRadius: "16px",
						borderTopRightRadius: "16px",

						width: "100%",
						display: "flex",
						justifyContent: "space-around",
						// Optional: Add a box shadow
					}}
				>
					<Tab
						label="Packages"
						sx={{
							flexGrow: 1,
							textAlign: "center",
							py: 1,
							color: "#1B1B1B",
							textTransform: "none",
							fontSize: "16px",
						}}
					/>
					<Tab
						label="Properties"
						sx={{
							flexGrow: 1,
							textTransform: "none",
							textAlign: "center",
							py: 1,
							color: "#1B1B1B",

							fontSize: "16px",
						}}
					/>
				</Tabs>

				<Divider />

				<WishlistBody filter={selectedTab} />


			</Box>
		</>
	);
};

export default Wishlist;
