import React, { useState } from "react";
import {
	Box,
	Divider,
	FormGroup,
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@mui/material";
import { UserAuth } from "../../store/context/userContext";
import * as Icon from "@mui/icons-material";
import { CommonContext } from "../../store/context/commonContext";
import { empty } from "../../utils/common";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import FeatureSection from "../home/section/FeatureSection";
import AccountVerification from "../../commonComponents/AccountVerification";

const activityOptions = [
	{ value: "A", label: "All Activities" },
	{ value: "H", label: "Hunting" },
	{ value: "F", label: "Fishing" },
	{ value: "BW", label: "Bird Watching" },
	{ value: "FR", label: "Farm and Ranch" },
];

const categoryLinks = [
	{ path: "/search?at=F", image: "/assets/images/homepage/fishing_search_img.png", label: "Fishing" },
	{ path: "/search?at=BW", image: "/assets/images/homepage/birdwatching_search_img.png", label: "Bird Watching" },
	{ path: "/search?at=FR", image: "/assets/images/homepage/rv_search_img.png", label: "RV & Camping" },
	{ path: "/search?at=H", image: "/assets/images/homepage/hunting_search_img.png", label: "Hunting" },
	{ path: "/search?at=FR", image: "/assets/images/homepage/horse_search_img.png", label: "Horseback Riding" },
];

const Dashboard = () => {
	const { user } = UserAuth();
	const navigate = useNavigate();
	const { searchFilters, setSearchFilters } = CommonContext();
	const [selectedActivity, setSelectedActivity] = useState(searchFilters.activity);
	const {isTablet} = CommonContext();

	const handleActivityChange = (e) => {
		setSearchFilters({ activity: e.target.value });
		setSelectedActivity(e.target.value);
	};

	const handleSearch = () => {
		const queryParams = `?kw=${searchFilters.kw}&at=${selectedActivity === "A" ? "" : selectedActivity}`;
		if (window.location.pathname !== "/search") {
			navigate(`/search${queryParams}`);
		}
	};

	const handleEnterKey = (e) => {
		if (e.key === "Enter") {
			handleSearch();
		}
	};

	return (
		<>
			<AccountVerification sx={{ mb: 6 }} />
			<Box
				sx={{

					bgcolor: 'var(--grey300)',
					borderRadius: '8px',
					pb: 6,
				}}
			>
				<Typography
					variant="h6"
					fontWeight="bold"
					fontSize='34px'
					textAlign="center"
					sx={{
						py: { xs: 2, sm: 3, md: 3, lg: 3 },
					}}
				>
					Search Properties
				</Typography>
				<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', flexDirection: 'column', alignItems: 'center' }}>
					<Box>
						<FormGroup
							sx={{
								alignItems: 'center',
								flexWrap: 'nowrap',
								flexDirection: 'row',
								justifyContent: 'center',
								border: 'solid 1px #C5C5C7',
								borderRadius: '16px',
								maxWidth: '680px',
								m: 'auto',
								height: '48px',
								bgcolor: 'white',
								mx: 2
							}}
						>
							<Select
								name="listing_sort"
								size="large"
								value={selectedActivity}
								onChange={handleActivityChange}
								IconComponent={Icon.KeyboardArrowDownOutlined}
								sx={{
									border: 'none',
									"& fieldset": { border: 'none' },
									m: 0,
									pt: 0,
									width: "19ch",
									mb: 0,
									mt: "0px",
									display: { xs: 'none', sm: 'none', md: 'block' },
								}}
							>
								{activityOptions.map((option) => (
									<MenuItem key={option.value} value={option.value} selected={option.value === selectedActivity}>
										{option.label}
									</MenuItem>
								))}
							</Select>
							<Divider orientation="vertical" variant="middle" flexItem sx={{ mt: 0, mb: 0, display: { xs: 'none', sm: 'none', md: 'block' } }} />
							<TextField
								className="top_hdr_search_input"
								placeholder={isTablet?'Search..':'Where would you like to go?'}
								variant="outlined"
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											{!empty(searchFilters.kw) ? (
												<IconButton sx={{ width: "34px", height: "34px", p: 0, mr: '2px', color: 'black', backgroundColor: 'var(--grey300)' }} onClick={() => setSearchFilters({ kw: '' })}>
													<Icon.Close />
												</IconButton>
											) : null}
											<IconButton variant="contained" style={{ background: "var(--primary)", color: "#fff" }} onClick={handleSearch}>
												<Icon.SearchOutlined />
											</IconButton>
										</InputAdornment>
									),
								}}
								sx={{
									border: 'none', "& fieldset": { border: 'none' }, m: 0, pt: '2px', pb: '2px', width: { xs: '100%', md: 'calc(100%-80px)', },
									'& .MuiOutlinedInput-root': {
										paddingRight: '3px'
									}
								}}
								size="large"
								value={searchFilters.kw}
								onInput={(e) => setSearchFilters({ kw: e.target.value })}
								onKeyDown={handleEnterKey}
							/>
						</FormGroup>
						<Box sx={{ maxWidth: '680px', textAlign: 'right', margin: 'auto', mb: 5, mb: '40px', mr: 2 }}>
							<Link to="/search?at=" style={{ color: 'var(--black)', fontSize: '12px', borderBottom: 'solid 1px var(--black)', textDecoration: 'none' }}>Explore All</Link>
						</Box>
					</Box>
				</Box>
				{/* <Box sx={{ overflow: 'auto', display: 'flex' }}>
					<Box sx={{ margin: 'auto', display: 'flex', gap: 2, justifyContent: { xs: 'initial', md: 'center' }, pl: 2 }}>
						{categoryLinks.map((link) => (
							<Box key={link.label} className="search_img_link" sx={{ textAlign: 'center' }}>
								<Link to={link.path}>
									<Box
										className="search_img"
										sx={{
											backgroundImage: `url("${link.image}")`,
											width: '190px',
											height: '120px',
											backgroundRepeat: 'no-repeat'
										}}
									></Box>
									<Typography>{link.label}</Typography>
								</Link>
							</Box>
						))}
					</Box>
				</Box> */}
				<Box sx={{ overflow: 'auto', display: 'flex', pl: 2 }}>
					<Box sx={{ margin: 'auto', display: 'flex', gap: 2, justifyContent: { xs: 'initial', md: 'center' }, mb: 1 }}>
						<Box className="search_img_link" sx={{ textAlign: 'center' }}>
							<Link to="/search?at=F">
								<Box className="search_img">
									<img src="/assets/images/homepage/fishing_search_img.png" alt="fishing" style={{ maxWidth: '100%' }} />
								</Box>
								<Typography>Fishing</Typography>
							</Link>
						</Box>

						<Box className="search_img_link" sx={{ textAlign: 'center' }}>
							<Link to="/search?at=BW">
								<Box className="search_img">
									<img src="/assets/images/homepage/birdwatching_search_img.png" alt="bird watching" style={{ maxWidth: '100%' }} />
								</Box>
								<Typography>Bird Watching</Typography>
							</Link>
						</Box>

						<Box className="search_img_link" sx={{ textAlign: 'center' }}>
							<Link to="/search?at=FR">
								<Box className="search_img">
									<img src="/assets/images/homepage/rv_search_img.png" alt="RV & Camping" style={{ maxWidth: '100%' }} />
								</Box>
								<Typography>Farm and Ranch</Typography>
							</Link>
						</Box>

						<Box className="search_img_link" sx={{ textAlign: 'center' }}>
							<Link to="/search?at=H">
								<Box className="search_img">
									<img src="/assets/images/homepage/hunting_search_img.png" alt="hunting" style={{ maxWidth: '100%' }} />
								</Box>
								<Typography>Hunting</Typography>
							</Link>
						</Box>

						{/* <Box className="search_img_link" sx={{ textAlign: 'center' }}>
							<Link to="/search?at=FR">
								<Box className="search_img">
									<img src="/assets/images/homepage/horse_search_img.png" alt="horseback riding" style={{ maxWidth: '100%' }} />
								</Box>
								<Typography>Horseback Riding</Typography>
							</Link>
						</Box> */}
					</Box>
				</Box>
			</Box>
			<FeatureSection />
		</>
	);
};

export default Dashboard;
