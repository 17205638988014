import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ROUTE_CHAT, ROUTE_CHECKOUT, ROUTE_LISTER_HOME } from '../../../constants/page';
import { CommonContext } from '../../../store/context/commonContext';
import AuthPopup from '../../../commonComponents/authComponents/AuthPopup';
import { UserAuth } from '../../../store/context/userContext';
import { empty } from '../../../utils/common';
import { Grid, Menu, MenuItem } from '@mui/material';
import * as Icon from '@mui/icons-material';
import LinkHref from '@mui/material/Link'
import ProfileSection from '../../ProfileSection';
import { useState } from 'react';
import  LandingPageDrawer from "../Sidebar/index"

const drawerWidth = 267;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer ,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - 0px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	position: 'fixed', // Make the position fixed
	top: 0, // Position at the top of the viewport
}));

const LandingPageHeader = (props) => {
	const { user, authPopup, setAuthPopup } = UserAuth()
	const { scroll } = CommonContext()

	const openAuthPopup = (type) => {
		setAuthPopup({ ...authPopup, type: type, open: true })
	}
	const closeAuthPopup = () => {
		setAuthPopup({ ...authPopup, open: false })
	}
	const location = useLocation();
	const showSidebar = [ROUTE_CHAT, ROUTE_CHECKOUT].includes(
		location.pathname
	);

	const { isMobile , isTablet } = CommonContext();

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [openDrawer, setOpenDrawer] = useState(false);

	const toggleDrawer = (newOpen) => () => {
		setOpenDrawer(newOpen);
	};

	return (
		<AppBar sx={scroll > 0 ? { background: '#fff', borderBottom: 0, boxShadow: '0 1px 6px #00000020' } : { background: '#ffffff00', boxShadow: 'unset' }} position="fixed" open={props.open}>
            {
				isTablet &&
              <LandingPageDrawer open={openDrawer} toggleDrawer={toggleDrawer}/> 
			}
			<Toolbar 
				sx={{
					// pr: '0px', // keep right padding when drawer closed
					height: "72px",
					p: "16px !important"
				}}
			>	<Grid container maxWidth={'lg'} sx={{ margin: '0 auto' }}>
				    <Box sx={{position:"absolute", top:16}}>
						{empty(user) &&
							isTablet && (
								<>
									<Box sx={{ color: 'var(--black)' }}>
										<IconButton
											id="basic-button"
											aria-controls={open ? 'basic-menu' : undefined}
											aria-haspopup="true"
											aria-expanded={open ? 'true' : undefined}
											onClick={toggleDrawer(true)}
										>
											<Icon.MenuOutlined />
										</IconButton>

										{/* <Menu
											id="basic-menu"
											anchorEl={anchorEl}
											open={open}
											onClose={handleClose}
											MenuListProps={{
												'aria-labelledby': 'basic-button',
											}}
										>
											<MenuItem>
												<LinkHref href={"/"} sx={{ color: 'var(--black)', display: { md: 'block' }, textDecoration: 'none' }}>
													Explore Properties
												</LinkHref>
											</MenuItem>
											<MenuItem onClick={() => { handleClose(); openAuthPopup('signin') }}>Sign In</MenuItem>
											<MenuItem onClick={() => { handleClose(); openAuthPopup('signup') }}>Sign Up</MenuItem>
										</Menu> */}
									</Box>
								</>
							)
						}
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "100%" }}>

						<Link className="logo image" to="/" style={{ marginInline: (empty(user) && isTablet) && "auto" }}>
							<Box sx={{ display: { xs: 'block', sm: 'block', md: 'block' } }}><img src='../assets/images/homepage/farwide-logo.svg' alt='FarWide' /></Box>
							{/* <Box className="fw_logo_mob" sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, width: '40px' }}><img src='../assets/images/homepage/farwide-logo.svg' alt='FarWide' /></Box> */}
						</Link>
						{/* {!showSidebar &&
							<IconButton
								className='fw_hdr_toggle_btn'
								edge="start"
								aria-label="open drawer"
								onClick={props.toggleDrawer}
								sx={{
									marginRight: '24px',
									marginLeft: '24px',
									color: 'var(--black)',
									...(props.open),
								}}
							>
							</IconButton>
						} */}
						{!isTablet && 
						<Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
							{
								!isTablet ? <LinkHref href={ROUTE_LISTER_HOME} sx={{ color: 'var(--black)', display: { xs: 'none', sm: 'block', md: 'block', textDecoration: 'none' } }}><Button variant="link" color='var(--black)' sx={{ textDecoration: 'none', mr: 1 }}>List Your Property </Button></LinkHref> : <></>
							}
						</Box>
						
						}
						{/* <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
							<LinkHref href={ROUTE_LISTER_HOME} sx={{ color: 'var(--black)', display: { xs: 'none', sm: 'none', md: 'block', textDecoration: 'none' } }}><Button variant="link" color='var(--black)' sx={{ textDecoration: 'none', mr: 1 }}>List Your Property </Button></LinkHref> */}
							{
								empty(user) ?
									<>
{/* 
										{
											isTablet && (
												<>
													<Box sx={{ color: 'var(--black)' }}>
														<IconButton
															id="basic-button"
															aria-controls={open ? 'basic-menu' : undefined}
															aria-haspopup="true"
															aria-expanded={open ? 'true' : undefined}
															onClick={handleClick}
														>
															<Icon.MenuOutlined />
														</IconButton>

														<Menu
															id="basic-menu"
															anchorEl={anchorEl}
															open={open}
															onClose={handleClose}
															MenuListProps={{
																'aria-labelledby': 'basic-button',
															}}
														>
															<MenuItem>
																<LinkHref href={ROUTE_LISTER_HOME} sx={{ color: 'var(--black)', display: { md: 'block' }, textDecoration: 'none' }}>
																	List Your Property
																</LinkHref>
															</MenuItem>
															<MenuItem onClick={() => { handleClose(); openAuthPopup('signin') }}>Sign In</MenuItem>
															<MenuItem onClick={() => { handleClose(); openAuthPopup('signup') }}>Sign Up</MenuItem>
														</Menu>
													</Box>
												</>
											)
										} */}

										{
											!isTablet &&
											<Box>
												<Button variant="outlined" sx={{ mr: 1 }} onClick={() => { openAuthPopup('signin') }}>Sign In</Button>
												<Button variant="contained" onClick={() => { openAuthPopup('signup') }}>Sign Up</Button>
											</Box>

										}


										<AuthPopup open={authPopup.open} type={authPopup.type} handleClose={() => { closeAuthPopup(false) }} handleOpen={openAuthPopup} />
									</> :
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<ProfileSection />
									</Box>
							}
						{/* </Box> */}
					</Box>
				</Grid>
			</Toolbar>
		</AppBar >)
}

export default LandingPageHeader

