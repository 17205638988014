import {
  //  Avatar,
  Box,
  IconButton,
  Tooltip,
  // Divider, 
  Typography
} from "@mui/material";
import * as Icon from "@mui/icons-material";
// import ReplyIcon from "@mui/icons-material/Reply";
// import { useState } from "react";
// import Moment from 'moment'

// import IconButton from '@mui/material/IconButton';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
// import Fade from '@mui/material/Fade';
import MessageComponent from "../defaultMsg";
import moment from "moment";
// import { MessageContext } from "../../../../store/context/messageContext";
import { extractDataFromArray } from "../../../../utils/common";
import SystemToolTip from '../systemToolTip'
import { CommonContext } from "../../../../store/context/commonContext";




const RightChat = ({ message, i,
  // isReply, setIsReply, 
  seqValue, onchat2 }) => {
  // const date = message.date;
  // const [date, setDate] = useState("");
  const { isMobile } = CommonContext();
  // const time = message.time;


  // const [anchorEl, setAnchorEl] = useState(null);
  // const open = Boolean(anchorEl);
  // const handleClick = (event) => {
  //   setAnchorEl(event.currentTarget);
  // };
  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const currentDate = new Date();
  // const isToday = date === currentDate.toISOString().split("T")[0];

  // const [hours, minutes, seconds] = time.split(":");

  // const formattedTime = `${parseInt(3, 10) % 12 || 12}:${minutes}${
  //   parseInt(hours, 10) >= 12 ? "PM" : "AM"
  // }`;

  // const handleReply = () => {
  //   setIsReply({ flag: true, conversation: message, time: 45 });
  // };


let showSystemToolTip = extractDataFromArray(message,['messages','system', 'text'],'');
  return (
    <Box sx={{ display: "flex", justifyContent: "flex-end",  }}>
      <Box
        key={i}
        sx={{
          width: "100%",
          maxWidth:"80%",
          display: "flex",
          padding: 2,
          gap: 1.25,
          alignItems: "flex-start",
          // cursor: "pointer",
          "& #hidden-button": {
            visibility: "hidden",
          },
          "&:hover #hidden-button": {
            visibility: "visible",
          },
          wordBreak:"break-word"
        }}
      >
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "flex-end", }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                gap: 0.5,

              }}
            >
              {
                showSystemToolTip && 
              <SystemToolTip content={extractDataFromArray(message,['messages','system', 'text'],'')} />
              }
              <Typography variant='caption' sx={{ color: "#85878A" }}>
                {moment.utc(extractDataFromArray(message, ['create_time'], '')).local().format('DD MMM') + " at " + moment.utc(extractDataFromArray(message, ['create_time'], '')).local().format('h:mm A')} 
              </Typography>
            </Box>
          </Box>

          <Box sx={{ display: "flex", alignItems: 'center', gap: 1, justifyContent: "flex-end" }}>
            {/* <Box
              id="hidden-button"
              onClick={handleReply}
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#F5F5F6",
                visibility: "hidden",
              }}
            >
              <ReplyIcon sx={{ width: "24px", height: "32px" }} />
            </Box>
            <Box>
              <IconButton
                aria-label="more"
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
                sx={{ backgroundColor: "#F5F5F6 !important", height: "32px", width: "32px" }}
              >
                <MoreVertIcon />
              </IconButton>
              <Menu
                id="fade-menu"
                MenuListProps={{
                  'aria-labelledby': 'fade-button',
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                TransitionComponent={Fade}
              >
                <MenuItem onClick={handleClose} sx={{ color: "#E94A4A", height: "40px", width: "206px" }}><DeleteOutlineOutlinedIcon sx={{ color: "#E94A4A" }} />Delete</MenuItem>

              </Menu>
            </Box> */}
            <MessageComponent onchat2={onchat2} message={message} seqValue={seqValue} isMobile={isMobile} />
          </Box>

        </Box>
      </Box>
      {/* } */}
      {/* } */}



      {/* 
        // <Box
        //   key={i}
        //   sx={{
        //     display: "flex",
        //     padding: "16px",
        //     gap: "10px",
        //     alignItems: "flex-start",
        //     cursor: "pointer",
        //     "& #hidden-button": {
        //       visibility: "hidden",
        //     },
        //     "&:hover #hidden-button": {
        //       visibility: "visible",
        //     },
        //   }}
        // >
        //   <Box>
        //     <Avatar
        //       alt="Remy Sharp"
        //       src={
        //         message.sender === "Kaushal"
        //           ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6MbzLVrpxVHJaPIUx3QTIKpUaaYlzkpbo_A&usqp=CAU"
        //           : "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1600"
        //       }
        //       sx={
        //         isMobile ? { width: 32, height: 32 } : { width: 40, height: 40 }
        //       }
        //     />
        //   </Box>
        //   <Box
        //     sx={{
        //       flex: 1,
        //       display: "flex",
        //       flexDirection: "column",
        //       textAlign: "left",
        //     }}
        //   >
        //     <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        //       <Box
        //         sx={{
        //           display: "flex",
        //           alignItems: "baseline",
        //           gap: "8px",
        //         }}
        //       >
        //         <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
        //           {message.message_sender}
        //         </Typography>
        //         <Typography sx={{ fontSize: "12px", color: "#85878A" }}>
        //         {Moment(message.msg_create_time, "YYYY-MM-DD HH:mm:ss").format("MMM DD, h:mm A")}
        //         </Typography>
        //       </Box>
        //       <Box
        //         id="hidden-button"
        //         onClick={handleReply}
        //         sx={{
        //           width: "32px",
        //           height: "32px",
        //           borderRadius: "50%",
        //           display: "flex",
        //           justifyContent: "center",
        //           alignItems: "center",
        //           backgroundColor: "#F5F5F6",
        //           visibility: "hidden",
        //         }}
        //       >
        //         <ReplyIcon sx={{ width: "24px", height: "32px" }} />
        //       </Box>
        //     </Box>
        //     <Box>
        //       <Typography
        //         sx={{
        //           marginTop: "-8px",
        //           width: "100%",
        //           color: "#85878A",
        //           fontSize: "16px",
        //         }}
        //       >
        //         {message.message}
        //       </Typography>
        //     </Box>
        //   </Box>
        // </Box> */}
      {/* // ) : ( */}
      {/* <Box
          key={i}
          sx={{
            padding: "16px",
          }}
        >
          <Box
            sx={{
              border: "1px solid #E5E7EB",
              padding: "9px",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "flex-start",
                cursor: "pointer",
                "& #hidden-button": {
                  visibility: "hidden",
                },
                "&:hover #hidden-button": {
                  visibility: "visible",
                },
              }}
            >
              <Box>
                <Avatar
                  alt="Remy Sharp"
                  src={
                    message.replyTo === "Kaushal"
                      ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6MbzLVrpxVHJaPIUx3QTIKpUaaYlzkpbo_A&usqp=CAU"
                      : "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  }
                  sx={
                    isMobile
                      ? { width: 32, height: 32 }
                      : { width: 40, height: 40 }
                  }
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: "8px",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                    {message.replyTo}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "#85878A" }}>
                    {message.messageTime}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      width: "100%",
                      color: "#85878A",
                      fontSize: "16px",
                    }}
                  >
                    {message.replyOf}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "flex-start",
                    borderRadius: "8px",
                    backgroundColor: "#F5F5F6",
                    marginTop: "8px",
                    padding: "8px",
                    cursor: "pointer",
                    "& #hidden-button": {
                      visibility: "hidden",
                    },
                    "&:hover #hidden-button": {
                      visibility: "visible",
                    },
                  }}
                >
                  <Box>
                    <Avatar
                      alt="Remy Sharp"
                      src={
                        message.sender === "Kaushal"
                          ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6MbzLVrpxVHJaPIUx3QTIKpUaaYlzkpbo_A&usqp=CAU"
                          : "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1600"
                      }
                      sx={{ width: 24, height: 24 }}
                    />
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "8px",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                        {message.sender}
                      </Typography>
                      <Typography sx={{ fontSize: "12px", color: "#85878A" }}>
                      {"45"}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography
                        sx={{
                          width: "100%",
                          color: "#85878A",
                          fontSize: "16px",
                        }}
                      >
                        {message.message}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box> */}
      {/* )}  */}
    </Box>
  );
};

export default RightChat;
