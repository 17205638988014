import * as Icon from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LinkHref from '@mui/material/Link'
import FeaturedPropertiesService from "../../../services/featuredProperties";
import { empty, extractDataFromArray } from "../../../utils/common";
import { ROUTE_LISTER_HOME } from "../../../constants/page";


const ListPropertySection = () => {
    return (
        <Grid container sx={{ mt: { xs: 6, md: 12 }, mb: { xs: 6, md: 12 },  }}>
            <Grid item xs={12} sm={12} md={12}>
                <Box sx={{ backgroundImage: "url(../assets/images/homepage/bg_list_today.jpg)", backgroundPosition: { xs: '540px', sm : 'right', md: 'right' }, minHeight: 218, padding: { xs: 3, md: 6 }, borderRadius: '16px', backgroundSize: 'cover', alignItems: 'flex-start', justifyContent: 'center', display: 'flex', flexDirection: 'column', }}>
                    <Grid>
                        <Grid item xs={12} sm={12} md={8}>
                            <Typography variant="h4" sx={{ mb: 4, color: '#fff', textShadow: '2px 2px 6px #1b1b1b90' }}>
                                Ready to start earning passive income in exchange for recreational access?
                            </Typography>

                            <LinkHref href={ROUTE_LISTER_HOME}>
                                <Button variant="contained" size="large">
                                    List Today!
                                </Button>
                            </LinkHref>

                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    )
}


export default ListPropertySection;
