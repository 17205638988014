import React, { useEffect } from "react";
import { Box ,Typography} from "@mui/material";
import Transactiontable from "./sections/Transactiontable";
import { showZendeskLauncher } from "../../utils/common";

const Transactions = () => {
  useEffect(() => {
    showZendeskLauncher(true)
}, [])
  return (
    <Box sx={{ mb: 5 }}>
      <Box
        sx={{
          display: "flex",
          border: "1px solid #fff",
          justifyContent: "space-between",
          alignItems: "center",
          mb:1,
        }}
      >
        <Typography variant="h4">Transactions </Typography>
      </Box>

      <Box
        sx={{
          border: "1px solid #E5E7EB",
          borderRadius: "16px",
          width: "100%",

          // Optional: Add a box shadow
        }}
      >
        <Transactiontable />
      </Box>
    </Box>
  );
};

export default Transactions;
