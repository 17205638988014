import UnauthorisedAccessPage from "../pages/unauthorisedAccessPage";
import { ProtectedRoute } from "./protectedRoutes";



const UnauthorisedRoutes = {
    path: '/unauthorised',
    element: <UnauthorisedAccessPage />,
    children: []
}


export default UnauthorisedRoutes;
