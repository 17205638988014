import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Outlet } from 'react-router-dom';
import { CommonContext } from "../../store/context/commonContext";


const UnauthorisedAccessPage = () => {
    // const {errorMessage, setErrorMessage} = CommonContext()
    // // const [errorMessage, setErrorMessage] = useState('');
    // const location = useLocation();

    // useEffect(() => {
    //     window.history.replaceState(null, null, window.location.origin + "/lister/unauthorised");
    //     const errorState = location.state;
    //     if (errorState && errorState.errorMessage) {
    //         setErrorMessage(errorState.errorMessage);
    //     }
    // }, [location, setErrorMessage]);
    const { errorMessage, setErrorMessage } = CommonContext();
    const location = useLocation();
    const navigate = useNavigate();

    const handleGoToDashboard = () => {
        // Clear local storage
        localStorage.removeItem('unauthorisedErrorMessage');
        // Set errorMessage to empty string
        setErrorMessage('');
        // Navigate to the dashboard
        // navigate.push("/dashboard");
    };

    useEffect(() => {
        // Load the error message from local storage
        const storedErrorMessage = localStorage.getItem('unauthorisedErrorMessage');

        if (storedErrorMessage) {
            // Set the error message only if it's not already set or if it's a different error
            if (!errorMessage || errorMessage !== storedErrorMessage) {
                setErrorMessage(storedErrorMessage);
            }
        } else {
            // Update the error message based on the navigation state
            const errorState = location.state;
            if (errorState && errorState.errorMessage) {
                setErrorMessage(errorState.errorMessage);
                // Save the error message to local storage
                localStorage.setItem('unauthorisedErrorMessage', errorState.errorMessage);
            }
        }

        // Attach event listener to clear local storage when leaving the page
        const handleBeforeUnload = () => {
            // Clear local storage only if the error message matches the current one
            if (localStorage.getItem('unauthorisedErrorMessage') === errorMessage) {
                localStorage.removeItem('unauthorisedErrorMessage');
            }
        };

        window.addEventListener('beforeunload', handleBeforeUnload);

        // Cleanup the event listener when the component is unmounted
        return () => {
            window.removeEventListener('beforeunload', handleBeforeUnload);
        };
    }, [location, errorMessage, setErrorMessage, navigate]);

    return (
        <>
              <Box sx={{display: "flex", justifyContent: "center", alignItems: "center",flexDirection: "column",textAlign:'center', mt:10, p:3}}>
                <img src="/assets/images/url_error.svg" alt="404" style={{ maxWidth: '580px' }} />
                <Box sx={{maxWidth: '580px'}}>
                <Typography variant="h4">{errorMessage}</Typography>
                </Box>
                <p>Unable to complete this action. Please return to the Dashboard and check all details once.</p>
                <Button component={Link} to="/dashboard" onClick={handleGoToDashboard}  variant="contained" sx={{mt:3}}>Go Back to Dashboard</Button>
                {/* <Outlet/> */}
            </Box>
        </>
    )
}


export default UnauthorisedAccessPage;