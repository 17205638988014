import * as Icon from "@mui/icons-material";
import { Box, CardMedia, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import LinkHref from '@mui/material/Link'
import FeaturedPropertiesService from "../../../services/featuredProperties";
import { empty, extractDataFromArray } from "../../../utils/common";
import { CommonContext } from "../../../store/context/commonContext";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';


const FeatureSection = () => {
  const { isMobile } = CommonContext()
  const [PropertieList, setFeaturedPropertiesList] = useState([])
  const [limit, setlimit] = useState('4')
  const { screenWidth } = CommonContext()
  useEffect(() => {
    async function getFeaturedProperties() {
      await FeaturedPropertiesService.getFeaturedProperties(limit, (data) => {
        // Only take the first 4 properties
        setFeaturedPropertiesList(data.slice(0, 4));
      });
    }
    getFeaturedProperties()
  }, [setFeaturedPropertiesList])

  if (PropertieList.length < 4) {
    return <></>
  }

  return (
    <Grid container spacing={isMobile ? 2 : 4} sx={{ mb: { xs: 6, md: 12 } }}>
      <Grid item xs={12} sm={12} md={12}>
        <Typography variant="h4" sx={{ textAlign: 'center', mb: { xs: 0, md: 1 } }}>Featured Properties</Typography>
      </Grid>

      {PropertieList.map((property, index) => (
        <Grid key={index} item xs={6} sm={6} md={3}>
          <Box className="fea_prop">
            <LinkHref target="_blank" style={{ color: '#fff', textDecoration: 'none' }} href={window.location.origin + "/property/" + property.slug + "-" + property.listing_seq} >
              <Box className="fea_prop_img" sx={{ backgroundImage: `url(${extractDataFromArray(property, ["listing_media", "media_library", 0, "url"], "")})` }}>
                {
                  extractDataFromArray(property, ["listing_media", "media_library", 0, "type"], "") === 'VIDEO' && <div className='' style={{ position: 'relative', display: 'flex' }}>
                    <div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                      <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
                    </div>
                    <CardMedia
                      component='video'
                      sx={{
                        width: '100%',
                        height: screenWidth < 769 ? '150px' : '412px',
                        backgroundSize: 'cover',
                        borderRadius: '16px',
                        position: 'relative',
                        objectFit: 'cover'
                      }}
                      image={extractDataFromArray(property, ["listing_media", "media_library", 0, "url"], "")}
                    />
                  </div>}
                <Box className="fea_prop_location" sx={{ fontSize: { sm: '14px', md: '16px', zIndex: 1 } }}>
                  <Icon.LocationOnOutlined /> {property.state}
                </Box>
              </Box>
              <Box >
                <Typography variant="body1" sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontWeight: '500', mt: 2, mb: 1 }}>{property.title}</Typography>
                <Box className="fea_prop_pricing">
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: "bold", }}> ${property.starting_price}</Typography><Typography variant="caption" > / starting at</Typography>
                  </Box>
                  {
                    !empty(property.average_rating) &&
                    <Box className="fea_prop_rating" sx={{ color: "#000", fontWeight: 'bold' }}>
                      {property.average_rating}  <Icon.StarOutlined />
                    </Box>
                  }
                </Box>
              </Box>
            </LinkHref>
          </Box>
        </Grid>
      ))}

    </Grid>
  )
}


export default FeatureSection;
