import { useCallback, useMemo } from "react";

// import { styled } from '@mui/material/styles'
import { Box,  Grid, Typography } from "@mui/material"
import * as Icon from "@mui/icons-material";
import { useDropzone } from "react-dropzone";
import { empty } from "../../../utils/common";
import { useLocation } from "react-router-dom"



const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

export function DropZone({dropCallback, accept}) {
    const location = useLocation()
    const setImageLimit = location.pathname === "/profile" ? false : true

    const onDrop = useCallback((files) => {
        dropCallback(files)


    }, [dropCallback]);

    let dropZoneConfig = {onDrop, multiple: setImageLimit }
    if(!empty(accept)){
        dropZoneConfig["accept"] = {}
        dropZoneConfig["accept"][accept] = []
    }

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone(dropZoneConfig);

    const style = useMemo(() => ({
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);


    return (
        <>
            <Grid item xs={12} sm={12} md={12}>
                <div className="container" style={{cursor : 'pointer'}}>
                    <div {...getRootProps({ style })}>
                        <input {...getInputProps()}  accept={accept} />
                        <Box className="file_uploader" sx={{ textAlign: "center", borderRadius: theme => `${theme.card.borderRadius} ` }}>
                            <Icon.Upload fontSize="large"  sx={{color : 'var(--grey900)'}} />
                            <Typography variant="h5" sx={{color : 'var(--grey900)'}}>Drop or select files</Typography>
                            <Typography variant="body" sx={{color : 'var(--grey900)'}}>Drop files here or click to browse from your device</Typography>
                        </Box>
                    </div>
                </div>
            </Grid>
        </>
    );
}
export default DropZone;