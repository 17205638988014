// import { Box, Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, Grid, IconButton, Typography } from "@mui/material";
// import * as Icon from '@mui/icons-material';

// const FilterPopup = ({ filterData = {}, type, value, setValue, open, handleClose, speciesFilterChange }) => {
    

    
//     return (
//         <Dialog
//             open={open}
//             onClose={handleClose}
//         >
//             <DialogTitle id='customized-dialog-title' sx={{ py: 2, px: 3, justifyContent: "space-between", display: "flex" }}>
//                 <Typography variant='h6' component='span'>
//                     Filters
//                 </Typography>
//                 <IconButton onClick={handleClose}><Icon.Close /></IconButton>
//             </DialogTitle>
//             <Divider />
//             <DialogContent>
//                 <Typography variant="body" sx={{ fontWeight: '600' }}>Select Species</Typography>
//                 <Box sx={{ mt: 1 }}>
//                     <Grid container>
//                         {
//                             Object.entries(filterData).map(([index, val]) => (
//                                 <Grid item xs={12} sm={6} md={6} key={index}>
//                                     <FormControlLabel control={<Checkbox />} label={`${index} (${val})`} />
//                                 </Grid>
//                             ))
//                         }
//                     </Grid>
//                 </Box>
//             </DialogContent>
//             <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', p: 3 }}>
//                 <Button variant="link">Clear All</Button>
//                 <Button variant="contained" onClick={speciesFilterChange}>
//                     Apply
//                 </Button>
//             </DialogActions>
//         </Dialog>
//     )
// }

// export default FilterPopup;

import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Typography
} from "@mui/material";
import * as Icon from '@mui/icons-material';

const FilterPopup = ({ filterData = {}, type, value, setValue, open, handleClose, selectedFilters, setSelectedFilters ,handleFilterChange}) => {

    const handleCheckboxChange = (index) => {
        const updatedFilters = [...selectedFilters];
        const selectedIndex = updatedFilters.indexOf(index);

        if (selectedIndex === -1) {
            updatedFilters.push(index);
        } else {
            updatedFilters.splice(selectedIndex, 1);
        }

        setSelectedFilters(updatedFilters);
    };

    const handleApplyFilter = () => {
        // Convert the array of selected species to a string separated by commas
        const selectedFiltersString = selectedFilters.join(',');

        // Call the speciesFilterChange function with the selected species string
        handleFilterChange(selectedFiltersString);

        // Close the dialog
        handleClose();
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
        >
            <DialogTitle id='customized-dialog-title' sx={{ py: 2, px: 3, justifyContent: "space-between", display: "flex" }}>
                <Typography variant='h6' component='span'>
                    Filters
                </Typography>
                <IconButton onClick={handleClose}><Icon.Close /></IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Typography variant="body" sx={{ fontWeight: '600' }}>Select Species</Typography>
                <Box sx={{ mt: 1 }}>
                    <Grid container>
                        {
                            Object.entries(filterData).map(([index, val]) => (
                                <Grid item xs={12} sm={6} md={6} key={index}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedFilters.includes(index)}
                                                onChange={() => handleCheckboxChange(index)}
                                            />
                                        }
                                        label={`${index} (${val})`}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', p: 3 }}>
                <Button variant="link" onClick={() => setSelectedFilters([])}>Clear All</Button>
                <Button variant="contained" onClick={handleApplyFilter}>
                    Apply
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FilterPopup;