import React, { useEffect, useState } from "react";
import 'swiper/css';

import SwiperCore from "swiper/core";
// ** MUI Imports

import { checkIfJsonHasNonEmptyValues, empty, extractDataFromArray, getDateToString, isValueEqual } from "../../utils/common";
// material icons
// import * as Icon from "@mui/icons-material";

//photo swipe/lightbox
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

// Custom Component
import { Link, Navigate, useParams } from "react-router-dom";

// import WishlistService from "../../services/wishlist";
import PackageService from "../../services/package";
import ListingService from "../../services/listing";
import config from "../../config";
import SkeletonPropertyPreview from "../placeholders/SkeletonPropertyPreview";
import Header from "../previewPageComponents/Header";
import HeaderGallery from "../previewPageComponents/HeaderGallery";
import Lodging from "../previewPageComponents/Lodging";
import LandOwner from "../previewPageComponents/LandOwner";
import Location from "../previewPageComponents/Location";
import LandDetails from "../previewPageComponents/LandDetails";
import { Navigation } from "swiper/modules";
import HuntingDetails from "../previewPageComponents/HuntingDetails";
import FishingDetails from "../previewPageComponents/FishingDetails";
import BirdWatchingDetails from "../previewPageComponents/BirdWatchingDetails";
import FarmRanchDetails from "../previewPageComponents/FarmRanchDetails";
import { AppBar, Box, Button, Dialog, Divider, Grid, Icon, IconButton, List, ListItem, ListItemText, Toolbar, Typography } from "@mui/material";
import BookingCard from "../previewPageComponents/BookingCard";
import AdminBanner from "../previewPageComponents/AdminBanner";
import { useNavigate } from "react-router-dom";
import Page404 from "../../pages/404";
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { CommonContext } from "../../store/context/commonContext";
import { ArrowBackOutlined, LocationOnOutlined, MapOutlined } from "@mui/icons-material";
import PackageCardSmall from "../PackageCardSmall";
import { ROUTE_PROPERTY } from "../../constants/page";
import BookingCardMobile from "../previewPageComponents/BookingCardMobile";
import PackageCardMobile from "../PackageCardMobile";
import ReviewRecievedCard from "../revieRecievedCard";
import dayjs from "dayjs";
import EnquiryPopUp from "../EnquiryPopUp";



SwiperCore.use([Navigation]);


const PackageMapping = {
	H: 'hunting_experience',
	F: 'fishing_experience',
	BW: 'bird_watching_experience',
	FR: 'farm_ranch_experience',
}
const PackageDetails = () => {
	const [loading, setLoading] = useState(true)
	const [packageData, setPackageData] = useState(null);
	const [listingData, setListingData] = useState(null);
	const [listerSeq, setListerSeq] = useState('')
	const [bookingSelection, setBookingSelection] = useState({ guests: '', dates: [null, null] });
	// console.log(bookingSelection)
	const navigate = useNavigate()
	const { isMobile, isTablet, isNineHundred } = CommonContext();
	const [notAvailableDates, setNotAvailableDates] = useState([]);
	const [notAvailableDatesStringArray, setNotAvailableDatesStringArray] = useState()
	const [open, setOpen] = React.useState(false);
	const [openEnquiryPopup, setOpenEnquiryPopup] = useState(false);
	const [paymentDetails, setPaymentDetails] = useState({})



	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
		setOpenEnquiryPopup(false);
	};

	const [isConfirm, setIsConfirm] = useState(false)

	const handleClickOpenReview = () => {
		setOpen(true);
	};

	const handleCloseReview = () => {
		setOpen(false);
	};

	const handleNotAvailabeDates = (data) => {
		setNotAvailableDatesStringArray(data)
		if (Array.isArray(data) && data && data.length > 0) {
			let notAvlDatesDayJS = data.map(date => dayjs(date))
			setNotAvailableDates(notAvlDatesDayJS)
		}
	}

	const { package_id } = useParams()
	let packageSeq = package_id.split("-")
	packageSeq = packageSeq[packageSeq.length - 1]

	useEffect(() => {
		PackageService.getPackageData(packageSeq, (data) => {
			setPackageData(data);

			// Initialize guests with the minimum value
			setBookingSelection({
				...bookingSelection,
				guests: extractDataFromArray(data, ["package_json", "min_guests"], 0).toString(),

			});

			window.history.replaceState(null, null, window.location.origin + (config.module === 'lister' ? "/landowner" : '') + "/package/" + data.slug + "-" + packageSeq);
			if (!empty(extractDataFromArray(data, ['listing_seq'], 0))) {
				ListingService.getListingData(extractDataFromArray(data, ['listing_seq'], 0), (data) => {
					setListingData(data);
				});
				config.module == 'guest' && ListingService.getListingBookedDates(extractDataFromArray(data, ['listing_seq'], 0), (data) => {

					// console.log(data)
					handleNotAvailabeDates(data)
				});
			}
			setLoading(false)
		},
			(errorMessage) => {
				navigate('/unauthorised', { state: { errorMessage } });

			}
		);
	}, [packageSeq]);

	useEffect(()=> {

		setListerSeq(extractDataFromArray(listingData,['lister_seq'],''))

	},[listingData])

	useEffect(()=> {
	
		PackageService.getCancellationPolicyData('lister',listerSeq, (data) => {
			setPaymentDetails(data)
			// setCancelReasons(data.cancelationResaons)
		})
	},[listerSeq])



	const minGuests = extractDataFromArray(packageData, ["package_json", "min_guests"], 0);
	const maxGuests = extractDataFromArray(packageData, ["package_json", "max_guests"], 0);

	const minDays = extractDataFromArray(
		packageData,
		["package_json", "min_days"],
		0
	);
	const maxDays = extractDataFromArray(
		packageData,
		["package_json", "max_days"],
		0
	);


	const pricePerGuest = extractDataFromArray(
		packageData,
		["package_json", "price_per_guest"],
		0
	);


	useEffect(() => {
		let lightbox = new PhotoSwipeLightbox({
			gallery: "#headerGallery",
			children: "a",
			pswpModule: () => import("photoswipe"),
		});
		lightbox.init();
	}, []);


	const handleGuestChange = (event) => {
		const selectedGuests = parseInt(event.target.value, 10);

		// Ensure selected guests are within the allowed range
		if (selectedGuests >= minGuests && selectedGuests <= maxGuests) {
			setBookingSelection({ ...bookingSelection, guests: selectedGuests.toString() });
		}

		let storedSelection = JSON.parse(localStorage.getItem(`${packageSeq}-bookingSelection`));
		storedSelection.guest = selectedGuests;
		localStorage.setItem(`${packageSeq}-bookingSelection`, JSON.stringify(storedSelection));
	};

	const handleBookingDates = (range) => {
		setBookingSelection({ ...bookingSelection, dates: range });
	};


	if (loading) {
		return <SkeletonPropertyPreview />
	}



	return (
		<>
			<div>
				{openEnquiryPopup && (
					<EnquiryPopUp
						open={openEnquiryPopup}
						setOpenEnquiryPopup={setOpenEnquiryPopup}
						packages={packageData}
						handleClose={handleClose}
						listingData={listingData}
					/>
				)}
			</div>
			<Box sx={{ p: !isNineHundred && 3, pb: isNineHundred && 9 }}>
				<Grid container sx={{ p: { xs: 2, sm: 2, md: 0 } }}>
					{
						config.module === 'admin' &&
						<Grid item md={12} xs={12}>
							<AdminBanner
								lister_name={extractDataFromArray(listingData, ['fname'], '') + " " + extractDataFromArray(listingData, ['lname'], '')}
								lister_pic={extractDataFromArray(listingData, ['profile_pic'], '')}
								lister_seq={extractDataFromArray(listingData, ['lister_seq'], '')}
								listing_seq={extractDataFromArray(listingData, ['listing_seq'], '')}
								status={extractDataFromArray(listingData, ['verification_status'], '')}
							/>
						</Grid>
					}
					<Grid item md={12} xs={12}>
						<Box sx={{ display: 'flex', flexDirection: { sm: 'column', xs: 'column-reverse' }, width: '100%', mb: { xs: 0, sm: 0, md: 6 } }}>
							<Header
								type="package"
								listing_seq={extractDataFromArray(listingData, ['listing_seq'], 0)}
								seq={extractDataFromArray(packageData, ['package_seq'], 0)}
								title={extractDataFromArray(packageData, ['title'], '')}
								rating={extractDataFromArray(packageData, ['average_rating'], '0')}
								reviewCount={extractDataFromArray(packageData, ['review_count'], '0')}
								address={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_address'], '')}
								ltln={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_ltln'], '')}
								areaSize={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], '')}
								nearestTown={extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '')}
								listingTitle={extractDataFromArray(listingData, ['title'], '')}
								listingSlug={extractDataFromArray(listingData, ['slug'], '')}
							/>
							<HeaderGallery images={extractDataFromArray(packageData, ['package_json', 'media_library'], [])} type="package" seq={extractDataFromArray(packageData, ['package_seq'], 0)} />
						</Box>
					</Grid>
					<Grid container spacing={8}>
						<Grid item sx={12} sm={12} md={7}>
							<Grid item xs={12} sm={12} md={12}>
								{packageData ? (
									<Box sx={{ mb: 6 }}>
										<Typography variant="h4" sx={{ mt: { xs: 2, sm: 2, md: 0 } }}>
											Package Details
										</Typography>
										<Divider sx={{ mb: 3, mt: 2, borderColor: 'var(--grey500)' }}></Divider>

										<Typography sx={{ color: 'var(--grey900)' }}>
											{extractDataFromArray(packageData, ["description"], "description")}
										</Typography>
									</Box>
								) : (
									<p></p>
								)}
							</Grid>

							<Grid item xs={12} sm={12} md={12} sx={{ mb: 6 }}>
								<LandDetails
									data={extractDataFromArray(listingData, ["listing_json", extractDataFromArray(PackageMapping, [extractDataFromArray(packageData, ['package_json', 'activity_type'], '')])], {})}

								/>
							</Grid>
							{
								isValueEqual(extractDataFromArray(packageData, ['package_json', 'activity_type'], ''), 'H') &&
								<Grid item xs={12} sm={12} md={12} sx={{ mb: 6 }}>
									<HuntingDetails
										packageData={extractDataFromArray(packageData, ["package_json"], {})}
										activityData={extractDataFromArray(listingData, ["listing_json", extractDataFromArray(PackageMapping, [extractDataFromArray(packageData, ['package_json', 'activity_type'], '')])], {})}
									/>
								</Grid>
							}
							{
								isValueEqual(extractDataFromArray(packageData, ['package_json', 'activity_type'], ''), 'F') &&
								<Grid item xs={12} sm={12} md={12} sx={{ mb: 6 }}>
									<FishingDetails
										packageData={extractDataFromArray(packageData, ["package_json"], {})}
										activityData={extractDataFromArray(listingData, ["listing_json", extractDataFromArray(PackageMapping, [extractDataFromArray(packageData, ['package_json', 'activity_type'], '')])], {})}
									/>
								</Grid>
							}
							{
								isValueEqual(extractDataFromArray(packageData, ['package_json', 'activity_type'], ''), 'BW') &&
								<Grid item xs={12} sm={12} md={12} sx={{ mb: 6 }}>
									<BirdWatchingDetails
										packageData={extractDataFromArray(packageData, ["package_json"], {})}
										activityData={extractDataFromArray(listingData, ["listing_json", extractDataFromArray(PackageMapping, [extractDataFromArray(packageData, ['package_json', 'activity_type'], '')])], {})}
									/>
								</Grid>
							}
							{
								isValueEqual(extractDataFromArray(packageData, ['package_json', 'activity_type'], ''), 'FR') &&
								<Grid item xs={12} sm={12} md={12} sx={{ mb: 6 }}>
									<FarmRanchDetails
										packageData={extractDataFromArray(packageData, ["package_json"], {})}
										activityData={extractDataFromArray(listingData, ["listing_json", extractDataFromArray(PackageMapping, [extractDataFromArray(packageData, ['package_json', 'activity_type'], '')])], {})}
									/>
								</Grid>
							}
							{
								extractDataFromArray(listingData, ["listing_json", "lodging_details", "available"], 'No') === "Yes" ?
									<Grid item xs={12} sm={12} md={12} sx={{ mb: 6 }}>
										<Lodging
											data={extractDataFromArray(listingData, ["listing_json", "lodging_details"], {})}
										/>
									</Grid> : <></>
							}
							<Grid item xs={12} sm={12} md={12} sx={{ mb: 6 }}>
								{extractDataFromArray(listingData, ['listing_json', 'arrival_instructions', 'land_owner_tips'], '') ?
									<Box>
										<Typography sx={{ color: '#1b1b1b', fontWeight: '500' }}>Landowner Rules : </Typography>
										<Typography color="grey" sx={{ mb: 2 }}>
											{
												extractDataFromArray(listingData, ['listing_json', 'arrival_instructions', 'land_owner_tips'], '')
											}
										</Typography>
									</Box> : ""
								}
								<Typography sx={{ fontWeight: '500', textDecoration: 'underline' }} >Cancellation Policy:</Typography>
								<Typography color="grey" sx={{}}>
								{extractDataFromArray(paymentDetails, ['messageToguest'], '')}
								</Typography>
							</Grid>


							<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
								<ReviewRecievedCard listingData={packageData} type={'P'} />
							</Grid>
							<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
								<LandOwner listingData={listingData} setOpenEnquiryPopup={setOpenEnquiryPopup} />
							</Grid>
							{
								!empty(listingData) &&
								<Grid item xs={12} sm={12} md={12} sx={{ mb: isNineHundred ? 9 : 5 }}>
									<Location lat={parseFloat(extractDataFromArray(listingData, ['lat'], 0))} lon={parseFloat(extractDataFromArray(listingData, ['lon'], 0))} />
								</Grid>
							}
						</Grid>

						{config.module === "guest" && packageData && !isNineHundred ? (
							<BookingCard
								packageSeq={packageSeq}
								guests={bookingSelection.guests}
								dateRange={bookingSelection.dates}
								minDays={minDays}
								maxDays={maxDays}
								minGuests={minGuests}
								maxGuests={maxGuests}
								handleGuestChange={handleGuestChange}
								handleDateChange={handleBookingDates}
								price={pricePerGuest}
								notAvailableDates={notAvailableDates}
							/>
						) : (
							<></>
						)}
					</Grid>
				</Grid>
				{isNineHundred && config.module === 'guest' && <Box
					sx={{
						width: '100%',
						height: '82px',
						position: 'fixed',
						bottom: 0,
						backgroundColor: 'white',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						pr: 2,
						pl: 2,
						boxShadow: '0 -2px 4px 0 rgba(0,0,0,0.15)',
						zIndex: 2
					}}
				>
					<Box sx={{ display: 'flex', fontSize: '14px', flexDirection: 'column' }}>
						<Typography sx={{ fontWeight: 'bold' }}>${parseFloat(pricePerGuest).toFixed(2)} </Typography>
						<Typography variant={isMobile && "caption"} sx={{}}>/ Starting price</Typography>
					</Box>
					<Button variant="contained" size="small" onClick={handleClickOpen}>
						Check Availability
					</Button>
				</Box>}
				<React.Fragment>
					<Dialog
						fullScreen
						open={open && isNineHundred}
						onClose={handleClose}
						sx={{
							'& .MuiDialog-paper': {
								borderRadius: 0
							}
						}}
					>
						<AppBar sx={{ position: 'relative', backgroundColor: 'white', boxShadow: 'none', minHeight: '56px' }}>
							<Toolbar>
								<IconButton
									edge="start"
									color="inherit"
									onClick={handleClose}
									aria-label="close"
								>
									<ArrowBackOutlined color="black" />
								</IconButton>
								<Typography sx={{ m: 'auto', fontWeight: 'bold' }} variant="h6" component="div">
									Booking Availability
								</Typography>
							</Toolbar>
							<Divider />
						</AppBar>
						<Box sx={{
							mt: 2,
							pr: 2,
							pl: 2,
							mb: 12
						}}>
							<Box sx={{ display: 'flex', mb: 2 }}>
								<PackageCardMobile
									image={extractDataFromArray(packageData, ['package_media', '0'])}
									title={extractDataFromArray(packageData, ['title'], '')}
									area={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], '')}
									address={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_address'], '')}
									nearest_town={extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '')}
									propertyName={extractDataFromArray(listingData,['listing_json','property_details','name'],'')}
									listingSlug={extractDataFromArray(listingData,['listing_json','property_details','slug'],'')}
									listing_seq={extractDataFromArray(listingData,['listing_seq'],'')}
									activity_type={extractDataFromArray(packageData,['package_json','activity_type'],'')}
									showBorder={false}
									type={extractDataFromArray(packageData, ['package_media', '0', 'file_type'])}
									thumbnail={extractDataFromArray(packageData, ['package_media', '0', 'thumbnail_url'], '')}
								/>
							</Box>

							<BookingCard
								packageSeq={packageSeq}
								guests={bookingSelection.guests}
								dateRange={bookingSelection.dates}
								minDays={minDays}
								maxDays={maxDays}
								minGuests={minGuests}
								maxGuests={maxGuests}
								handleGuestChange={handleGuestChange}
								handleDateChange={handleBookingDates}
								price={pricePerGuest}
								notAvailableDates={notAvailableDates}
								isPopup={true}
							/>
						</Box>

					</Dialog>
				</React.Fragment>

			</Box>
		</>
	);
};

export default PackageDetails;