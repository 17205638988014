import { Avatar, Box, Typography } from "@mui/material";
// import ReplyIcon from "@mui/icons-material/Reply";
// import { useEffect, useState } from "react";
// import Moment from 'moment'
import MessageComponent from "../defaultMsg";
import moment from "moment";
import { MessageContext } from "../../../../store/context/messageContext";
import { extractDataFromArray } from "../../../../utils/common";
import { useEffect, useState } from "react";



const LeftChat = ({ message, i,
  // isReply, setIsReply, 
  seqValue, onchat2 }) => {

  const { isMobile, userDetail, mobileScreen } = MessageContext();
  
  
  // const time = message?.time;


  // const currentDate = new Date();

  // const handleReply = () => {
  //   setIsReply({ flag: true, conversation: message, time: 45 });
  // };

  return (
    <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
      <Box
        key={i}
        sx={{
          display: "flex",
          padding: 2,
          gap: 1.25,
          alignItems: "flex-start",
          width: "100%",
          maxWidth: "80%",
          // cursor: "pointer",
          "& #hidden-button": {
            visibility: "hidden",
          },
          "&:hover #hidden-button": {
            visibility: "visible",
          },
          wordBreak:"break-word"
        }}
      >
        <Box>
          <Avatar
            alt="Remy Sharp"
            src={userDetail.profile_pic}
            sx={isMobile ? { width: 32, height: 32 } : { width: 40, height: 40 }}
          />
        </Box>
        <Box
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            textAlign: "left",
          }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                gap: 1,
              }}
            >
              <Typography variant="subtitle2" sx={{ fontWeight: 600 }}>
                {message?.from?.seq === seqValue ? message?.to?.fname : message?.from?.fname}
              </Typography>
              <Typography variant="caption" sx={{ color: "#85878A" }}>
                {moment.utc(extractDataFromArray(message, ['create_time'], '')).local().format('DD MMM') + " at " + moment.utc(extractDataFromArray(message, ['create_time'], '')).local().format('h:mm A')}
              </Typography>
            </Box>

          </Box>
          <Box sx={{ display: "flex", flexDirection: "row", gap: 1, alignItems: "center" }}>
           
            <MessageComponent onchat2={onchat2} message={message} seqValue={seqValue} isMobile={mobileScreen}/>

            {/* <Box
              id="hidden-button"
              onClick={handleReply}
              sx={{
                width: "32px",
                height: "32px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "flex-end",
                backgroundColor: "#F5F5F6",
                visibility: "hidden",
              }}
            >
              <ReplyIcon sx={{ width: "24px", height: "32px" }} />
            </Box> */}

          </Box>
        </Box>

      </Box>



      {/* 
        // <Box
        //   key={i}
        //   sx={{
        //     display: "flex",
        //     padding: "16px",
        //     gap: "10px",
        //     alignItems: "flex-start",
        //     cursor: "pointer",
        //     "& #hidden-button": {
        //       visibility: "hidden",
        //     },
        //     "&:hover #hidden-button": {
        //       visibility: "visible",
        //     },
        //   }}
        // >
        //   <Box>
        //     <Avatar
        //       alt="Remy Sharp"
        //       src={
        //         message.sender === "Kaushal"
        //           ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6MbzLVrpxVHJaPIUx3QTIKpUaaYlzkpbo_A&usqp=CAU"
        //           : "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1600"
        //       }
        //       sx={
        //         isMobile ? { width: 32, height: 32 } : { width: 40, height: 40 }
        //       }
        //     />
        //   </Box>
        //   <Box
        //     sx={{
        //       flex: 1,
        //       display: "flex",
        //       flexDirection: "column",
        //       textAlign: "left",
        //     }}
        //   >
        //     <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        //       <Box
        //         sx={{
        //           display: "flex",
        //           alignItems: "baseline",
        //           gap: "8px",
        //         }}
        //       >
        //         <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
        //           {message.message_sender}
        //         </Typography>
        //         <Typography sx={{ fontSize: "12px", color: "#85878A" }}>
        //         {Moment(message.msg_create_time, "YYYY-MM-DD HH:mm:ss").format("MMM DD, h:mm A")}
        //         </Typography>
        //       </Box>
        //       <Box
        //         id="hidden-button"
        //         onClick={handleReply}
        //         sx={{
        //           width: "32px",
        //           height: "32px",
        //           borderRadius: "50%",
        //           display: "flex",
        //           justifyContent: "center",
        //           alignItems: "center",
        //           backgroundColor: "#F5F5F6",
        //           visibility: "hidden",
        //         }}
        //       >
        //         <ReplyIcon sx={{ width: "24px", height: "32px" }} />
        //       </Box>
        //     </Box>
        //     <Box>
        //       <Typography
        //         sx={{
        //           marginTop: "-8px",
        //           width: "100%",
        //           color: "#85878A",
        //           fontSize: "16px",
        //         }}
        //       >
        //         {message.message}
        //       </Typography>
        //     </Box>
        //   </Box>
        // </Box> */}
      {/* // ) : ( */}
      {/* <Box
          key={i}
          sx={{
            padding: "16px",
          }}
        >
          <Box
            sx={{
              border: "1px solid #E5E7EB",
              padding: "9px",
              borderRadius: "8px",
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                gap: "10px",
                alignItems: "flex-start",
                cursor: "pointer",
                "& #hidden-button": {
                  visibility: "hidden",
                },
                "&:hover #hidden-button": {
                  visibility: "visible",
                },
              }}
            >
              <Box>
                <Avatar
                  alt="Remy Sharp"
                  src={
                    message.replyTo === "Kaushal"
                      ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6MbzLVrpxVHJaPIUx3QTIKpUaaYlzkpbo_A&usqp=CAU"
                      : "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1600"
                  }
                  sx={
                    isMobile
                      ? { width: 32, height: 32 }
                      : { width: 40, height: 40 }
                  }
                />
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "left",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    gap: "8px",
                  }}
                >
                  <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                    {message.replyTo}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", color: "#85878A" }}>
                    {message.messageTime}
                  </Typography>
                </Box>

                <Box>
                  <Typography
                    sx={{
                      width: "100%",
                      color: "#85878A",
                      fontSize: "16px",
                    }}
                  >
                    {message.replyOf}
                  </Typography>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "flex-start",
                    borderRadius: "8px",
                    backgroundColor: "#F5F5F6",
                    marginTop: "8px",
                    padding: "8px",
                    cursor: "pointer",
                    "& #hidden-button": {
                      visibility: "hidden",
                    },
                    "&:hover #hidden-button": {
                      visibility: "visible",
                    },
                  }}
                >
                  <Box>
                    <Avatar
                      alt="Remy Sharp"
                      src={
                        message.sender === "Kaushal"
                          ? "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6MbzLVrpxVHJaPIUx3QTIKpUaaYlzkpbo_A&usqp=CAU"
                          : "https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1600"
                      }
                      sx={{ width: 24, height: 24 }}
                    />
                  </Box>
                  <Box
                    sx={{
                      flex: 1,
                      display: "flex",
                      flexDirection: "column",
                      textAlign: "left",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "8px",
                      }}
                    >
                      <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
                        {message.sender}
                      </Typography>
                      <Typography sx={{ fontSize: "12px", color: "#85878A" }}>
                      {"45"}
                      </Typography>
                    </Box>

                    <Box>
                      <Typography
                        sx={{
                          width: "100%",
                          color: "#85878A",
                          fontSize: "16px",
                        }}
                      >
                        {message.message}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box> */}
      {/* )}  */}
    </Box>
  );
};

export default LeftChat;
