import React, { useRef, useEffect, useState } from 'react';
import * as Icon from "@mui/icons-material"
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import { empty, extractDataFromArray } from '../../utils/common';
import WishlistService from '../../services/wishlist';
import { UserAuth } from '../../store/context/userContext';
import { toast } from 'react-toastify';
import { Box, Button, Dialog, Divider, Typography } from '@mui/material';
import { string } from 'yup';
import { CommonContext } from '../../store/context/commonContext';
import zIndex from '@mui/material/styles/zIndex';

const defaultLat = 40.489627;
const defaultLng = -101.592734;

const SearchMap = ({ results, listingSequence }) => {
	const [isWishlist, setIsWishlist] = useState(false)
	const [toggle, setToggle] = useState(false)
	const [iconUrl, setIconUrl] = useState('/assets/images/icon_images/favorite_border_icon.svg')
	const [listingSeq, setListinSeq] = useState('')
	const mapContainer = useRef(null);
	const { user, authPopup, setAuthPopup } = UserAuth()
	const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
	const [checkIsWishlisted, setCheckIsWishlisted] = useState(false);
	const [isUser, setIsUser] = useState(false)
	const { isMobile } = CommonContext()


	const handleRemoveConfirm = (event) => {

		event.stopPropagation();
		setOpenRemoveDialog(false);
		toggleWishlist(listingSeq);
	};


	const handleRemoveCancel = (event) => {
		event.stopPropagation();
		setOpenRemoveDialog(false);
	};



	const map = useRef(null);
	let activeMarker = null;
	let activePopup = null;

	useEffect(() => {

	}, [iconUrl, toggle])

	const toggleWishlist = (listingSeq) => {
		const type = 'L'
		if (checkIsWishlisted === false) {
			setCheckIsWishlisted(true)
			WishlistService.saveWishlist(type, listingSeq, (data, errorMsg) => {
				if (data === false) {
					setOpenRemoveDialog(true)
				} else {
					toast.success(errorMsg, {
						theme: "colored",
					})
					document.getElementById("mb_fav_icon").style.backgroundImage = 'url(/assets/images/icon_images/favorite_icon.svg)';
					setCheckIsWishlisted(true)
					setToggle(true)
				}
			})
		} else {
			WishlistService.removeFromWishlist(type, listingSeq, (data, errorMsg) => {
				if (!data) {
					// toast.warning("Something Went Wrong.", {
					//     theme: "colored",
					// })
				} else {
					toast.success(errorMsg, {
						theme: "colored",
					})
					document.getElementById("mb_fav_icon").style.backgroundImage = 'url(/assets/images/icon_images/favorite_border_icon.svg)';
				}
			})
		}
	}

	// const { user } = UserAuth()




	const addPopupToMarker = (marker, map, result, checkIsWishlisted) => {
		const popupContent = document.createElement('div');

		// console.log("user",user);
		setListinSeq(result.listing.seq)

		popupContent.style.width = '230px';
		popupContent.onclick = () => {
			let locationToOpen = isMobile ? "_self" : "_blank"
			window.open(`/property/${extractDataFromArray(result, ['listing', 'slug'], 'propery')}-${result.listing.seq}`, locationToOpen);
		};

		const showRating = !(extractDataFromArray(result, ['listing', 'review_count'], 0) == 0 || extractDataFromArray(result, ['listing', 'rating'], '') == 0)

		popupContent.innerHTML = `

		<p class="mb_property_title">${extractDataFromArray(result, ['listing', 'title'], 'property')}</p>
		<p class="mb_property_address">${extractDataFromArray(result, ['listing', 'area'], '1')} Acres, ${extractDataFromArray(result, ['listing', 'address'], '') === '-' ? `near ${extractDataFromArray(result, ['listing', 'nearest_town'], '')}` : `${extractDataFromArray(result, ['listing', 'address'], '')}`}</p>
		${showRating ?
				`<p class="mb_property_review_card">
			<img src="/assets/images/icon_images/star-solid.svg" style="height: 16px; width: 16px;" />
			<span class="mb_rating">&nbsp;${extractDataFromArray(result, ['listing', 'rating'], '')}</span>${extractDataFromArray(result, ['listing', 'review_count'], 0)} reviews
			</p>`
				:
				''
			}
	  
      <p style="margin: 0">
        <span style="color: #1B1B1B; font-size: 14px; font-weight: bold;">$${Math.round(extractDataFromArray(result, ['listing', 'starting_price'], '1'))}</span>
        <span style=" font-size: 14px; ">/Starting Price</span>
      </p>
    `;
		// Created 2 new div for wishlist icon logic
		const imageDiv = document.createElement("div");
		const videoPreview = document.createElement("div");
		const overlay = document.createElement("div");
   
		imageDiv.className = "mb_property_image";
  
		if (extractDataFromArray(result, ['listing', 'image', 'type'], '') === 'VIDEO') {
			imageDiv.style.backgroundImage = "url(" + extractDataFromArray(result, ['listing', 'image', 'thumbnail_url'], '/landowner/assets/images/property_detail_page/blank_property_image.png') + ")";
			videoPreview.style.backgroundImage = "url('/assets/images/icon_images/play-button-svgrepo-com.svg')";
			videoPreview.style.height = "40px";
			videoPreview.style.width = "40px";
			videoPreview.style.zIndex = 1000;
			videoPreview.style.margin = "auto";
			videoPreview.style.position = "absolute";
			videoPreview.style.padding = "16px";
			videoPreview.style.backgroundPosition = "center center";
			videoPreview.style.backgroundRepeat = "no-repeat";
			videoPreview.style.borderRadius = "4px";
			videoPreview.style.backgroundSize = "40px";
			videoPreview.style.top = '0px';
			videoPreview.style.left = '0px';
			videoPreview.style.right = '0px';
			videoPreview.style.bottom = '0px';

			// Adding overlay to imageDiv
			overlay.style.position = "relative";
			overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
			overlay.style.position = "rgba(0, 0, 0, 0.5)";
			overlay.style.borderRadius = "8px";
			overlay.style.width = "100%";
			overlay.style.height = "100%";
		} else if (extractDataFromArray(result, ['listing', 'image', 'type'], '') === 'IMAGE') {
			imageDiv.style.backgroundImage = "url(" + extractDataFromArray(result, ['listing', 'image', 'url'], '/landowner/assets/images/property_detail_page/blank_property_image.png') + ")";
		}
  
		// Append videoPreview to imageDiv
		imageDiv.appendChild(videoPreview);
  

		imageDiv.style.backgroundImage = "url(" + extractDataFromArray(result, ['listing', 'image'], '/landowner/assets/images/property_detail_page/blank_property_image.png') + ")"



		const favoriteIcon = document.createElement("div")
		favoriteIcon.id = "mb_fav_icon"
		favoriteIcon.style.backgroundImage = "url(" + iconUrl + ")"
		favoriteIcon.style.backgroundColor = "white"
		favoriteIcon.style.height = "34px"
		favoriteIcon.style.width = "34px"
		favoriteIcon.style.zIndex = 1000
		favoriteIcon.style.position = "absolute"
		favoriteIcon.style.padding = "16px"
		favoriteIcon.style.backgroundPosition = "center center"
		favoriteIcon.style.backgroundRepeat = "no-repeat"
		favoriteIcon.style.borderRadius = "4px"
		favoriteIcon.style.backgroundSize = "24px"
		favoriteIcon.style.top = "8px"
		favoriteIcon.style.right = "8px"

		imageDiv.append(favoriteIcon)
		imageDiv.append(videoPreview)
		imageDiv.append(overlay)
		popupContent.prepend(imageDiv);

		// popupContent.prepend(videoPreview);


		// if(!empty(user)) {
		// 	if(checkIsWishlisted) {

		// 	} else {
		// 		document.getElementById("mb_fav_icon").style.backgroundImage = 'url(/assets/images/icon_images/favorite_border_icon.svg)';
		// 	}
		// }


		favoriteIcon.addEventListener("click", (e) => {
			e.stopImmediatePropagation()
			e.stopPropagation()

			if (empty(user)) {
				setAuthPopup({
					...authPopup,
					type: 'L',
					open: true,
					callback: () => {
						if (checkIsWishlisted) {
							setOpenRemoveDialog(false);
						} else {
							toggleWishlist(result.listing.seq);
						}
					}
				});
			} else {
				if (checkIsWishlisted) {
					// console.log(checkIsWishlisted, 'in lin 174')
					WishlistService.isWishlist(result.listing.seq, 'L', (data) => {
						if (data) {
							setOpenRemoveDialog(true);
						} else {
							WishlistService.saveWishlist('L', result.listing.seq, (data, errorMsg) => {
								if (data) {
									toast.success(errorMsg, {
										theme: "colored",
									})
									document.getElementById("mb_fav_icon").style.backgroundImage = 'url(/assets/images/icon_images/favorite_icon.svg)';
									setCheckIsWishlisted(true)
									setToggle(true)
								}
							});
						}
					})
				} else {
					toggleWishlist(result.listing.seq);
				}
			}
		})


		const popup = new mapboxgl.Popup({ closeButton: false, offset: 25 }).setDOMContent(popupContent);

		popup.setLngLat(marker.getLngLat()).addTo(map.current);

		if (activeMarker && activeMarker !== marker) {
			activeMarker.getElement().style.backgroundImage = '/assets/images/property_detail_page/map_pointer.svg';
		}

		marker.getElement().style.backgroundImage = 'url("/assets/images/property_detail_page/map_pointer_active.svg")';
		activeMarker = marker;

		marker.setPopup(popup);
		map.current.on('click', (event) => {
			// Check if the click is not on a custom marker
			const clickedMarker = event.originalEvent.target.closest('.custom-marker');
			if (!clickedMarker) {
				// Remove active style from the previous active marker
				if (activeMarker) {
					activeMarker.getElement().style.backgroundImage = 'url("/assets/images/property_detail_page/map_pointer.svg")';
					activeMarker.getElement().style.zIndex = 0;
					activeMarker = null;

					// Close any open popups
					if (activePopup) {
						activePopup.remove();
						activePopup = null;
					}
				}
			}
		});
	};

	useEffect(() => {
		const coordinatesSet = new Set(); // Use a Set to store unique lat-lon combinations
		const coordinates = results.length > 0 ? results.reduce((acc, result) => {
			// Parse lat and lon as floating-point numbers
			const lat = parseFloat(result.listing.lat);
			const lon = parseFloat(result.listing.lon);

			// Check if lat and lon are valid numbers
			if (!isNaN(lat) && !isNaN(lon)) {
				const latLonString = `${lat}_${lon}`;
				if (!coordinatesSet.has(latLonString)) {
					coordinatesSet.add(latLonString);
					acc.push({ lon, lat });
				}
			}
			return acc;
		}, []) : null;


		if (results.length === 0) {
			// If there are no results, create a map with a default view of the entire US
			mapboxgl.accessToken = 'pk.eyJ1IjoiZmFyd2lkZSIsImEiOiJjajloOHc4MGozNWFnMnFsc2FueXJvbnZ3In0.7Cek0jLTdXkikf2ioduEvg';

			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: 'mapbox://styles/mapbox/streets-v12',
				center: [defaultLng, defaultLat],
				zoom: 4, // Zoom level to fit the entire US
				attributionControl: false,
			});

			const nav = new mapboxgl.NavigationControl();
			map.current.addControl(nav, 'top-left');

			return () => {
				map.current.remove();
			};
		}

		mapboxgl.accessToken = 'pk.eyJ1IjoiZmFyd2lkZSIsImEiOiJjajloOHc4MGozNWFnMnFsc2FueXJvbnZ3In0.7Cek0jLTdXkikf2ioduEvg';

		map.current = new mapboxgl.Map({
			container: mapContainer.current,
			style: 'mapbox://styles/mapbox/streets-v12',
			center: coordinates.length === 1 ? coordinates[0] : [defaultLng, defaultLat],
			zoom: coordinates.length === 1 ? 12 : 4, // Adjust the zoom level as needed
			attributionControl: false,
		});

		const nav = new mapboxgl.NavigationControl();
		map.current.addControl(nav, 'top-left');

		// Calculate bounds to fit all result coordinates
		const bounds = coordinates.reduce(
			(bounds, coord) => bounds.extend(coord),
			new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
		);

		map.current.fitBounds(bounds, { padding: 50 });

		// If there's only one pin, set a different zoom level
		if (coordinates.length === 1) {
			map.current.setZoom(9);
		}

		map.current.on('load', () => {
			// Use default if there are no results
			const resultsToDisplay = results.length > 0 ? results : [];

			resultsToDisplay.forEach((result) => {
				const customMarker = document.createElement('div');
				customMarker.className = 'custom-marker';
				customMarker.style.backgroundImage = 'url("/assets/images/property_detail_page/map_pointer.svg")';
				customMarker.style.width = '50px';
				customMarker.style.height = '50px'; // it was 100px before code fix
				customMarker.style.backgroundRepeat = 'no-repeat';
				customMarker.style.cursor = 'pointer';
				// customMarker.style.marginTop = '-25px' commented for accurate pin location

				const marker = new mapboxgl.Marker({ element: customMarker }).setLngLat([result.listing.lon, result.listing.lat]).addTo(map.current);

				marker.getElement().addEventListener('click', () => {
					// Check if there is an active marker
					if (activeMarker) {
						// Set the previous active marker to inactive
						activeMarker.getElement().style.backgroundImage = 'url("/assets/images/property_detail_page/map_pointer.svg")';
						activeMarker.getElement().style.zIndex = 0
						// Remove the popup from the previous active marker
						activeMarker.getPopup().remove();
					}

					// Set the current marker as active
					customMarker.style.backgroundImage = 'url("/assets/images/property_detail_page/map_pointer_active.svg")';
					activeMarker = marker;
					activeMarker.getElement().style.zIndex = 1000

					let user1 = JSON.parse(localStorage.getItem("userData")) || {}


					if (!empty(user1) || user1['loggedIn'] === "Y") {

						WishlistService.isWishlist(result.listing.seq, 'L', (data) => {
							setIsWishlist(data);
							if (data === true) {
								document.getElementById("mb_fav_icon").style.backgroundImage = 'url(/assets/images/icon_images/favorite_icon.svg)';
								setCheckIsWishlisted(true)

							} else {
								document.getElementById("mb_fav_icon").style.backgroundImage = 'url(/assets/images/icon_images/favorite_border_icon.svg)';
								setCheckIsWishlisted(false)
							}
						});
					} else {

					}

					// Add popup to the current marker
					addPopupToMarker(marker, map, result, checkIsWishlisted);
				});
			});
		});

		return () => {
			map.current.remove();
		};
	}, [results]);

	useEffect(() => {
		// Cleanup function to remove the element immediately when listingSequence becomes empty
		// Remove all existing marker elements
		const existingMarkers = document.querySelectorAll('.custom2-marker');
		existingMarkers.forEach(marker => marker.remove());

		// If listingSequence is not empty, create new marker elements with unique IDs
		if (listingSequence !== "") {
			const resultsToDisplay = results.length > 0 ? results : [];
			resultsToDisplay.forEach((result) => {
				if (result.listing.seq === listingSequence) {
					const customMarker = document.createElement('div');
					customMarker.id = `custom-marker-${result.listing.seq}`;
					customMarker.className = 'custom2-marker';
					customMarker.style.backgroundImage = 'url("/assets/images/property_detail_page/map_pointer_active.svg")'; // Set default background image
					customMarker.style.width = '50px';
					customMarker.style.height = '50px'; // it was 100px before code fix
					customMarker.style.backgroundRepeat = 'no-repeat';
					customMarker.style.cursor = 'pointer';
					// customMarker.style.marginTop = '-25px'; commented for accurate pin location
					customMarker.style.zIndex = 10; // Set default zIndex
                    
					// Add the marker to the map
					const marker = new mapboxgl.Marker({ element: customMarker }).setLngLat([result.listing.lon, result.listing.lat]).addTo(map.current);

					// Remove the element and marker when component unmounts
					return () => {
						customMarker.remove();
						marker.remove();
					};
				}
			});
		}
	}, [listingSequence]);

	return (

		<>
			<div ref={mapContainer} className="map-container" />
			<Dialog open={openRemoveDialog} onClose={handleRemoveCancel} >
				<Box >
					<Box sx={{ p: 2.5 }}>
						<Typography variant="h6" sx={{ fontWeight: 700 }}>Confirm the action</Typography>
					</Box>
					<Divider />
					<Box sx={{ p: 2.5 }}>
						<Typography>Are you sure you want to remove this property from your wishlist?</Typography>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'end', gap: 1, p: 2.5, width: '100%' }}>
						<Button variant="contained" color="error" onClick={handleRemoveConfirm}>REMOVE</Button>
						<Button variant="text" color="secondary" onClick={(event) => handleRemoveCancel(event)}>CANCEL</Button>
					</Box>
				</Box>
			</Dialog>
		</>
	)
};

export default SearchMap;
