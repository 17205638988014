import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import * as Icon from "@mui/icons-material";
import { IconButton, Toolbar } from '@mui/material';
import { UserAuth } from '../../../store/context/userContext';
import AuthPopup from '../../../commonComponents/authComponents/AuthPopup';
import LinkHref from '@mui/material/Link'
import { ROUTE_LISTER_HOME } from '../../../constants/page';

const LandingPageDrawer = ({ open, toggleDrawer }) => {

    const { user, authPopup, setAuthPopup } = UserAuth()

    const openAuthPopup = (type) => {
        setAuthPopup({ ...authPopup, type: type, open: true })
    }
    const closeAuthPopup = () => {
        setAuthPopup({ ...authPopup, open: false })
    }

    const DrawerList = (
        <Box sx={{ width: 260, mt: 3 }} role="presentation" onClick={toggleDrawer(false)}>
            <Box sx={{ paddingInline: 2, display: "flex", flexDirection: "column",alignItems:"center", gap: 1, }}>
            <LinkHref href={ROUTE_LISTER_HOME} sx={{ color: 'var(--black)',  width:"100%", textAlign:"center" }}><Button variant="link" color='var(--black)' sx={{ textDecoration: 'none' }}>List Your Property  </Button></LinkHref>
                <Button fullWidth size='small' variant="outlined" onClick={() => { openAuthPopup('signin') }} sx={{fontSize:"16px !important"}}>Sign In</Button>
                <Button fullWidth size='small' variant="contained" onClick={() => { openAuthPopup('signup') }} sx={{fontSize:"16px !important"}}>Sign Up</Button>
            </Box>
            <AuthPopup open={authPopup.open} type={authPopup.type} handleClose={() => { closeAuthPopup(false) }} handleOpen={openAuthPopup} />
        </Box>
    );

    return (
        <Box>

            <Drawer open={open} onClose={toggleDrawer(false)}>
                <Toolbar

                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        // px: 2,
                        // height: '72px',
                    }}
                >
                    <Box  sx={{ml:"0px !important"}}><img src='/landowner/assets/images/farwide-new-logo.svg' alt='FarWide' /></Box>
                    <IconButton onClick={toggleDrawer(false)} sx={{p:0}}>
                        <Icon.CloseOutlined sx={{ color: '#000000',p:0 }} />
                    </IconButton>
                </Toolbar>
                <Divider />
                {DrawerList}
            </Drawer>
        </Box>
    );
}
export default LandingPageDrawer;